import React from "react";
import {
  TextField,
  NumberField,
  ReferenceField,
  ShowButton,
  useListContext,
  useGetOne,
} from "react-admin";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { EditableField } from "../../components/Editable/Field";
import noImage from "../../../assets/icons/no-image.svg";
import PropTypes from "prop-types";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import { style } from "./style";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";

const placeholderImage = noImage;

const ProductCard = ({ record }) => {
  const role = localStorage.getItem("role");

  const { data: product } = record.product_id
    ? useGetOne("products", { id: record.product_id })
    : { data: null };
  const imageUrl =
    product && product?.images && product?.images[0]
      ? product?.images[0]?.url
      : placeholderImage;
  return (
    <Card variant="outlined" sx={style.cardContainer}>
      <Box sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          image={imageUrl}
          alt={record?.translations?.en?.name || "Placeholder"}
          sx={style.cardMedia}
        />
      </Box>
      <CardContent sx={style.cardContent}>
        <Box sx={style.cardInnerContentWrap}>
          <Box sx={{ fontWeight: "bold" }}>
            <TextField sx={style.nameField} source={"name"} record={record} />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={style.idTitle}>Id:</Typography>
            <NumberField source="id" record={record} />
          </Box>
          <Box sx={style.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Merchant:</Box>
            <ReferenceField
              source="merchant_id"
              reference="merchants"
              record={record}
            >
              <TextField source="name" />
            </ReferenceField>
          </Box>
          <Box sx={style.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Product:</Box>
            <ReferenceField
              source="product_id"
              reference="products"
              record={record}
            >
              <TextField sx={style.truncateField} source="name" />
            </ReferenceField>
          </Box>
          <Box sx={style.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Sku:</Box>
            <TextField
              sx={style.truncateField}
              source={"sku"}
              record={record}
            />
          </Box>
          <Box sx={style.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Price:</Box>
            {role === "Accounts::MerchantAdmin" ? (
              <TextField
                source={"cost"}
                record={record.cost ? record : { cost: "N/A" }}
              />
            ) : (
              <EditableField
                source={"cost"}
                type="number"
                record={record}
                showExtra={true}
              />
            )}
          </Box>
          <Box sx={style.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Currency:</Box>
            <TextField source="currency.iso_code" record={record} />
          </Box>
          <Box sx={style.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>VAT:</Box>
            <EditableBooleanField
              source="vat"
              recordOut={record}
              key="vat"
              label="Vat"
            />
          </Box>

          <Box sx={style.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Recommended Retail Price:</Box>
            {role !== "Accounts::SuperAdmin" ? (
              <TextField
                source={"recommended_retail_price"}
                record={
                  record.recommended_retail_price
                    ? record
                    : { recommended_retail_price: "N/A" }
                }
              />
            ) : (
              <EditableField
                source={"recommended_retail_price"}
                type="number"
                record={record}
                showExtra={true}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            mt: "auto",
          }}
        >
          <Box sx={style.showButtonWrap}>
            <ShowButton record={record} />
            {role === "Accounts::SuperAdmin" && (
              <CustomDeleteButton
                extRecord={record}
                recordName={"merchant_products"}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

ProductCard.propTypes = {
  record: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

const CardList = () => {
  const { data, isLoading } = useListContext();
  if (isLoading) {
    return (
      <Grid
        mb={2}
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center" mb={2}>
        {data.map((record) => (
          <Grid
            item
            key={record.id}
            xs={12}
            sm={6}
            md={4}
            lg={"auto"}
            xl={"auto"}
            sx={{ minWidth: 371, mr: 1 }}
          >
            <ProductCard record={record} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CardList;
