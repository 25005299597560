import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";

const TaCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
    </SimpleForm>
  </Create>
);
export default TaCreate;
