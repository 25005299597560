import React from "react";
import { Datagrid, NumberField, TextField, ArrayField } from "react-admin";
// import EditableField from "../Editable/Field";
import PropTypes from "prop-types";
const SmallPriceList = () => (
  <ArrayField source="prices_array" label="Prices">
    <Datagrid bulkActionButtons={false}>
      <TextField source="currency" sortable={false} />
      <NumberField source="price" label="Price" sortable={false} />
      <NumberField
        source="price_without_vat"
        label="Price without VAT"
        sortable={false}
      />
      <NumberField source="vat_amount" label="Vat Amount" sortable={false} />
    </Datagrid>
  </ArrayField>
);

SmallPriceList.propTypes = {
  filter: PropTypes.object,
  record: PropTypes.object,
};
export default SmallPriceList;
