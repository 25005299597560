import * as React from "react";
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
} from "react-admin";
import LocaleList from "./List";
import InDrawer from "../../hocs/InDrawer";

const LocaleCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput
          fullWidth
          source="code"
          validate={[required(), maxLength(2)]}
        />
        <TextInput fullWidth source="name" validate={[required()]} />
        <BooleanInput source="default" />
      </SimpleForm>
    </Create>
  );
};
export default InDrawer(
  <LocaleList />,
  <LocaleCreate />,
  "Create Locale",
  "Create new Locale",
  "/translations",
);
