import React from "react";

const ToggleActive = () => (
  <svg
    width="36"
    height="20"
    viewBox="0 0 36 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12_40442)">
      <rect width="36" height="20" rx="10" fill="#5F57FF" />
      <g filter="url(#filter0_d_12_40442)">
        <circle cx="26" cy="10" r="8" fill="white" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_12_40442"
        x="16"
        y="1"
        width="20"
        height="20"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.36 0 0 0 0 0.306 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12_40442"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_12_40442"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_12_40442">
        <rect width="36" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { ToggleActive };
