import React from "react";
import { FunctionField } from "react-admin";

const NewPrice = () => (
  <FunctionField
    label="New Price"
    render={(record) => {
      const { price, price_without_vat } = record;
      return (
        <React.Fragment>
          {price ? `${parseFloat(price)}(VAT)` : null}
          <br />
          {price_without_vat ? `${parseFloat(price_without_vat)}(noVAT)` : null}
        </React.Fragment>
      );
    }}
  />
);
export default NewPrice;
