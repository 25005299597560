import { snakeCase, get } from "lodash";
import pluralize from "pluralize";

const knownActions = [
  "list",
  "show",
  "create",
  "edit",
  "delete",
  "export",
  "refund",
];
const actionAliases = {
  read: ["list", "show"],
  write: ["create", "edit"],
  manage: ["read", "write", "delete", "export", "refund", "allowed_keys"],
  all: ["manage"],
};

const knownResources = [
  "accounts",
  "api_requests",
  "api_tokens",
  "attachments",
  "categories",
  "configs",
  "currency_merchants",
  "contracts",
  "currencies",
  "integrations",
  "locales",
  "merchant_products",
  "merchants",
  "prices",
  "products",
  "regions",
  "integration_products",
  "suppliers",
  "notifications",
  "tags",
  "orders",
  "order_merchant_products",
  "order_integration_products",
  "pending_prices",
  "translations",
  "uploads",
  "wallets",
  "web_hook_logs",
  "versions",
];

const aliasResources = { all: knownResources, alls: knownResources };
const allResources = knownResources;
const translateResources = (items) => {
  const resources = (Array.isArray(items) ? items : [items]).map((item) =>
    pluralize(snakeCase(item)),
  );

  return resources
    .map((resource) => {
      if (aliasResources[resource]) {
        return aliasResources[resource]
          .map((alias) => translateResources(alias))
          .flat(Infinity);
      } else {
        return resource;
      }
    })
    .flat(Infinity);
};

const translateActions = (action) => {
  const actions = Array.isArray(action) ? action : [action];
  return actions
    .map((action) => {
      if (actionAliases[action]) {
        return actionAliases[action]
          .map((alias) => translateActions(alias))
          .flat(Infinity);
      } else {
        return action;
      }
    })
    .flat(Infinity);
};

// can returns one of options
// object with filters -- action allowed with limitations
// empty object -- action allowed without limitations
// nil -- action denied
const saveCan = (action, resource, canCanPermissions) => {
  const { rules } = canCanPermissions;

  const requiredResources = translateResources(resource);
  const requiredActions = translateActions(action);

  const rule = rules.find((rule) => {
    const ruleResources = rule.subjects
      .map((subject) => translateResources(subject))
      .flat(Infinity);
    const ruleActions = rule.actions
      .map((action) => translateActions(action))
      .flat(Infinity);

    return (
      requiredResources.every((resource) => ruleResources.includes(resource)) &&
      requiredActions.every((action) => ruleActions.includes(action))
    );
  });

  return rule?.conditions || null;
};
const convertPermissionTable = (canCanPermissions) => {
  const table = {};

  allResources.forEach((resource) => {
    table[resource] = {};
    knownActions.forEach((action) => {
      table[resource][action] = saveCan(action, resource, canCanPermissions);
    });
  });
  return table;
};
const can = (resource, action, permissions) =>
  get(permissions, `${resource}.${action}`);
const permissionProvider = ({ permissions }) => ({
  setPermissions: () =>
    localStorage.setItem("permissions", JSON.stringify(permissions)),
  getPermissions: () => JSON.parse(localStorage.getItem("permissions")),
  can: (resource, action) =>
    get(
      JSON.parse(localStorage.getItem("permissions")),
      `${resource}.${action}`,
    ),
});

export { convertPermissionTable, permissionProvider, can };
