import React from "react";

import { useRecordContext, CreateButton } from "react-admin";
import { Remove as RemoveIcon } from "@mui/icons-material";
const MerchantDeductButton = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <CreateButton
      icon={<RemoveIcon />}
      sx={{ margin: 0 }}
      resource="contracts"
      state={{
        record: { type: "Contracts::Deduct", wallet_id: record.wallet_id },
      }}
      label="Deduct"
    />
  );
};
export default MerchantDeductButton;
