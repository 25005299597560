import * as React from "react";
import PropTypes from "prop-types";
import {
  ImageField,
  useRecordContext,
  useDataProvider,
  useRefresh,
  usePermissions,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageListItem from "@mui/material/ImageListItem";
import Box from "@mui/material/Box";

const ImageFieldWithRemoval = ({ source }) => {
  const [open, setOpen] = React.useState(false);

  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    dataProvider.delete("attachments", { id: record.id }).then(() => {
      refresh();
    });
    handleClose();
  };
  const { permissions } = usePermissions();

  return (
    <>
      <ImageListItem sx={{ display: "flex" }}>
        {can("attachments", "delete", permissions) && (
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleClickOpen}>
              <CancelIcon />
            </IconButton>
          </Box>
        )}
        <ImageField source={source} />
      </ImageListItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Removal"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ImageFieldWithRemoval.propTypes = {
  source: PropTypes.string,
};

export default ImageFieldWithRemoval;
