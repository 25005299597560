import * as React from "react";
import {
  useRecordContext,
  useDataProvider,
  Confirm,
  useRefresh,
  useNotify,
} from "react-admin";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { style } from "./style";

const CustomDeleteButton = ({ recordName, extRecord }) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const handleUndoDelete = async (e) => {
    e.stopPropagation();
    return dataProvider
      .delete(recordName, {
        id: record ? record.id : extRecord.id,
      })
      .then(() => {
        refresh();
        setOpen(false);
      })
      .catch(() => {
        notify("Failed to update record", "error");
      });
  };
  const handleReactivate = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const record = useRecordContext();
  if (
    (record && record.deleted_at !== null) ||
    (!record && extRecord?.deleted_at !== null)
  ) {
    return (
      <>
        <Button
          sx={style.deteteButton.buttonActivate}
          onClick={handleReactivate}
        >
          Activate
        </Button>
        <Confirm
          isOpen={open}
          title="Confirm Reactivation"
          content="Are you sure you want to reactivate this item?"
          confirm="Reactivate"
          cancel="Cancel"
          onConfirm={(e) => handleUndoDelete(e)}
          onClose={() => setOpen(false)}
        />
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={style.deteteButton.buttonWrapper}
          onClick={(e) => handleReactivate(e)}
        >
          Delete
        </Button>
        <Confirm
          isOpen={open}
          title="Confirm Removal"
          content="Are you sure you want to delete this item?"
          confirm="Delete"
          cancel="Cancel"
          onConfirm={handleUndoDelete}
          onClose={() => setOpen(false)}
        />
      </>
    );
  }
};

CustomDeleteButton.propTypes = {
  recordName: PropTypes.string,
  extRecord: PropTypes.object,
};

export default CustomDeleteButton;
