import React, { useState, useRef } from "react";
import {
  FunctionField,
  useDataProvider,
  useRefresh,
  useResourceContext,
  usePermissions,
  useNotify,
  useTranslate,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import { get } from "lodash";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ModeIcon from "@mui/icons-material/Mode";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { copyToClipboard } from "./copyToClip";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import Tooltip from "@mui/material/Tooltip";
const Wrapper = ({
  source,
  label,
  getter,
  type = "text",
  maxChar = 255,
  truncate,
  justify,
  na,
  withCopy,
}) => (
  <FunctionField
    label={label}
    render={(record) => (
      <EditableField
        source={getter || source}
        record={record}
        type={type}
        maxChar={maxChar}
        truncate={truncate}
        justify={justify}
        na={na}
        withCopy={withCopy}
      />
    )}
  />
);

Wrapper.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  getter: PropTypes.func,
  type: PropTypes.string,
  maxChar: PropTypes.number,
  truncate: PropTypes.object,
  justify: PropTypes.object,
  na: PropTypes.bool,
  withCopy: PropTypes.bool,
};

export const EditableField = ({
  source,
  record,
  type,
  showExtra,
  classes,
  maxChar,
  truncate,
  justify,
  na,
  withCopy,
}) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const translate = useTranslate();
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(get(record, source));
  const anchorRef = useRef(null);
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const canEdit = can(resource, "edit", permissions);
  const role = localStorage.getItem("role");
  const handleInputChange = (e) => setInputValue(e.target.value);
  const handleSave = () => {
    if (inputValue.length > maxChar) {
      notify(`Maximum character limit of ${maxChar} exceeded`, "error");
      return;
    } else {
      dataProvider
        .update(resource, {
          id: record.id,
          data: {
            [source.replace("translations.", "").split(".")]: inputValue,
          },
        })
        .then(() => {
          refresh();
          notify("Saved");
          setIsEditing(false);
        })
        .catch((error) => error.message && notify(error.message, "error"));
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setInputValue(get(record, source));
    setIsEditing(false);
  };

  const handleKeyDown = (e) => e.key === "Enter" && handleSave();

  const handleEditClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditing(true);
  };

  const InputComponent = type === "textarea" ? TextareaAutosize : Input;
  const inputType = type === "textarea" ? "text" : type;
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: showExtra ? "space-between" : "unset",
        ...justify,
      }}
      ref={anchorRef}
    >
      {canEdit && isEditing ? (
        <Popper
          open={isEditing}
          anchorEl={anchorRef.current}
          placement={showExtra ? "bottom-end" : "bottom-start"}
        >
          <Paper
            style={{ padding: "8px", display: "flex", alignItems: "center" }}
          >
            <InputComponent
              type={inputType}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              autoFocus
              fullWidth
              style={{ marginRight: "8px" }}
            />
            <IconButton onClick={handleSave} size="small" color="primary">
              <CheckIcon />
            </IconButton>
            <IconButton onClick={handleCancel} size="small" color="secondary">
              <CloseIcon />
            </IconButton>
          </Paper>
        </Popper>
      ) : (
        <>
          <FunctionField
            render={(record) =>
              (
                <Box sx={truncate ? { ...truncate } : {}}>
                  {" "}
                  {get(record, source)}{" "}
                </Box>
              ) || "N/A"
            }
          />
          {withCopy && inputValue && (
            <Tooltip title="Copy to Clipboard" arrow>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={(e) =>
                  copyToClipboard(e, inputValue, notify, translate)
                }
              >
                <CopyAllIcon />
              </Box>
            </Tooltip>
          )}
          {canEdit && (
            <>
              <Box sx={{ ...classes }}>{na && !inputValue ? "N/A" : ""}</Box>
              {showExtra && (
                <Box sx={{ ...classes }}>{inputValue ? inputValue : "N/A"}</Box>
              )}

              {role === "Accounts::SuperAdmin" && (
                <IconButton onClick={handleEditClick} size="small">
                  <ModeIcon fontSize="small" />
                </IconButton>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

EditableField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  type: PropTypes.string,
  showExtra: PropTypes.bool,
  classes: PropTypes.object,
  maxChar: PropTypes.number,
  truncate: PropTypes.object,
  justify: PropTypes.object,
  na: PropTypes.bool,
  withCopy: PropTypes.bool,
};

export default Wrapper;
