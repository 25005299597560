export const style = {
  actionButton: {
    minWidth: "32px",
    height: "32px",
    cursor: "pointer",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "6px",
    boxShadow:
      "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
};
