import { stringify } from "query-string";
import { fetchUtils } from "ra-core";
import { mapKeys, snakeCase } from "lodash";
const snakeify = (params) => mapKeys(params, (_, key) => snakeCase(key));
const httpClient = fetchUtils.fetchJson;

const formatError = (errorObject) => {
  try {
    let errorMessage = "";
    let keys = Object.keys(errorObject.errors);
    keys.forEach((key, index) => {
      errorMessage += `${key}: ${errorObject.errors[key].join(", ")}`;
      if (index < keys.length - 1) {
        errorMessage += ", ";
      }
    });
    return errorMessage;
  } catch (error) {
    return "Error: Data could not be processed. Please try again.";
  }
};
const defaultHeaders = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "Bearer " + localStorage.getItem("token"),
});
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const processArray = async (array) => {
  const promises = array.map(async (item) => {
    if (item instanceof File) {
      return await convertFileToBase64(item);
    } else if (Array.isArray(item)) {
      return await processArray(item);
    } else if (item instanceof Object) {
      return await processData(item);
    } else {
      return item;
    }
  });
  return await Promise.all(promises);
};
const checkIsDate = (value) => {
  try {
    return value.toISOString();
  } catch (e) {
    return false;
  }
};
const processData = async (data) => {
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    if (
      key === "id" ||
      key === "created_at" ||
      key === "updated_at" ||
      key === "deleted_at" ||
      key === "images" ||
      key.includes("_count")
    ) {
      continue;
    } else if (value instanceof File) {
      const convertedFile = await convertFileToBase64(value);
      result[snakeCase(key)] = convertedFile;
    } else if (Array.isArray(value)) {
      result[snakeCase(key)] = await processArray(value);
    } else if (checkIsDate(value)) {
      result[snakeCase(key)] = value.toISOString();
    } else if (value instanceof Object) {
      result[snakeCase(key)] = await processData(value);
    } else {
      result[snakeCase(key)] = value;
    }
  }

  return result;
};

export default (apiUrl) =>
  ({ csrfToken }) => ({
    getList: (resource, params = {}) => {
      const { page = 1, perPage = 100 } = params.pagination || {};
      const { field = "id", order = "ASC" } = params.sort || {};

      const filter = fetchUtils.flattenObject(params.filter);

      const query = {
        ...filter,
        _sort: field,
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage,
      };

      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url, {
        headers: new Headers(defaultHeaders()),
      }).then(({ headers, json }) => {
        if (!headers.has("x-total-count")) {
          throw new Error(
            "The X-Total-Count header is missing in the HTTP Response.",
          );
        }

        const excludeId = query.excludeId;
        const filteredData = json.filter((item) => item.id !== excludeId);

        return {
          data: filteredData,
          total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
        };
      });
    },
    sync: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}/sync`, {
        method: "POST",
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      }).then(({ json }) => json),
    getOne: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        headers: new Headers(defaultHeaders()),
      }).then(({ json }) => ({
        data: json,
      })),
    export: (resource, params) => {
      const query = Object.assign(
        Object.assign({}, fetchUtils.flattenObject(params.filter)),
      );
      return httpClient(`${apiUrl}/${resource}/export?${stringify(query)}`, {
        headers: new Headers(defaultHeaders()),
      });
    },
    pivot: (resource, params) => {
      const query = Object.assign(
        Object.assign({}, fetchUtils.flattenObject(params.filter)),
      );
      return httpClient(`${apiUrl}/${resource}/pivot?${stringify(query)}`, {
        headers: new Headers(defaultHeaders()),
      });
    },
    populate: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}/populate`, {
        method: "POST",
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      }).then(({ json }) => json),
    getManyOneByOne: (resource, params) => {
      const requests = params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          headers: new Headers(defaultHeaders()),
        }),
      );

      return Promise.all(requests).then((data) => ({ data }));
    },

    getMany: (resource, params) => {
      const query = { "id[]": params.ids };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url, {
        headers: new Headers(defaultHeaders()),
      }).then(({ json }) => ({ data: json }));
    },
    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = Object.assign(
        Object.assign({}, fetchUtils.flattenObject(params.filter)),
        {
          [params.target]: params?.id,
          _sort: field,
          _order: order,
          _start: (page - 1) * perPage,
          _end: page * perPage,
        },
      );
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url, {
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      }).then(({ headers, json }) => {
        if (!headers.has("x-total-count")) {
          throw new Error(
            "The X-Total-Count header is missing in the HTTP Response.",
          );
        }

        return {
          data: json,
          total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
        };
      });
    },
    checkMerge: async (resource, params) => {
      const data = params.data;

      return httpClient(`${apiUrl}/${resource}/${params.id}/can_merge`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      })
        .then(({ json }) => ({ data: json }))
        .catch((error) => {
          if (error.status === 400) {
            return Promise.reject({
              message: formatError(error.body),
            });
          } else {
            return error.message;
          }
        });
    },
    performMerge: async (resource, params) => {
      const data = params.data;
      return httpClient(`${apiUrl}/${resource}/${params.id}/merge`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      })
        .then(({ json }) => ({ data: json }))
        .catch((error) => {
          if (error.status === 400) {
            return Promise.reject({
              message: formatError(error.body),
            });
          } else {
            return error.message;
          }
        });
    },
    update: async (resource, params) => {
      const data = await processData({ [resource]: params.data });

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      })
        .then(({ json }) => ({ data: json }))
        .catch((error) => {
          if (error.status === 400) {
            return Promise.reject({
              message: formatError(error.body),
            });
          } else {
            return error.message;
          }
        });
    },
    bulkUpdate: async (resource, params = null) => {
      let options = {
        method: "POST",
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      };

      if (params) {
        const data = await processData({ [resource]: params });
        options.body = JSON.stringify(data);
      }

      return httpClient(`${apiUrl}/${resource}/bulk_update`, options);
    },
    updateMany: async (resource, params) =>
      Promise.all(
        params.ids.map(async (id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(
              await processData({ [resource]: params.data }),
            ),
            headers: new Headers({
              "X-CSRF-Token": csrfToken,
              ...defaultHeaders(),
            }),
          }),
        ),
      ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

    create: async (resource, params) => {
      const data = await processData({ [resource]: params.data });

      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      })
        .then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }))
        .catch((error) => {
          if (error.status === 400) {
            return Promise.reject({
              message: formatError(error.body),
            });
          } else {
            return error.message;
          }
        });
    },

    delete: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "DELETE",
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
            headers: new Headers({
              "X-CSRF-Token": csrfToken,
              ...defaultHeaders(),
            }),
          }),
        ),
      ).then((responses) => ({ data: responses.map(({ json }) => json?.id) })),
    // Deprecated.
    // Ensure this is not used anymore. Remove it then
    // getList("locales") should be used instead
    locales: () => {
      // eslint-disable-next-line no-console
      console.error(
        "This method is deprecated. Use getList('locales') instead",
      );
      return httpClient(`${apiUrl}/locales`, {
        headers: new Headers(defaultHeaders()),
      }).then(({ json }) =>
        json.map(({ code, name }) => ({ locale: code, name })),
      );
    },
    // Deprecated.
    // Ensure this is not used anymore. Remove it then
    // getList("intercom_settings") should be used instead

    intercomSettings: () => {
      // eslint-disable-next-line no-console
      console.error(
        "This method is deprecated. Use getList('intercom_settings') instead",
      );
      return httpClient(`${apiUrl}/intercom_settings`, {
        headers: new Headers(defaultHeaders()),
      }).then(({ json }) => json);
    },
    refundContract: (contractId) =>
      httpClient(`${apiUrl}/contracts/${contractId}/refund`, {
        method: "POST",
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      }).then(({ json }) => json),
    refundOrder: (orderId) =>
      httpClient(`${apiUrl}/orders/${orderId}/refund`, {
        method: "POST",
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      }).then(({ json }) => json),
    resendOrder: (orderId) =>
      httpClient(`${apiUrl}/orders/${orderId}/resend`, {
        method: "POST",
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      }).then(({ json }) => json),
    raiseOrderComplaint: (orderId) =>
      httpClient(`${apiUrl}/orders/${orderId}/raise_complaint`, {
        method: "POST",
        headers: new Headers({
          "X-CSRF-Token": csrfToken,
          ...defaultHeaders(),
        }),
      }).then(({ json }) => json),

    get: (resource, params) => {
      return httpClient(
        `${apiUrl}/${resource}/?${stringify(snakeify(params))}`,
        {
          headers: new Headers(defaultHeaders()),
        },
      ).then(({ json }) => json);
    },
    getDashboard: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/?${stringify(params)}`, {
        headers: new Headers(defaultHeaders()),
      }).then(({ json }) => json);
    },
  });
