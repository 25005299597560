import React, { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import calendar from "../../../assets/icons/calendar.svg";
import PropTypes from "prop-types";
import { style } from "./style";

const FilterCalendar = ({ setStart, setEnd, after, id }) => {
  const getTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split("T")[0];
  };

  const getOneMonthAgo = () => {
    const monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    return monthAgo.toISOString().split("T")[0];
  };

  const [isOpen, setIsOpen] = useState(false);
  const [afterDate, setAfterDate] = useState(
    sessionStorage.getItem("afterDate") || getOneMonthAgo(),
  );
  const [beforeDate, setBeforeDate] = useState(
    sessionStorage.getItem("beforeDate") || getTomorrow(),
  );

  const dropdownRef = useRef();

  useEffect(() => {
    if (after) {
      setStart(afterDate);
      sessionStorage.setItem("afterDate", afterDate);
    } else {
      setEnd(beforeDate);
      sessionStorage.setItem("beforeDate", beforeDate);
    }
  }, [afterDate, beforeDate, setStart, setEnd, after]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToggle = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <Box
      ref={dropdownRef}
      sx={style.filterCalendar}
      onClick={() => document.getElementById(`date-picker-${id}`).showPicker()}
    >
      <img src={calendar} alt="calendar" width={"13px"} height={"15px"} />
      <Box sx={{ fontSize: "0.74rem" }}>{after ? "After:" : "Before:"}</Box>
      <Box>{after ? afterDate : beforeDate}</Box>
      <ExpandMoreIcon />
      {true && (
        <input
          ref={dropdownRef}
          onClick={(e) => e.stopPropagation()}
          id={`date-picker-${id}`}
          type="date"
          value={after ? afterDate : beforeDate}
          onChange={(e) => {
            if (after) {
              setAfterDate(e.target.value);
            } else {
              setBeforeDate(e.target.value);
            }
            handleToggle(e);
          }}
          style={{
            ...style.datePicker,
          }}
        />
      )}
    </Box>
  );
};

FilterCalendar.propTypes = {
  setStart: PropTypes.func.isRequired,
  setEnd: PropTypes.func.isRequired,
  after: PropTypes.bool,
  id: PropTypes.string,
};

export default FilterCalendar;
