import * as React from "react";
import {
  BooleanInput,
  required,
  minLength,
  maxLength,
  Edit,
  SimpleForm,
  TextInput,
  regex,
} from "react-admin";
import CurrencyShow from "./Show";
import InDrawer from "../../hocs/InDrawer";

const CurrencyEdit = () => {
  return (
    <Edit
      title=" "
      actions={<></>}
      mutationMode="pessimistic"
      undoable={false}
      redirect="show"
    >
      <SimpleForm>
        <TextInput
          fullWidth
          source="iso_code"
          validate={[required(), regex(/^[A-Z]{3}$/, "3 letters uppercase")]}
        />
        <TextInput
          fullWidth
          source="name"
          validate={[required(), maxLength(60), minLength(3)]}
        />
        <BooleanInput source="default" />
      </SimpleForm>
    </Edit>
  );
};
export default InDrawer(
  <CurrencyShow />,
  <CurrencyEdit />,
  "Edit Currency",
  "Edit Currency details",
);
