import * as React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  required,
  NumberInput,
  minValue,
  BooleanInput,
  minLength,
  ReferenceArrayInput,
  usePermissions,
  SelectInput,
} from "react-admin";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";
import MerchantShow from "./Show";
import InDrawer from "../../hocs/InDrawer";
import { can } from "../../../shared/permissionProvider";
import { TimezoneInput } from "../../components/Timezone";
const MerchantEdit = () => {
  const role = localStorage.getItem("role");
  const { permissions } = usePermissions();
  const canEditMerchant = can("merchants", "edit", permissions);
  return (
    <Edit
      title={" "}
      actions={<></>}
      mutationMode="pessimistic"
      undoable={false}
      redirect="show"
    >
      <SimpleForm toolbar={<CustomEditToolbar recordName={"merchants"} />}>
        {canEditMerchant && (
          <TextInput fullWidth source="name" validate={[required()]} />
        )}
        {canEditMerchant && (
          <ReferenceArrayInput
            source="account_ids"
            reference="accounts"
            filter={{ type: "Accounts::MerchantAdmin", active: true }}
          />
        )}
        {canEditMerchant && (
          <NumberInput source="credit" fullWidth validate={[minValue(0)]} />
        )}
        {canEditMerchant && (
          <SelectInput
            fullWidth
            validate={[required()]}
            source="merchant_type"
            choices={[
              { id: "Internal", name: "Internal" },
              { id: "External", name: "External" },
            ]}
          />
        )}
        {canEditMerchant && (
          <NumberInput
            source="vat_rate"
            fullWidth
            validate={[minValue(0), required()]}
          />
        )}
        <TimezoneInput />
        <TextInput fullWidth source="webhook_url" />
        <TextInput
          fullWidth
          source="webhook_secret"
          validate={[minLength(15)]}
        />
        <BooleanInput source="webhook_enabled" />
        <BooleanInput source="webhook_on_product_change" />
        <BooleanInput source="webhook_on_order_change" />
        {role === "Accounts::SuperAdmin" && <BooleanInput source="vatable" />}
        <BooleanInput source="send_daily_reports" />
      </SimpleForm>
    </Edit>
  );
};
export default InDrawer(
  <MerchantShow />,
  <MerchantEdit />,
  "Edit Merchant",
  "Edit Merchant details",
);
