import * as React from "react";
import {
  DateField,
  NumberField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  Button,
  useRecordContext,
  useNotify,
  SelectField,
} from "react-admin";
import SafeJsonField from "../../components/SafeJsonField";
import EditableField from "../../components/Editable/Field";

import VersionList from "../../components/Version/List";

const SyncButton = () => {
  const notify = useNotify();
  const { id, active } = useRecordContext();
  const dataProvider = useDataProvider();
  const [syncInProgress, setSyncInProgress] = React.useState(false);
  const performSync = () => {
    setSyncInProgress(true);
    return dataProvider
      .sync("integrations", { id })
      .then(() => {
        notify("Syncronization scheduled");
      })
      .catch(() => {
        notify("Syncronization not sceduled", { type: "warning" });
      });
  };
  if (active) {
    return (
      <Button label="Sync" onClick={performSync} disabled={syncInProgress} />
    );
  } else {
    return null;
  }
};

const IntegrationShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <SyncButton />
          <TextField source="id" />
          <EditableField source="name" />
          <ReferenceField
            source="supplier_id"
            reference="suppliers"
            label="Integration"
          >
            <TextField source="name" />
          </ReferenceField>
          <SafeJsonField source="config" />
          <NumberField source="integration_products_count" />
          <SelectField
            source="provider_type"
            choices={[
              { id: "Internal", name: "Internal" },
              { id: "External", name: "External" },
            ]}
          />
          <DateField source="created_at" />
          <DateField source="updated_at" />
          <TextField source="deleted_at" />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="history">
          <VersionList item_type="Integration" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default IntegrationShow;
