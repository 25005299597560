import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import EditableField from "../../components/Editable/Field";

export const ConfigShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <EditableField source="value" />
      <EditableField source="key" />
    </SimpleShowLayout>
  </Show>
);
export default ConfigShow;
