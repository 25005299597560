const translatableFields = {
  products: [
    "name",
    "redeem_steps",
    "how_to_use",
    "description",
    "terms_and_conditions",
  ],
  categories: ["name"],
};
export default translatableFields;
