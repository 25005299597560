export const neutral = {
  25: "#F6F8FA",
  50: "#ECEFF3",
  100: "#DFE1E6",
  200: "#C1C7CF",
  300: "#A4ABB8",
  400: "#808897",
  500: "#666D80",
  600: "#353849",
  700: "#272835",
  800: "#1A1B25",
  900: "#0D0D12",
  A100: "#091948",
  A200: "#072045",
  A400: "#021030",
  A700: "#010213",
};

export const primary = {
  50: "#F0EFFF",
  100: "#E5E4FB",
  200: "#A19CF0",
  300: "#5E56FF",
  400: "#312BA0",
  500: "#201C6C",
};
