import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  NumberField,
  TextInput,
} from "react-admin";

const TagsFilter = [<TextInput label="search" source="name" key="name" />];

const TagList = () => (
  <List filters={TagsFilter} exporter={false}>
    <Datagrid rowClick="show">
      <NumberField source="id" />
      <TextField source="name" />
      <NumberField source="tag_items_count" />
      <DateField source="deleted_at" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);
export default TagList;
