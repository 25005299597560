import ApiIcon from "@mui/icons-material/Api";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TranslateIcon from "@mui/icons-material/Translate";
import LanguageIcon from "@mui/icons-material/Language";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudIcon from "@mui/icons-material/Cloud";
import DifferenceIcon from "@mui/icons-material/Difference";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import GavelIcon from "@mui/icons-material/Gavel";
import ListAltIcon from "@mui/icons-material/ListAlt";

export {
  ApiIcon,
  NotificationsActiveIcon,
  TranslateIcon,
  LanguageIcon,
  PeopleIcon,
  InventoryIcon,
  SouthAmericaIcon,
  ProductionQuantityLimitsIcon,
  IntegrationInstructionsIcon,
  FileUploadIcon,
  CloudIcon,
  DifferenceIcon,
  WarehouseIcon,
  GavelIcon,
  ListAltIcon,
};
