import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  BooleanInput,
  TextInput,
  usePermissions,
  TopToolbar,
  ShowButton,
  ReferenceInput,
  FilterButton,
  CreateButton,
} from "react-admin";
import EditableField from "../../components/Editable/Field";
import { Box } from "@material-ui/core";
import { can } from "../../../shared/permissionProvider";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import ExportButton from "../../components/Export/ExportButton";

const CategoryFilter = [
  <BooleanInput source="active" key="active" />,
  <TextInput source="name" key="name" />,
  <ReferenceInput
    source="category_id"
    reference="categories"
    label="Parent Category"
    key="parent_category"
  />,
];

const ListActions = () => {
  const { permissions } = usePermissions();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <TopToolbar sx={{ position: "relative" }}>
        <FilterButton />
        <ExportButton />
        {can("categories", "create", permissions) && <CreateButton />}
      </TopToolbar>
    </Box>
  );
};

const CategoryList = () => {
  const { permissions } = usePermissions();

  return (
    <List
      filters={CategoryFilter}
      exporter={false}
      actions={<ListActions />}
      filterDefaultValues={{ active: true }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <EditableField
          source="name"
          sortable={false}
          justify={{ justifyContent: "center" }}
        />
        <ReferenceField
          source="category_id"
          reference="categories"
          label="Parent Category"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="region_id" reference="regions">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ShowButton sx={{ height: "35px" }} />
          {can("categories", "delete", permissions) && (
            <CustomDeleteButton recordName={"categories"} />
          )}
        </Box>
      </Datagrid>
    </List>
  );
};

export default CategoryList;
