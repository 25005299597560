import React from "react";
import { createTheme } from "@mui/material/styles";
import { defaultTheme } from "react-admin";
import { red } from "@mui/material/colors";
import { CheckboxDefault } from "../../../assets/icons/CheckboxDefault";
import { ToggleDefault } from "../../../assets/icons/ToggleDefault";
import { ToggleActive } from "../../../assets/icons/ToggleActive";
import { neutral, primary } from "./colors";

export const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: primary,
    error: red,

    background: {
      default: "#F6F8FA",
    },
  },

  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          color: "black",
          "& label": {},
          "& label.Mui-focused": {
            color: "#5F57FF",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#5F57FF52",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {},
            "&:hover fieldset": {
              borderColor: "#5F57FF52",
              borderWidth: "0.15rem",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#5F57FF",
            },
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          width: "90%",
          marginLeft: "10px",
          backgroundColor: "transparent",
          "&.RaMenuItemLink-active": {
            backgroundColor: "white",
            borderRadius: " 10px",
            border: "1px solid var(#ECEFF3, rgba(18, 55, 105, 0.08))",
            boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
            "& .RaMenuItemLink-icon": {
              color: "#BF57FF",
            },
          },
          "&:focus": {
            "& .RaMenuItemLink-icon": {
              color: "#BF57FF",
            },
          },
        },
      },
    },
    RaMenu: {
      styleOverrides: {
        root: {
          "&.RaMenu-open": {
            width: "308px",
            marginTop: "10px",
            padding: "0px",
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          width: "300px",
          "& .RaSidebar-fixed": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
          "&.RaSidebar-appBarCollapsed": {
            marginTop: "0px",
          },
        },
      },
    },
    RaButton: {
      styleOverrides: {
        root: {
          display: "flex",
          cursor: "pointer",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          padding: "8px",
          height: "32px",
          gap: "4px",
          textTransform: "Capitalize",
          borderRadius: "8px",
          boxShadow:
            " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    RaCreateButton: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          width: "100px",
          borderRadius: "6px",
          background: "transparent",
          display: "flex",
          backgroundColor: "transparent",
          color: "#201C6C",
          boxShadow:
            " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
          "&:hover": {
            boxShadow: "unset",
            border: "1px solid",
            borderColor: neutral[100],
            backgroundColor: neutral[25],
            transition: "unset",
          },
        },
      },
    },
    RaSelectColumnsButton: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          width: "100px",
          height: "32px",
          borderRadius: "6px",
          background: "transparent",
          display: "flex",
          backgroundColor: "transparent",
          textTransform: "Capitalize",
          color: "#201C6C",
          boxShadow:
            " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
          "&:hover": {
            boxShadow: "unset",
            border: "1px solid",
            borderColor: neutral[100],
            backgroundColor: neutral[25],
            transition: "unset",
          },
        },
      },
    },
    RaEditButton: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          width: "100px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "transparent",
          backgroundColor: "transparent",
          display: "flex",
          color: "#201C6C",
          boxShadow:
            " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
          "&:hover": {
            boxShadow: "unset",
            border: "1px solid",
            borderColor: neutral[100],
            backgroundColor: neutral[25],
            transition: "unset",
          },
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          marginRight: "20px",
        },
      },
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        root: {
          position: "relative",
          maxWidth: "100%",
          overflow: "auto",

          "&::-webkit-scrollbar": {
            width: "3px",
            height: "3px",
          },
          "& .RaTabbedShowLayout-content": {
            maxHeight: "calc(100vh - 180px)",
            overflow: "auto",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(150, 150, 150, 0.7)",
            borderRadius: "12px",
          },

          "&::-webkit-scrollbar-track": {
            backgroundColor: "rgba(200, 200, 200, 0.3)",
          },

          scrollbarWidth: "thin",
          scrollbarColor: "rgba(150, 150, 150, 0.7) rgba(200, 200, 200, 0.3)",
        },
      },
    },

    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          maxHeight: "calc(100vh - 180px)",
          overflow: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(150, 150, 150, 0.7) rgba(200, 200, 200, 0.3)",
          "&::-webkit-scrollbar": {
            width: "3px",
            height: "3px",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(150, 150, 150, 0.7)",
            borderRadius: "12px",
          },

          "&::-webkit-scrollbar-track": {
            backgroundColor: "rgba(200, 200, 200, 0.3)",
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .column-merchant_ids": {
            width: "400px",
            justifyContent: "center",
          },
          "& .RaDatagrid-headerCell": {
            backgroundColor: neutral[25],
            padding: "6px",
            "&:first-of-type": {
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            },
            "&:last-of-type": {
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            },
          },
          "& .RaDatagrid-tableWrapper": {
            maxWidth: "100%",

            overflow: "auto",
            maxHeight: "calc(100vh - 200px)",
            /* Scrollbar styling */
            "&::-webkit-scrollbar": {
              width: "3px", // Thinner scrollbar for WebKit browsers
              height: "3px", // Thinner horizontal scrollbar
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(150, 150, 150, 0.7)", // Lighter thumb color
              borderRadius: "12px", // More rounded scrollbar
            },

            "&::-webkit-scrollbar-track": {
              backgroundColor: "rgba(200, 200, 200, 0.3)", // Lighter track color for contrast
            },

            /* For Firefox */
            scrollbarWidth: "thin", // Thin scrollbar for Firefox
            scrollbarColor: "rgba(150, 150, 150, 0.7) rgba(200, 200, 200, 0.3)",
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "16px",
          boxShadow: "none",
          border: "none",
          textAlign: "center",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid",
          borderColor: neutral[100],
          "&:first-of-type": {
            borderTop: "none",
          },
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    RaToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px",

          border: "none",
          color: "#818898",
          "&:before": {
            borderBottom: "none !important",
          },
          "&:hover": {
            border: "none",
          },
        },
      },
    },
    RaLocalesMenuButton: {
      styleOverrides: {
        root: {
          border: "1px solid ",
          borderColor: primary[100],
          borderRadius: "8px",
          backgroundColor: "transparent",
          "& .RaLocalesMenuButton-icon": {
            color: "#5F57FF",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: 0.1,
        },
        root: {
          minWidth: "250px",
          color: "black",
          "&:hover": {
            backgroundColor: "transparent",
            border: "none",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
          borderRadius: "8px",
          background: "#FFF",

          "&:before": {
            borderBottom: "none !important",
          },
          "&:after": {
            borderBottom: "none !important",
          },
          "&.Mui-focused": {
            backgroundColor: "transparent",
            notchedOutline: {
              borderColor: "red",
            },
          },
        },
      },
    },

    RaSaveButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), #5F57FF",

          boxShadow:
            "0px 0px 0px 1px #4F47EB, 0px 1px 2px 0px rgba(26, 19, 161, 0.50)",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxDefault />,
      },
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
            stroke: neutral[50],
          },
          "&:active": {
            backgroundColor: "transparent",
            stroke: primary[300],
          },

          "&.Mui-checked": {
            color: primary[300],
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "6px",
          borderRadius: "10px",
          background:
            "var(--Form-Button-Secondary-Normal, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #FFF)",
          boxShadow:
            "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
          marginLeft: "5px",
          marginRight: "5px",
          width: "32px",
          height: "32px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          "& .MuiTablePagination-displayedRows": {
            marginRight: "auto",
          },
          ".RaCreateButton-root": {
            backgroundColor: "transparent",
            margin: "unset",
          },
        },
      },
    },
    RaFileInput: {
      styleOverrides: {
        root: {
          "& .RaFileInput-dropZone": {
            backgroundColor: "transparent",
          },
          "& .RaImageInput": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          opacity: "0.3 !important",
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        icon: <ToggleDefault />,
        checkedIcon: <ToggleActive />,
      },
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          marginRight: "10px",
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(0px)",
          },
          "& .MuiSwitch-track": {
            display: "none",
          },
          "&:hover": {
            backgroundColor: "transparent",
            "&$disabled": {
              backgroundColor: "transparent",
            },
          },
          "&:active": {
            backgroundColor: "transparent",
            "& .MuiSwitch-thumb": {},
          },
        },
        disabled: {},
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          height: "100%",
          maxWidth: "100%",
          "& .RaList-main": {
            width: "100%",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          marginTop: "auto",
          backgroundColor: neutral[25],
          marginBottom: "-24px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          paddingBottom: "20px",
          "& .MuiButton-root": {
            display: "flex",
            cursor: "pointer",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "150px",
            padding: "8px",
            height: "32px",
            gap: "4px",
            textTransform: "Capitalize",
            borderRadius: "8px",
            boxShadow:
              " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
          },
        },
      },
    },

    MuiListItemText: {
      root: {
        textAlign: "center",
      },
      styleOverrides: {
        primary: {
          textAlign: "unset",
          fontFamily: "Inter",
          fontWeight: "700",
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
    },
    RaSingleFieldList: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
