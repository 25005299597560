import React from "react";
import { useLogout, useGetIdentity } from "react-admin";
import { Box } from "@mui/material";
import { Menu } from "react-admin";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import userAvatar from "../../../assets/icons/userAvatar.svg";
import { useTranslate } from "react-admin";
import PropTypes from "prop-types";
const UserSettings = ({ collapsed }) => {
  const { data } = useGetIdentity();
  const logout = useLogout();
  const translate = useTranslate();

  return (
    <Box
      sx={{
        marginTop: "auto",
        marginBottom: "10px",
      }}
    >
      <Divider
        sx={{
          borderStyle: "dashed",
          width: "95%",
          marginLeft: "10px",
          marginBottom: "10px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: !collapsed ? "25px" : "15px",
        }}
      >
        <Box>
          {<Avatar sx={{ width: "35px", height: "35px" }} src={userAvatar} />}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {data?.name && !collapsed && <Box>{data.name}</Box>}

          <Box
            sx={{
              fontSize: "14px",
              color: "#818898",
              display: "flex",
            }}
          >
            {data?.email && !collapsed && data.email}
          </Box>
        </Box>
      </Box>
      <Menu>
        <Menu.Item
          sx={{ marginLeft: !collapsed ? "10px" : "0px" }}
          to="login"
          primaryText={!collapsed && translate("signOut", { _: "Sign out" })}
          leftIcon={<LogoutIcon />}
          onClick={logout}
        />
      </Menu>
    </Box>
  );
};

UserSettings.propTypes = {
  collapsed: PropTypes.bool,
};

export default UserSettings;
