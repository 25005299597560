import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  useRecordContext,
} from "react-admin";

import { PolymorphicLink } from "../../components";
import SafeJsonField from "../../components/SafeJsonField";
const CallerLink = () => {
  const { caller_id, caller_type } = useRecordContext();
  if (!caller_id || !caller_type) return null;

  return <PolymorphicLink type={caller_type} id={caller_id} />;
};

const ShowAccount = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <NumberField source="id" />
        <CallerLink source="caller" />
        <TextField source="url" />
        <TextField source="http_method" />
        <SafeJsonField
          source="request_headers"
          reactJsonOptions={{
            name: null,
            collapsed: true,
            displayDataTypes: false,
          }}
        />
        <SafeJsonField
          source="request_params"
          jsonString={true}
          reactJsonOptions={{
            name: null,
            collapsed: true,
            displayDataTypes: false,
          }}
        />
        <SafeJsonField
          source="response_headers"
          reactJsonOptions={{
            name: null,
            collapsed: true,
            displayDataTypes: false,
          }}
        />
        <SafeJsonField
          source="response_body"
          jsonString={true}
          reactJsonOptions={{
            name: null,
            collapsed: true,
            displayDataTypes: false,
          }}
        />
        <NumberField source="response_status" />
        <DateField source="created_at" showTime />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowAccount;
