import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  FunctionField,
  TextInput,
  DateTimeInput,
  ShowButton,
} from "react-admin";
import HTTPbadge from "../../components/Badges/HTTPbadge";
const Filter = [
  <TextInput source="url" label="Search by URL" alwaysOn key="url" />,
  <DateTimeInput source="before" label="Before" key="before" />,
  <DateTimeInput source="after" label="After" key="after" />,
];
const ApiRequestList = () => (
  <List
    exporter={false}
    filterDefaultValues={{ active: true }}
    filters={Filter}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <NumberField source="id" />
      <TextField source="url" />
      <FunctionField
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        source="http_method"
        render={(request) => <HTTPbadge text={request.http_method} />}
      />
      <NumberField source="response_status" />
      <DateField source="created_at" showTime />
      <ShowButton />
    </Datagrid>
  </List>
);

export default ApiRequestList;
