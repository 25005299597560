import React from "react";
import { useRecordContext, CreateButton } from "react-admin";

const MerchantTopupButton = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <CreateButton
      sx={{ margin: 0 }}
      resource="contracts"
      state={{
        record: { type: "Contracts::Topup", wallet_id: record.wallet_id },
      }}
      label="Topup"
    />
  );
};
export default MerchantTopupButton;
