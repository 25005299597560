import * as React from "react";
import PropTypes from "prop-types";
import { Box, Collapse, ListItemText, MenuItem } from "@mui/material";
import {
  Menu,
  Sidebar,
  useResourceDefinitions,
  MenuItemLink,
} from "react-admin";
import playheraIcon from "../../../assets/icons/playheraIcon.svg";
import playheraText from "../../../assets/icons/playheraText.svg";
import UserSettings from "./UserSettings";
import ListIcon from "@mui/icons-material/List";
import HomeIcon from "@mui/icons-material/Home";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslate } from "react-admin";
import Divider from "@mui/material/Divider";
import { SettingsIcon } from "../../../assets/icons/SidebarIcons";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

const SuperAdminSidebar = ({ dashboard, collapsed }) => {
  const location = useLocation();
  const locationPath = location.pathname.split("/")[1];
  const translate = useTranslate();

  const resourcesDefinitions = useResourceDefinitions();

  const resources = Object.keys(resourcesDefinitions)
    .map((name) => resourcesDefinitions[name])
    .filter((r) => r.hasList);

  const menuItems = {
    orders: [
      { resource: "orders", label: "Merchant Orders" },
      { resource: "order_integration_products", label: "Provider Orders" },
    ],
    products: [
      { resource: "products", label: "Playhera Products" },
      { resource: "merchant_products", label: "Merchant Products" },
      { resource: "integration_products", label: "Provider Products" },
    ],
    middle: [
      { resource: "contracts", label: "Contracts" },
      { resource: "pending_prices", label: "Pending Prices" },
      { resource: "merchants", label: "Merchants" },
      { resource: "integrations", label: "Providers" },
      { resource: "accounts", label: "Accounts" },
    ],
    settings: [
      { resource: "uploads", label: "Uploads" },
      { resource: "categories", label: "Categories" },
      { resource: "currencies", label: "Currencies" },
      { resource: "api_tokens", label: "API Tokens" },
      { resource: "configs", label: "Configs" },
      { resource: "regions", label: "Regions" },
      { resource: "translations", label: "Languages" },
      { resource: "settings", label: "Account" },
    ],
    history: [
      { resource: "suppliers", label: "Integrations" },
      { resource: "web_hook_logs", label: "Webhook Log" },
      { resource: "api_requests", label: "Api Requests" },
      { resource: "versions", label: "Actions" },
    ],
  };
  const findActiveMenu = (locationPath) => {
    for (const menu in menuItems) {
      if (menuItems[menu].some((item) => item.resource === locationPath)) {
        return menu;
      }
    }
    return "";
  };

  const [activeMenu, setActiveMenu] = React.useState([
    findActiveMenu(locationPath),
  ]);

  const getSpecificResourceIcon = (resourceName) => {
    if (resourceName === "settings") return <SettingsIcon />;

    const resource = resources.find(
      (resource) => resource.name === resourceName,
    );
    if (!resource) return;
    return resource.icon ? <resource.icon /> : <ListIcon />;
  };

  const SideBarItem = ({ label, resource }) => {
    if (!resource) return;
    return (
      <>
        <Tooltip title={label} placement="top-start">
          <MenuItemLink
            to={"/" + resource}
            primaryText={label}
            leftIcon={getSpecificResourceIcon(resource)}
            sx={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              marginLeft: collapsed ? "3px" : "10px",
            }}
            onClick={() => setActiveMenu([findActiveMenu(resource)])}
          />
        </Tooltip>
      </>
    );
  };

  const handleToggle = (resource) => {
    if (activeMenu.includes(resource)) {
      setActiveMenu([
        ...activeMenu.filter((item) => item !== resource),
        findActiveMenu(locationPath),
      ]);
      return;
    }
    setActiveMenu((old) => {
      if (
        old.includes(resource) &&
        old.includes(findActiveMenu(locationPath))
      ) {
        return [...old, resource];
      } else return [resource];
    });
  };

  const preventToggle = (resource) => {
    return findActiveMenu(locationPath) === resource;
  };

  return (
    <Sidebar>
      <Box
        sx={{
          display: "flex",
          paddingTop: "10px",
          alignItems: "center",
          top: "10px",
          marginLeft: collapsed ? "15px" : "26px",
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            background: "linear-gradient(180deg, #E412B8 -9%, #151C50 110.26%)",
            boxShadow:
              "0px -2px 4px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 0px 1px rgba(0, 0, 0, 0.10) inset",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "30px",
            width: "30px",
            marginRight: "10px",
          }}
        >
          <Box component="img" alt="playhera logo" src={playheraIcon}></Box>
        </Box>
        {!collapsed && (
          <Box
            component="img"
            alt="playhera text"
            sx={{ height: "13px" }}
            src={playheraText}
          ></Box>
        )}
      </Box>

      <Menu
        hasDashboard={!!dashboard}
        sx={{
          display: "flex",
          backgroundColor: "transparent",
          gap: "5px",
          paddingTop: "20px",
          flexDirection: "column",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        <MenuItemLink
          to="/"
          primaryText={translate("dashboard", { _: "Dashboard" })}
          leftIcon={<HomeIcon />}
          sx={{
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: collapsed ? "3px" : "10px",
          }}
        />

        {/* ----------------------------Orders------------------------- */}
        <MenuItem
          sx={{
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: collapsed ? "-5px" : "0px",
            flexDirection: !collapsed ? "row" : "column",
            width: collapsed ? "70px" : "308px",
          }}
          onClick={() => handleToggle("orders")}
        >
          <ListItemText
            primary="Orders"
            sx={{
              "& .MuiListItemText-primary": {
                fontSize: collapsed ? "12px" : "14px",
              },
            }}
          />
          {activeMenu.includes("orders") || preventToggle("orders") ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </MenuItem>

        <Collapse
          in={activeMenu.includes("orders") || preventToggle("orders")}
          timeout="auto"
          unmountOnExit
        >
          {menuItems.orders.map((item) => (
            <SideBarItem
              key={item.resource}
              label={item.label}
              resource={item.resource}
            />
          ))}
        </Collapse>

        {/*------------------------------Products----------------------- */}
        <MenuItem
          sx={{
            marginLeft: collapsed ? "-5px" : "0px",
            flexDirection: !collapsed ? "row" : "column",
            width: collapsed ? "70px" : "308px",
          }}
          onClick={() => handleToggle("products")}
        >
          <ListItemText
            sx={{
              "& .MuiListItemText-primary": {
                fontSize: collapsed ? "12px" : "14px",
              },
            }}
            primary="Products"
          />
          {activeMenu.includes("products") || preventToggle("products") ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </MenuItem>

        <Collapse
          in={activeMenu.includes("products") || preventToggle("products")}
          timeout="auto"
          unmountOnExit
        >
          {menuItems.products.map((item) => (
            <SideBarItem
              key={item.resource}
              label={item.label}
              resource={item.resource}
            />
          ))}
        </Collapse>

        <Divider
          sx={{ borderStyle: "dashed", width: "95%", marginLeft: "10px" }}
        />
        {menuItems.middle.map((item) => (
          <SideBarItem
            key={item.resource}
            label={item.label}
            resource={item.resource}
          />
        ))}

        <Divider
          sx={{ borderStyle: "dashed", width: "95%", marginLeft: "10px" }}
        />

        {/* --------------------------Settings----------------------------- */}
        <MenuItem
          sx={{
            marginLeft: collapsed ? "-5px" : "0px",
            flexDirection: !collapsed ? "row" : "column",
            width: collapsed ? "70px" : "308px",
          }}
          onClick={() => handleToggle("settings")}
        >
          <ListItemText
            sx={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              "& .MuiListItemText-primary": {
                fontSize: collapsed ? "12px" : "14px",
              },
            }}
            primary="Settings"
          />
          {activeMenu.includes("settings") || preventToggle("settings") ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </MenuItem>
        <Collapse
          in={activeMenu.includes("settings") || preventToggle("settings")}
          timeout="auto"
          unmountOnExit
        >
          {menuItems.settings.map((item) => (
            <SideBarItem
              key={item.resource}
              label={item.label}
              resource={item.resource}
            />
          ))}
        </Collapse>

        {/*-----------------------------History---------------------------- */}
        <MenuItem
          sx={{
            marginLeft: collapsed ? "-5px" : "0px",
            flexDirection: !collapsed ? "row" : "column",
            width: collapsed ? "70px" : "308px",
          }}
          onClick={() => handleToggle("history")}
        >
          <ListItemText
            sx={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              "& .MuiListItemText-primary": {
                fontSize: collapsed ? "12px" : "14px",
              },
            }}
            primary="History"
          />
          {activeMenu.includes("history") || preventToggle("history") ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </MenuItem>
        <Collapse
          in={activeMenu.includes("history") || preventToggle("history")}
          timeout="auto"
          unmountOnExit
        >
          {menuItems.history.map((item) => (
            <SideBarItem
              key={item.resource}
              label={item.label}
              resource={item.resource}
            />
          ))}
        </Collapse>
      </Menu>
      <UserSettings collapsed={collapsed} />
    </Sidebar>
  );
};

SuperAdminSidebar.propTypes = {
  dashboard: PropTypes.element,
  label: PropTypes.string,
  resource: PropTypes.string,
  collapsed: PropTypes.bool,
};

export default SuperAdminSidebar;
