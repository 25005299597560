import React, { useState } from "react";
import { useDataProvider, useRecordContext } from "react-admin";
import Button from "@mui/material/Button";
import { Box } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import complaintIcon from "../../../assets/icons/complaintIcon.svg";
import { style } from "./style";
const ComplaintButton = () => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const order = useRecordContext();
  const role = localStorage.getItem("role");
  if (role !== "Accounts::MerchantAdmin") {
    return;
  }
  const complain = () => {
    const { Intercom } = window;

    if (Intercom === undefined) return;

    if (order.intercom_conversation_id) {
      Intercom("showConversation", order.intercom_conversation_id);
      return;
    }

    setLoading(true);
    dataProvider
      .raiseOrderComplaint(order.id)
      .then(({ intercom_conversation_id }) => {
        if (intercom_conversation_id) {
          Intercom("showConversation", intercom_conversation_id);
        }
        setLoading(false);
      });
  };

  return (
    <Tooltip title="Complain">
      <Button sx={style.actionButton} disabled={loading} onClick={complain}>
        <Box component="img" alt="icon" src={complaintIcon}></Box>
      </Button>
    </Tooltip>
  );
};

export default ComplaintButton;
