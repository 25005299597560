export const style = {
  collapsedButton: {
    position: "absolute",
    cursor: "pointer",
    zIndex: 1,
    top: 0,
    left: 0,
    backgroundColor: "white",
    borderRadius: "0px  10px  10px  0px",
    width: "30px",
    height: "30px",
    border: "1px solid var(#ECEFF3, rgba(18, 55, 105, 0.08))",
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.30)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    height: 40,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 30,
    top: 30,
  },
};
