import * as React from "react";
import {
  DateField,
  Show,
  ReferenceField,
  TextField,
  TabbedShowLayout,
  useDataProvider,
  Button,
  useRecordContext,
  useRefresh,
  ArrayField,
  Datagrid,
  NumberField,
} from "react-admin";
import MergeForm from "./MergeForm";
import VersionList from "../../components/Version/List";
import Prices from "../../components/Prices/List";
import SafeJsonField from "../../components/SafeJsonField";
import EditableField from "../../components/Editable/Field";
import OpenMergeFormButton from "./OpenMergeFormButton";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
import BoolColored from "../../components/common/BoolColored";
import { style } from "./style";
const SyncButton = () => {
  const { id } = useRecordContext();
  const dataProvider = useDataProvider();
  const [syncInProgress, setSyncInProgress] = React.useState(false);
  const refresh = useRefresh();
  const performSync = () => {
    setSyncInProgress(true);
    dataProvider
      .sync("integration_products", { id })
      .then(() => {
        setSyncInProgress(false);
        refresh();
      })
      .catch(() => setSyncInProgress(false));
  };

  return (
    <Button label="Sync" onClick={performSync} disabled={syncInProgress} />
  );
};
const OffersTabContent = () => {
  const record = useRecordContext();
  if (!record) return null;
  if (!Array.isArray(record.offers)) return null;

  return (
    <ArrayField source="offers">
      <Datagrid bulkActionButtons={false}>
        <NumberField source="price" />
        <TextField source="offer_id" />
        <NumberField source="available_qty" />
      </Datagrid>
    </ArrayField>
  );
};
const IntegrationProductShow = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpenForm = () => {
    setOpen(true);
  };

  const handleCloseForm = () => {
    setOpen(false);
  };
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <SyncButton />
          <TextField source="id" />
          <EditableField
            truncate={style.truncate}
            source={"ean"}
            maxChar={128}
          />
          <TextField source="name_by_supplier" emptyText="-" />
          <BoolColored source="active" />
          <EditableBooleanField source="available" />
          <EditableBooleanField source="vat" />

          <TextField source="vat_type" />
          <NumberField source="price" />
          <NumberField source="price_without_vat" />
          <NumberField source="vat_amount" />
          <TextField source="countries" emptyText="-" />
          <ReferenceField source="integration_id" reference="integrations">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="product_id"
            reference="products"
            emptyText="-"
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <TextField source="deleted_at" showTime emptyText="-" />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Offers">
          <OffersTabContent />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Supplier Info">
          <SafeJsonField
            source="extras"
            reactJsonOptions={{ collapsed: false }}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="prices">
          <Prices priceable_type="IntegrationProduct" />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="history">
          <VersionList item_type="IntegrationProduct" />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="merge">
          <OpenMergeFormButton onClick={handleOpenForm} />
          <MergeForm open={open} onClose={handleCloseForm} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default IntegrationProductShow;
