import React from "react";
import { useRecordContext } from "react-admin";
import PropTypes from "prop-types";

const FieldChanges = ({ value }) => {
  if (
    typeof value === "string" ||
    value instanceof String ||
    typeof value === "number"
  )
    return value;
  if (typeof value === "object") {
    return JSON.stringify(value, null, 2);
  }
  if (typeof value === "boolean") {
    return value ? "true" : "false";
  }
  return value;
};

FieldChanges.propTypes = {
  value: PropTypes.any,
};

const ObjectChanges = () => {
  const { object_changes } = useRecordContext();
  const keys = Object.keys(object_changes);
  return (
    <table width="100%" border={1}>
      <thead>
        <tr>
          <th width="30%">Field</th>
          <th>Old</th>
          <th>New</th>
        </tr>
      </thead>
      <tbody>
        {keys.map((key, index) => {
          return (
            <tr key={index}>
              <td>{key}</td>
              <td>
                <FieldChanges value={object_changes[key][0]} />
              </td>
              <td>
                <FieldChanges value={object_changes[key][1]} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ObjectChanges;
