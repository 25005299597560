import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { TitlePortal } from "react-admin";
import Notification from "../common/Notification";
import MerchantAdminSidebar from "./MerchantAdminSidebar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SuperAdminSidebar from "./SuperAdminSidebar";
import BackButton from "../common/BackButton";
import { style } from "./style";
const AdminLayout = ({ children, dashboard }) => {
  const role = localStorage.getItem("role");
  const [collapsed, setCollapsed] = React.useState(false);
  return (
    <Box
      display="flex"
      zIndex={1}
      minHeight="100vh"
      backgroundColor="theme.palette.neutral.main"
    >
      <Box height="100%" sx={{ width: `${collapsed ? "60px" : "350px"}` }}>
        {role !== "Accounts::MerchantAdmin" && (
          <SuperAdminSidebar dashboard={dashboard} collapsed={collapsed} />
        )}
        {role === "Accounts::MerchantAdmin" && (
          <MerchantAdminSidebar dashboard={dashboard} collapsed={collapsed} />
        )}
      </Box>

      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={style.collapsedButton}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Box sx={{ position: "absolute", top: 3 }}>
            {!collapsed ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </Box>
        </Box>
        <Box display="flex" minHeight={"100%"}>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={2}
            minHeight={"100%"}
            paddingLeft={5}
            paddingRight={1}
            sx={{
              borderRadius: "16px",
              boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.10)",
              paddingTop: "80px",
            }}
            backgroundColor="white"
          >
            <Box sx={style.title}>
              <TitlePortal
                sx={{
                  span: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "1 rem",
                    maxWidth: "60vw",
                  },
                }}
              />
            </Box>
            <Notification />
            <BackButton />
            <Box
              sx={{
                position: "relative",
                maxWidth: collapsed
                  ? "calc(100vw - 110px)"
                  : "calc(100vw - 350px)",
                padding: "0px",
                margin: "0px",
                minHeight: "96%",
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
  dashboard: PropTypes.func,
};

export default AdminLayout;
