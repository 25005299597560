import React, { useState, useRef } from "react";
import {
  useNotify,
  ReferenceInput,
  SelectInput,
  EditBase,
  Form,
  useRecordContext,
  useDataProvider,
  useRefresh,
  ReferenceField,
} from "react-admin";
import PropTypes from "prop-types";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ModeIcon from "@mui/icons-material/Mode";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { TextFieldWithCopy } from "./CopyTextField";
import { style, useStyles } from "./style";

const Wrapper = (props) => <ExtendedField {...props} />;

Wrapper.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  resource: PropTypes.string,
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  sx: PropTypes.object,
};

export const ExtendedField = ({
  source,
  reference,
  resource,
  optionText,
  optionValue,
  sx,
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [isEditing, setIsEditing] = useState(false);
  const rec = useRecordContext();
  const anchorRef = useRef(null);
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(rec[source]);

  const role = localStorage.getItem("role");

  const handleSave = () => {
    dataProvider
      .update(resource, {
        id: rec.id,
        data: { [source]: selectedValue },
      })
      .then(() => {
        refresh();
        notify("Saved");
        setIsEditing(false);
      })
      .catch((error) => error.message && notify(error.message, "error"));
  };
  const handleCancel = (e) => {
    e.stopPropagation();
    setIsEditing(false);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditing(true);
  };

  return (
    <Box style={style.wrap} ref={anchorRef}>
      {isEditing && (
        <Popper
          open={isEditing}
          anchorEl={anchorRef.current}
          placement={"bottom-start"}
          onClick={(e) => e.stopPropagation()}
        >
          <Paper style={style.paper} onClick={(e) => e.stopPropagation()}>
            <EditBase sx={style.onTop} id={rec.id}>
              <Form
                className={classes.form}
                sx={style.form}
                onClick={(e) => e.stopPropagation()}
              >
                <ReferenceInput
                  record={rec}
                  source={source}
                  reference={reference}
                  onClick={(e) => e.stopPropagation()}
                >
                  <SelectInput
                    sx={style.input}
                    optionText={optionText}
                    optionValue={optionValue}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setSelectedValue(e.target.value)}
                  />
                </ReferenceInput>
              </Form>
            </EditBase>
            <IconButton onClick={handleSave} size="small" color="primary">
              <CheckIcon />
            </IconButton>
            <IconButton onClick={handleCancel} size="small" color="secondary">
              <CloseIcon />
            </IconButton>
          </Paper>
        </Popper>
      )}
      {!isEditing && (
        <>
          <Box sx={{ ...sx }}>
            <ReferenceField source={source} reference={reference}>
              <TextFieldWithCopy source={optionText} sx={{ ...sx }} />
            </ReferenceField>
          </Box>
          {role === "Accounts::SuperAdmin" && (
            <IconButton onClick={handleEditClick} size="small">
              <ModeIcon fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

ExtendedField.propTypes = {
  ...Wrapper.propTypes,
};

export default Wrapper;
