import * as React from "react";
import {
  ReferenceField,
  TextField,
  Show,
  TabbedShowLayout,
  usePermissions,
  ReferenceOneField,
  DateField,
  NumberField,
  ImageField,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import VersionList from "../../components/Version/List";

import EditableField from "../../components/Editable/Field";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
import { style } from "./style";
import { Box } from "@material-ui/core";
import { VatTypeField } from "../../components";
const MerchantProductShow = () => {
  const { permissions } = usePermissions();
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          {can("products", "create", permissions) && (
            <ReferenceField
              source="product_id"
              reference="products"
              link="show"
            >
              <Box sx={style.show.nameField}>
                <TextField source="name" />
              </Box>
            </ReferenceField>
          )}
          {can("merchants", "list", permissions) && (
            <ReferenceField
              source="merchant_id"
              reference="merchants"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
          )}
          <EditableBooleanField source="vat" />
          <VatTypeField label="Vat Type" />
          <EditableField source="nominal" label="Nominal" />

          <TextField source="name" />
          <TextField source="sku" />

          {can("prices", "list", permissions) && (
            <EditableField
              source="price"
              label="Price"
              sortable={false}
              type={"number"}
            />
          )}
          {can("prices", "list", permissions) && (
            <EditableField
              source="price_without_vat"
              label="Price without VAT"
              sortable={false}
              type={"number"}
            />
          )}

          <NumberField
            source="vat_amount"
            label="Vat amount"
            sortable={false}
            type={"number"}
          />

          <EditableField source="recommended_retail_price" type="number" />
          <ReferenceOneField
            label="Region"
            source="product_id"
            reference="regions"
            target="region_id"
            link="show"
          />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="images">
          <ReferenceField source="product_id" reference="products" link="show">
            <ImageField source="image.url" sortable={false} />
          </ReferenceField>
        </TabbedShowLayout.Tab>
        {can("versions", "list", permissions) && (
          <TabbedShowLayout.Tab label="history">
            <VersionList item_type="MerchantProduct" />
          </TabbedShowLayout.Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
export default MerchantProductShow;
