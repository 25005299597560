import React from "react";
import { Datagrid, DateField, List, TextField, SelectInput } from "react-admin";
const NotificationFilter = [
  <SelectInput
    source="seen"
    key="seenSelect"
    emptyText={"all"}
    choices={[
      { id: true, name: "read" },
      { id: false, name: "unread" },
    ]}
    alwaysOn
  />,
];

const NotificationList = () => (
  <List exporter={false} filters={NotificationFilter}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="title" />
      <TextField source="body" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
export default NotificationList;
