import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const ButtonSmall = ({ icon, action }) => {
  return (
    <Box
      sx={{
        height: 32,
        cursor: "pointer",
        width: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        padding: "6px",
        boxShadow:
          "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
      }}
    >
      <Box component="img" alt="icon" src={icon} onClick={action}></Box>
    </Box>
  );
};

ButtonSmall.propTypes = {
  icon: PropTypes.node,
  action: PropTypes.func,
};

export default ButtonSmall;
