import * as React from "react";
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  minValue,
} from "react-admin";

const PriceEdit = () => (
  <Edit redirect="show">
    <SimpleForm>
      <NumberInput
        source="price"
        validate={[required(), minValue(0.0)]}
        label="Price(VAT)"
      />
      <NumberInput
        source="price_without_vat"
        validate={[required(), minValue(0.0)]}
        label="Price(no VAT)"
      />
      <TextInput source="priceable_type" disabled style={{ display: "none" }} />
      <NumberInput source="priceable_id" disabled style={{ display: "none" }} />
      <ReferenceInput source="currency_id" reference="currencies">
        <SelectInput optionText="name" validate={[required()]} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
export default PriceEdit;
