import React from "react";
import {
  DateField,
  Show,
  NumberField,
  SelectField,
  SimpleShowLayout,
} from "react-admin";

import uploadTypes from "./uploadTypes";
import SafeJsonField from "../../components/SafeJsonField";
const UploadShow = () => (
  <Show>
    <SimpleShowLayout>
      <NumberField source="id" />
      <SelectField source="type" choices={uploadTypes} />
      <SafeJsonField source="data" root={false} />
      <DateField source="appliance_time" showTime />
      <DateField source="created_at" showTime />
    </SimpleShowLayout>
  </Show>
);
export default UploadShow;
