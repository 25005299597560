import * as React from "react";
import {
  ReferenceField,
  Show,
  TabbedShowLayout,
  TextField,
  FunctionField,
  ReferenceArrayField,
  SelectField,
  minValue,
  required,
  NumberField,
} from "react-admin";
import VersionList from "../../components/Version/List";
import EditableField from "../../components/Editable/Field";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
const MerchantShow = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="summary">
        <TextField source="name" />
        <EditableField source="credit" type={"number"} />

        <FunctionField
          label="Balance"
          render={(record) => record?.current_balance}
        />
        <EditableField source="webhook_url" />
        <EditableField source="webhook_secret" />
        <EditableBooleanField source="webhook_enabled" />
        <EditableBooleanField source="webhook_on_product_change" />
        <EditableBooleanField source="webhook_on_order_change" />
        <EditableBooleanField source="webhook_active?" />
        <EditableBooleanField source="vatable" adminOnly={true} />
        <EditableBooleanField source="send_daily_reports" />
        <ReferenceField source="region_id" reference="regions">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField
          sx={{ display: "flex" }}
          source="account_ids"
          reference="accounts"
        />
        <TextField source="timezone" />

        <SelectField
          source="merchant_type"
          choices={[
            { id: "Internal", name: "Internal" },
            { id: "External", name: "External" },
          ]}
        />
        <NumberField
          source="vat_rate"
          type={"number"}
          validate={[minValue(0), required()]}
        />
        <ReferenceField reference="currencies" source="currency_id">
          <TextField source="name" />
        </ReferenceField>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="history">
        <VersionList item_type="Merchant" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
export default MerchantShow;
