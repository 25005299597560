import React from "react";
import {
  FormDataConsumer,
  SelectInput,
  NumberInput,
  minValue,
  required,
} from "react-admin";
import priceTypes from "./priceTypes";
const FixedPriceInputs = () => (
  <>
    <NumberInput
      fullWidth
      source="price"
      label="Price"
      validate={[minValue(0)]}
    />
    <NumberInput
      fullWidth
      source="price_without_vat"
      label="Price without VAT"
    />
  </>
);
const FixedProfitInputs = () => (
  <NumberInput
    fullWidth
    source="price_params.profit"
    label="Profit"
    validate={[minValue(0), required()]}
    step={0.01}
  />
);
const PercentProfitInputs = () => (
  <NumberInput
    fullWidth
    source="price_params.profit"
    label="Profit"
    validate={[minValue(0), required()]}
    step={0.01}
  />
);
const customInputs = {
  fixed_price: FixedPriceInputs,
  fixed_profit: FixedProfitInputs,
  percent_profit: PercentProfitInputs,
};

const PriceInputs = () => (
  <>
    <SelectInput source="price_type" choices={priceTypes} />
    <FormDataConsumer>
      {({ formData }) => {
        const CustomForm = customInputs[formData.price_type];
        return CustomForm ? (
          <>
            <CustomForm />
          </>
        ) : null;
      }}
    </FormDataConsumer>
  </>
);
export default PriceInputs;
