import React from "react";
import {
  Datagrid,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceManyField,
} from "react-admin";

export const OIPShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceManyField
        source="integration_product_id"
        reference="integration_products"
        target="id"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="ean" />
          <ReferenceField source="product_id" reference="products" />
          <ReferenceField source="integration_id" reference="integrations" />
        </Datagrid>
      </ReferenceManyField>

      <TextField source="status" />
      <TextField source="cost" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ReferenceField
        source="order_merchant_product_id"
        reference="order_merchant_products"
        label="Order ID"
      >
        <ReferenceField source="order_id" reference="orders" link={"show"} />
      </ReferenceField>
      <TextField source="result.code" />
    </SimpleShowLayout>
  </Show>
);

export default OIPShow;
