import * as React from "react";
import {
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import { useQuery } from "react-query";
import TranslationList from "./List";
import InDrawer from "../../hocs/InDrawer";

const KeyInput = () => {
  const { translatableType } = useRecordContext() || {};

  if (!translatableType) {
    return <TextInput fullWidth source="key" validate={[required()]} />;
  }

  const dataProvider = useDataProvider();
  const query = ["translations/translatable_keys", { translatableType }];
  const { data, isLoading } = useQuery(query, () => dataProvider.get(...query));

  return (
    <SelectInput
      fullWidth
      source="key"
      choices={data}
      isLoading={isLoading}
      validate={[required()]}
    />
  );
};

const TranslationCreate = () => {
  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <KeyInput />
        <ReferenceInput label="Language" source="locale_id" reference="locales">
          <SelectInput
            label="Language"
            fullWidth
            optionText="name"
            validate={[required()]}
          />
        </ReferenceInput>
        <TextInput fullWidth source="value" multiline validate={[required()]} />
        <TextInput
          fullWidth
          source="translatableType"
          disabled
          style={{ display: "none" }}
        />
        <NumberInput
          fullWidth
          source="translatableId"
          disabled
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Create>
  );
};

export default InDrawer(
  <TranslationList />,
  <TranslationCreate />,
  "Create Translation",
  "Create new Translation",
  "/translations",
);
