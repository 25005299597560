import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  UrlField,
  NumberField,
  DateField,
  FunctionField,
} from "react-admin";
import { PolymorphicLink } from "../../components";
import SafeJsonField from "../../components/SafeJsonField";

export const WHLShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="merchant_id" reference="merchants" />
      <FunctionField
        label="Hookable"
        render={(record) => (
          <PolymorphicLink
            id={record.hookable_id}
            type={record.hookable_type}
          />
        )}
      />

      <UrlField source="url" />
      <NumberField source="status" />
      <SafeJsonField source="request_body" />
      <SafeJsonField source="request_headers" />
      <SafeJsonField source="response_headers" />
      <SafeJsonField source="response_body" />
      <TextField source="error_message" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </SimpleShowLayout>
  </Show>
);

export default WHLShow;
