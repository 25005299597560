import React, { useState, useEffect } from "react";
import {
  AppBar as DefaultAppBar,
  TitlePortal,
  useDataProvider,
  useRedirect,
} from "react-admin";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Button } from "@mui/material";

const NotificationsButton = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [notificationsQty, setNotificationsQty] = useState();

  const getNotificationsQty = () => {
    dataProvider
      .getList("notifications", {
        pagination: { perPage: 100, page: 1 },
        sort: { field: "id", order: "ASC" },
        filter: { seen: false },
      })
      .then(({ total }) => {
        setNotificationsQty(total);
      });
  };

  useEffect(() => {
    getNotificationsQty();
    const interval = setInterval(getNotificationsQty, 3000000);

    return () => clearInterval(interval);
  }, [dataProvider]); // Add dependencies here if needed

  return (
    <Button color="inherit" onClick={() => redirect("/notifications")}>
      <Badge badgeContent={notificationsQty} color="primary">
        <NotificationsIcon />
      </Badge>
    </Button>
  );
};

const AppBar = () => (
  <DefaultAppBar>
    <TitlePortal />
    <NotificationsButton />
  </DefaultAppBar>
);
export default AppBar;
