import * as React from "react";
import {
  Edit,
  ReferenceInput,
  SimpleForm,
  AutocompleteInput,
  required,
  NumberInput,
  BooleanInput,
  ReferenceField,
} from "react-admin";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";
import MerchantProductShow from "./Show";
import InDrawer from "../../hocs/InDrawer";
import { VatTypeInput } from "../../components";
import PriceInputs from "./PriceInputs";

const MerchantProductEdit = () => (
  <Edit
    title={" "}
    actions={<></>}
    mutationMode="pessimistic"
    undoable={false}
    redirect="show"
  >
    <SimpleForm
      toolbar={<CustomEditToolbar recordName={"merchant_products"} />}
    >
      <ReferenceField source="merchant_id" reference="merchants" />
      <BooleanInput source="vat" />
      <PriceInputs />
      <VatTypeInput validate={[required()]} />
      <ReferenceInput source="product_id" reference="products">
        <AutocompleteInput
          fullWidth
          optionValue="id"
          validate={[required()]}
          optionText="sku"
          filterToQuery={(sku) => ({ sku })}
        />
      </ReferenceInput>
      <NumberInput fullWidth source="recommended_retail_price" />
    </SimpleForm>
  </Edit>
);
export default InDrawer(
  <MerchantProductShow />,
  <MerchantProductEdit />,
  "Edit Merchant Product",
  "Edit Merchant Product details",
);
