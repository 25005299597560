import * as React from "react";
import {
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  minValue,
} from "react-admin";

const PriceCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <NumberInput source="price" validate={[required(), minValue(0.0)]} />
      <TextInput source="priceable_type" disabled style={{ display: "none" }} />
      <NumberInput source="priceable_id" disabled style={{ display: "none" }} />
      <ReferenceInput source="currency_id" reference="currencies">
        <SelectInput optionText="name" validate={[required()]} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
export default PriceCreate;
