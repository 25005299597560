import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const OpenMergeFormButton = ({ onClick }) => {
  return <Button onClick={onClick}>Merge Products</Button>;
};
OpenMergeFormButton.propTypes = {
  onClick: PropTypes.func,
};
export default OpenMergeFormButton;
