import * as React from "react";
import { Datagrid, DateField, List, ShowButton, TextField } from "react-admin";
import EditableField from "../../components/Editable/Field";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
const CurrencyList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <EditableField source="iso_code" justify={{ justifyContent: "center" }} />
      <EditableField source="name" justify={{ justifyContent: "center" }} />
      <EditableBooleanField source="default" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <ShowButton />
    </Datagrid>
  </List>
);
export default CurrencyList;
