import React from "react";

import { useRecordContext, useDataProvider, FunctionField } from "react-admin";
const CurrentPrice = () => {
  const pendingPrice = useRecordContext();
  const dataProvider = useDataProvider();
  const [currency, setCurrency] = React.useState(null);
  const [integrationProduct, setIntegrationProduct] = React.useState(null);
  React.useEffect(() => {
    if (pendingPrice) {
      dataProvider
        .getOne("currencies", { id: pendingPrice.currency_id })
        .then(({ data }) => {
          setCurrency(data);
        });
      dataProvider
        .getOne("integration_products", {
          id: pendingPrice.integration_product_id,
        })
        .then(({ data }) => {
          setIntegrationProduct(data);
        });
    }
  }, [pendingPrice]);
  return (
    <>
      <FunctionField
        render={() => {
          if (integrationProduct && currency) {
            const price = integrationProduct.prices[currency.iso_code]?.price;
            return price ? `${parseFloat(price)}(VAT)` : null;
          }
        }}
      />
      <br />
      <FunctionField
        render={() => {
          if (integrationProduct && currency) {
            const price =
              integrationProduct.prices[currency.iso_code]?.price_without_vat;
            return price ? `${parseFloat(price)}(noVat)` : null;
          }
        }}
      />
    </>
  );
};

export default CurrentPrice;
