import * as React from "react";
import { useListContext } from "react-admin";
import PropTypes from "prop-types";

const ListFilterValues = ({ setActive }) => {
  const { filterValues } = useListContext();
  React.useEffect(() => {
    setActive(filterValues?.active);
  }, [filterValues]);
  return <></>;
};

ListFilterValues.propTypes = {
  setActive: PropTypes.func.isRequired,
};

export default ListFilterValues;
