import React from "react";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router";
import { Box } from "@material-ui/core";
import ListIcon from "@mui/icons-material/List";

const BackButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const backLocation = location.pathname.split("/");
  const isShowPage = location.pathname.includes("show");
  const isNotification = location.pathname.includes("notifications");
  const isPrice = location.pathname.includes("prices");
  const handleClick = () => {
    if (backLocation[1] === "locales") {
      navigate("/translations");
    } else {
      navigate("/" + backLocation[1]);
    }
  };

  return (
    <>
      {isShowPage && !isNotification && !isPrice && (
        <Box
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: 80,
            top: 30,
            borderRadius: "10px",
            boxShadow:
              "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
          }}
        >
          <Box
            sx={{
              width: "60px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleClick}
          >
            <ArrowBack />
            <ListIcon />
          </Box>
        </Box>
      )}
    </>
  );
};

export default BackButton;
