import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  ChipField,
  DateField,
  FunctionField,
  UpdateButton,
  SelectInput,
  ReferenceInput,
  useListContext,
  useUnselect,
  TopToolbar,
  FilterButton,
} from "react-admin";
import PropTypes from "prop-types";
import BulkUpdateButton from "./UpdateButton";
import { Box } from "@material-ui/core";
import ExportButton from "../../components/Export/ExportButton";
import CurrentPrice from "./CurrentPrice";
import NewPrice from "./NewPrice";

const Filters = [
  <SelectInput
    source="status"
    choices={[
      { id: "pending", name: "Pending" },
      { id: "accepted", name: "Accepted" },
      { id: "rejected", name: "Rejected" },
    ]}
    key="status"
    resettable
    alwaysOn
    emptyValue={"pending"}
  />,
  <ReferenceInput
    source="currency_id"
    reference="currencies"
    label="Currency"
    key="currency_id"
  />,
  <ReferenceInput
    source="integration_id"
    reference="integrations"
    label="Integration"
    key="integration_id"
  />,
  <ReferenceInput
    source="account_id"
    reference="accounts"
    label="Account"
    key="account_id"
    filter={{ type: "Accounts::SuperAdmin" }}
  />,
];
const BulkActionButtons = (props) => (
  <>
    <BulkUpdateButton
      data={{ action: "accept" }}
      label="Accept"
      mutationMode="pessimistic"
      {...props}
    />
    <BulkUpdateButton
      data={{ action: "reject" }}
      label="Reject"
      mutationMode="pessimistic"
      {...props}
    />
  </>
);

const FilterValueObject = ({ setFilStatus }) => {
  const { filterValues } = useListContext();
  React.useEffect(() => {
    filterValues?.status && setFilStatus(filterValues?.status);
  }, [filterValues]);

  return <></>;
};

FilterValueObject.propTypes = {
  setFilStatus: PropTypes.func,
};
const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};

export const PendingPrice = () => {
  const [filStatus, setFilStatus] = React.useState("pending");
  const [disabled, setDisabled] = React.useState(false);
  const unselect = useUnselect("pending_prices");
  const onSuccess = ({ id }) => unselect(id);

  return (
    <List
      exporter={false}
      filters={Filters}
      perPage={50}
      actions={<ListActions />}
      filterDefaultValues={{ status: "pending" }}
      aside={<FilterValueObject setFilStatus={setFilStatus} />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={
          filStatus === "pending" ? (
            <BulkActionButtons disabled={disabled} setDisabled={setDisabled} />
          ) : (
            false
          )
        }
      >
        <NumberField source="id" />
        <ReferenceField
          source="integration_product_id"
          reference="integration_products"
          sortable={false}
        >
          <TextField source="name_by_supplier" />
        </ReferenceField>
        <ReferenceField
          source="integration_product_id"
          reference="integration_products"
          label="Product"
          sortable={false}
          link={false}
        >
          <ReferenceField source="product_id" reference="products">
            <TextField source="sku" />
          </ReferenceField>
        </ReferenceField>

        <ReferenceField
          source="integration_product_id"
          reference="integration_products"
          label="Provider"
          sortable={false}
          link={false}
        >
          <ReferenceField source="integration_id" reference="integrations" />
        </ReferenceField>
        <ReferenceField
          source="integration_product_id"
          reference="integration_products"
          label="EAN"
          sortable={false}
        >
          <TextField source="ean" />
        </ReferenceField>
        <ReferenceField source="currency_id" reference="currencies" />
        <NewPrice label="New Price(With vat / Without Vat)" />
        <NumberField source="vat" label="VAT" />
        <FunctionField
          source="account_id"
          label="Account"
          render={(record) => {
            return record.account_id ? (
              <ReferenceField source="account_id" reference="accounts" />
            ) : (
              <span>System</span>
            );
          }}
        />
        <CurrentPrice label="Current price(With vat / Without Vat)" />

        <ChipField source="status" />
        <DateField source="created_at" showTime />
        <Box sx={{ display: "flex", gap: "10px" }}>
          <FunctionField
            render={(record) =>
              record.status == "pending" && (
                <UpdateButton
                  label="Accept"
                  data={{ action: "accept" }}
                  mutationMode="pessimistic"
                  disabled={disabled}
                  mutationOptions={{ onSuccess }}
                />
              )
            }
          />
          <FunctionField
            render={(record) =>
              record.status == "pending" && (
                <UpdateButton
                  label="Reject"
                  data={{ action: "reject" }}
                  mutationMode="pessimistic"
                  disabled={disabled}
                  mutationOptions={{ onSuccess }}
                />
              )
            }
          />
        </Box>
      </Datagrid>
    </List>
  );
};

export default PendingPrice;
