import React from "react";
import {
  Button,
  useDataProvider,
  useUnselectAll,
  useRefresh,
} from "react-admin";
import PropTypes from "prop-types";

const BulkUpdateButton = (props) => {
  const { data, selectedIds, label, disabled, setDisabled } = props;

  const dataprovider = useDataProvider();
  const unselect = useUnselectAll("pending_prices");
  const reload = useRefresh();
  const handleClick = () => {
    setDisabled(true);
    return dataprovider
      .bulkUpdate("pending_prices", {
        ...data,
        ids: selectedIds,
      })
      .then(unselect)
      .then(reload)
      .then(() => {
        setDisabled(false);
      });
  };

  return <Button label={label} onClick={handleClick} disabled={disabled} />;
};

BulkUpdateButton.propTypes = {
  data: PropTypes.object,
  selectedIds: PropTypes.array,
  label: PropTypes.string,
  setDisabled: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BulkUpdateButton;
