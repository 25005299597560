import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  NumberField,
  BooleanInput,
  ShowButton,
} from "react-admin";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import ListFilterValues from "../../components/common/ListFilterValues";
import EditableField from "../../components/Editable/Field";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
import { Box } from "@material-ui/core";

const IntegrationFilter = [
  <BooleanInput source="active" key="active" defaultValue={true} />,
];

const IntegrationList = () => {
  const [active, setActive] = React.useState(true);
  return (
    <List
      filters={IntegrationFilter}
      exporter={false}
      aside={<ListFilterValues setActive={setActive} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <EditableField source="name" justify={{ justifyContent: "center" }} />
        <ReferenceField
          source="supplier_id"
          reference="suppliers"
          label="Integration"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditableBooleanField source="notifier_on_balance_change" />
        <EditableField
          source="expected_balance"
          type="number"
          justify={{ justifyContent: "center" }}
        />
        <NumberField source="integration_products_count" />
        {!active && <DateField source="deleted_at" />}
        <Box sx={{ display: "flex", gap: "10px" }}>
          <CustomDeleteButton recordName={"integrations"} />
          <ShowButton sx={{ height: "35px" }} />
        </Box>
      </Datagrid>
    </List>
  );
};
export default IntegrationList;
