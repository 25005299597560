import * as React from "react";
import {
  Create,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  ImageInput,
  ImageField,
  required,
  TextInput,
  maxLength,
  AutocompleteInput,
} from "react-admin";
import FileInputPlaceholder from "../../components/common/FileInputPlaceholder";
import CategoryList from "./List";
import { useNotify } from "react-admin";
import InDrawer from "../../hocs/InDrawer";

const CategoryCreate = () => {
  const handleDropRejected = React.useCallback(
    (fileRejections) => {
      const errorMessage = fileRejections
        .flatMap((fr) => fr.errors)
        .map((err) => err.message)
        .join("; ");
      notify(`Invalid file(s): ${errorMessage}`, { type: "error" });
    },
    [notify],
  );

  const notify = useNotify();

  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <TextInput
          fullWidth
          source="name"
          validate={[required(), maxLength(60)]}
        />
        <ReferenceInput source="category_id" reference="categories">
          <AutocompleteInput
            fullWidth
            optionText="name"
            optionValue="id"
            filterToQuery={(searchText) => ({ name: searchText })}
            label="Parental Category"
          />
        </ReferenceInput>
        <ReferenceInput source="region_id" reference="regions">
          <SelectInput
            fullWidth
            optionText="name"
            optionValue="id"
            validate={[required()]}
          />
        </ReferenceInput>
        <ImageInput
          sx={{
            "& .RaFileInput-dropZone": {
              backgroundColor: "transparent",
            },
          }}
          source="new_images"
          multiple
          options={{ onDropRejected: handleDropRejected }}
          maxSize={10000000}
          accept="image/*"
          placeholder={<FileInputPlaceholder subText="up to 10 MB." />}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
export default InDrawer(
  <CategoryList />,
  <CategoryCreate />,
  "Create Category",
  "Create new Category",
  "/categories",
);
