import React from "react";
import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
} from "react-admin";

const NotificationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="body" />
      <BooleanField source="seen" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);
export default NotificationShow;
