import React from "react";
import {
  Show,
  TabbedShowLayout,
  TextField,
  DateField,
  NumberField,
  ReferenceOneField,
  ReferenceField,
  useRecordContext,
  FunctionField,
  ReferenceArrayField,
  Labeled,
} from "react-admin";
import AccountBadge from "../../components/Badges/AccountBadge";
import VersionList from "../../components/Version/List";
import EditableField from "../../components/Editable/Field";
import { TimezoneField } from "../../components/Timezone";
import { Box } from "@material-ui/core";

const SpecificField = () => {
  const record = useRecordContext();
  if (record.type === "Accounts::RegionalAdmin") {
    return (
      <ReferenceOneField
        target="account_id"
        reference="admin_regions"
        label="Region"
      >
        <ReferenceField source="region_id" reference="regions">
          <TextField source="name" />
        </ReferenceField>
      </ReferenceOneField>
    );
  } else if (record.type === "Accounts::MerchantAdmin") {
    return (
      <Box sx={{ display: "flex" }}>
        <Labeled>
          <ReferenceArrayField
            label="Merchants"
            source="merchant_ids"
            reference="merchants"
          />
        </Labeled>
      </Box>
    );
  } else {
    return null;
  }
};
const ShowAccount = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <NumberField source="id" />
          <FunctionField
            source="type"
            render={(account) => <AccountBadge text={account.type} />}
          />
          <EditableField source="email" />
          <SpecificField />
          <TimezoneField source="timezone" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <DateField source="deleted_at" showTime />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="history">
          <VersionList item_type="Account" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ShowAccount;
