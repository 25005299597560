import * as React from "react";
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import ShowLocale from "./Show";
import InDrawer from "../../hocs/InDrawer";

const LocaleEdit = () => {
  return (
    <Edit title=" " actions={<></>} redirect="show">
      <SimpleForm>
        <TextInput fullWidth source="code" validate={[required()]} />
        <TextInput fullWidth source="name" validate={[required()]} />
        <BooleanInput source="default" />
      </SimpleForm>
    </Edit>
  );
};
export default InDrawer(
  <ShowLocale />,
  <LocaleEdit />,
  "Edit Locale",
  "Edit Locale details",
);
