const validateUniqueCurrency = (value) => {
  if (value) {
    const currencies = value.map((item) => item.currency);
    const duplicates = currencies.filter(
      (currency, index) => currencies.indexOf(currency) !== index,
    );
    if (duplicates.length > 0) {
      return "Each currency must be unique";
    }
  }
  return undefined;
};

export { validateUniqueCurrency };
