import * as React from "react";
import {
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
  Confirm,
  useRefresh,
  useNotify,
  useDataProvider,
} from "react-admin";
import { Box } from "@material-ui/core";
import Button from "@mui/material/Button";

const customEditToolbar = ({ recordName }) => {
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const handleUndoDelete = async () => {
    try {
      await dataProvider.delete(recordName, {
        id: record.id,
      });
      refresh();
    } catch (error) {
      notify("Failed to update record", "error");
    }
  };

  const handleReactivate = () => {
    setOpen(true);
  };

  if (record.deleted_at !== null) {
    return (
      <Toolbar>
        <Box display="flex" sx={{ gap: "10px" }}>
          <SaveButton />
          <Button
            sx={{
              width: "100px",
              height: "35px",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
              margin: "0px",
              textTransform: "none",
              lineHeight: "1.5",
              boxShadow:
                " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
            }}
            onClick={handleReactivate}
          >
            Activate
          </Button>
          <Confirm
            isOpen={open}
            title="Confirm Reactivation"
            content="Are you sure you want to reactivate this item?"
            confirm="Reactivate"
            cancel="Cancel"
            onConfirm={handleUndoDelete}
            onClose={() => setOpen(false)}
          />
        </Box>
      </Toolbar>
    );
  } else {
    return (
      <Toolbar>
        <Box display="flex" sx={{ gap: "10px" }}>
          <SaveButton />
          <DeleteButton sx={{ height: "37px" }} mutationMode="pessimistic" />
        </Box>
      </Toolbar>
    );
  }
};

export default customEditToolbar;
