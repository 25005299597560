import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  BooleanInput,
  ShowButton,
} from "react-admin";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import ListFilterValues from "../../components/common/ListFilterValues";
import { Box } from "@material-ui/core";

const ApiTokenFilters = [<BooleanInput source="active" key="active" />];

const ApiTokenList = () => {
  const [active, setActive] = React.useState(false);

  return (
    <List
      filters={ApiTokenFilters}
      filterDefaultValues={{ active: true }}
      exporter={false}
      aside={<ListFilterValues setActive={setActive} />}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ReferenceField
          source="tokenizable_id"
          reference="merchants"
          label="Merchant"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" showTime />
        {!active && <DateField source="deleted_at" showTime />}
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ShowButton sx={{ height: "35px" }} />
          <CustomDeleteButton recordName={"api_tokens"} />
        </Box>
      </Datagrid>
    </List>
  );
};
export default ApiTokenList;
