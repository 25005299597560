import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const UploadsStatus = ({ text }) => {
  const pickColor = () => {
    const colorMap = {
      checking: "#6E330C",
      applied: "#164564",
      failed: "#525866",
    };
    return text ? colorMap[text.toLowerCase()] : "black";
  };

  const pickBackground = () => {
    const backgroundMap = {
      applied: "#C2D6FF",
      checking: "#FFDAC2",
      failed: "#F6F8FA",
    };
    return text ? backgroundMap[text.toLowerCase()] : "#FFFFFF";
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 4.5,
        backgroundColor: pickBackground(),
        padding: "2px 10px 2px 8px",
        width: "fit-content",
        height: "fit-content",
      }}
    >
      <Box sx={{ fontSize: "12px", color: pickColor() }}>{text && text}</Box>
    </Box>
  );
};

UploadsStatus.propTypes = {
  text: PropTypes.string,
};

export default UploadsStatus;
