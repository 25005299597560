import React from "react";
import { Box, Typography } from "@mui/material";

export const dashboardTitle = (title, subtitle) => {
  const dashboardTitle = (
    <Box style={{ marginLeft: "20px" }}>
      <Typography style={{ fontSize: "20px" }}>{title}</Typography>
      <Typography
        style={{ color: "#818898", fontSize: "16px", fontWeight: "500" }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
  return dashboardTitle;
};
