import React, { useState, useCallback } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
  ImageField,
  required,
  maxLength,
  useEditController,
  useNotify,
} from "react-admin";

import FileInputPlaceholder from "../../components/common/FileInputPlaceholder";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";
import ProductShow from "./Show";
import InDrawer from "../../hocs/InDrawer";
import ImagesList from "../../components/ImageManipulation/ImagesList";
const ImageInputWithPreview = (props) => {
  const { record } = useEditController(props);
  const [inputHidden, setInputHidden] = useState(false);

  const handleDropRejected = useCallback(
    (fileRejections) => {
      const errorMessage = fileRejections
        .flatMap((fr) => fr.errors)
        .map((err) => err.message)
        .join("; ");
      notify(`Invalid file(s): ${errorMessage}`, { type: "error" });
    },
    [notify],
  );
  const notify = useNotify();
  const handleDropAccept = () => setInputHidden(true);

  if (record !== undefined && record?.images !== undefined && record.images[0])
    return <ImagesList />;

  return (
    <ImageInput
      sx={{
        "& .RaFileInput-dropZone": {
          backgroundColor: "transparent",
        },
      }}
      source="new_images"
      options={{
        onDropRejected: handleDropRejected,
        onDropAccepted: handleDropAccept,
      }}
      maxSize={10000000}
      multiple
      accept="image/*"
      placeholder={
        !inputHidden ? <FileInputPlaceholder subText="up to 10 MB." /> : <></>
      }
      validateFileRemoval={() => setInputHidden(false)}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  );
};

const ProductEdit = (props) => (
  <Edit
    title=" "
    actions={<></>}
    mutationMode="pessimistic"
    {...props}
    redirect="show"
  >
    <SimpleForm toolbar={<CustomEditToolbar recordName={"products"} />}>
      <TextInput
        fullWidth
        source="sku"
        validate={[required(), maxLength(60)]}
      />
      <ImageInputWithPreview {...props} />
      <TextInput fullWidth source="denomination" />
      <TextInput fullWidth source="countries" />
      <TextInput fullWidth source="redeem_steps" multiline />
      <TextInput fullWidth source="description" multiline />
      <TextInput fullWidth source="terms_and_conditions" multiline />
      <TextInput fullWidth source="how_to_use" multiline />
      <ReferenceInput source="category_id" reference="categories">
        <AutocompleteInput
          fullWidth
          optionText="name"
          optionValue="id"
          filterToQuery={(searchText) => ({ name: searchText })}
          validate={[required()]}
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default InDrawer(
  <ProductShow />,
  <ProductEdit />,
  "Edit Product",
  "Edit Product details",
);
