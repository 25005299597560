import * as React from "react";
import {
  Create,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  AutocompleteInput,
  required,
  BooleanInput,
  NumberInput,
} from "react-admin";
import MerchantProductList from "./List";
import InDrawer from "../../hocs/InDrawer";
import { VatTypeInput } from "../../components";
import PriceInputs from "./PriceInputs";
const MerchantProductCreate = () => {
  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <ReferenceInput source="merchant_id" reference="merchants">
          <SelectInput
            optionText="name"
            optionValue="id"
            validate={[required()]}
            fullWidth
          />
        </ReferenceInput>
        <BooleanInput source="vat" />
        <VatTypeInput validate={[required()]} />
        <PriceInputs />
        <ReferenceInput source="product_id" reference="products">
          <AutocompleteInput
            optionText="sku"
            optionValue="id"
            filterToQuery={(sku) => ({ sku })}
            validate={[required()]}
            fullWidth
          />
        </ReferenceInput>
        <NumberInput fullWidth source="recommended_retail_price" />
      </SimpleForm>
    </Create>
  );
};

export default InDrawer(
  <MerchantProductList />,
  <MerchantProductCreate />,
  "Create Merchant Product",
  "Create new Merchant Product",
  "/merchant_products",
);
