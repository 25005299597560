import React from "react";
import {
  NumberInput,
  SelectInput,
  SelectField,
  FormDataConsumer,
} from "react-admin";
import PropTypes from "prop-types";
const CHOICES = [
  { id: "OS", name: "OS" },
  { id: "SG", name: "SG" },
  { id: "SM", name: "SM" },
  { id: "VI", name: "VI" },
  { id: "XO", name: "XO" },
];

const VatTypeInput = (props) => (
  <>
    <SelectInput
      source="vat_type"
      choices={CHOICES}
      key="vat_type"
      label="Vat Type"
      defaultValue="OS"
      {...props}
    />
    <FormDataConsumer>
      {({ formData }) => {
        if (formData.vat_type === "SM") {
          return <NumberInput source="nominal" label="Nominal value" />;
        }
      }}
    </FormDataConsumer>
  </>
);

VatTypeInput.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  validate: PropTypes.arrayOf(PropTypes.func),
  label: PropTypes.string,
  source: PropTypes.string,
};

const VatTypeFilterInput = () => (
  <SelectInput
    source="vat_type"
    choices={CHOICES}
    label="Vat Type"
    key="vat_type"
  />
);

const VatTypeField = () => (
  <SelectField
    source="vat_type"
    choices={CHOICES}
    label="Vat Type"
    key="vat_type"
  />
);

export { VatTypeInput, VatTypeField, VatTypeFilterInput };
