import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  ImageInput,
  ImageField,
  maxLength,
  AutocompleteInput,
} from "react-admin";
import FileInputPlaceholder from "../../components/common/FileInputPlaceholder";
import ProductList from "./List";
import InDrawer from "../../hocs/InDrawer";

const ProductCreate = () => {
  const [selectedRegion, setSelectedRegion] = React.useState(null);
  const [categoryDisabled, setCategoryDisabled] = React.useState(true);

  React.useEffect(() => {
    setCategoryDisabled(!selectedRegion);
  }, [selectedRegion]);

  const handleRegionChange = (event) => {
    const regionId = event.target.value;
    setSelectedRegion(regionId);
  };

  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput
          fullWidth
          source="sku"
          validate={[required(), maxLength(60)]}
        />
        <TextInput
          fullWidth
          source="name"
          validate={[required(), maxLength(100)]}
        />
        <TextInput fullWidth source="description" multiline />
        <TextInput fullWidth source="terms_and_conditions" multiline />
        <TextInput fullWidth source="how_to_use" multiline />
        <TextInput fullWidth source="redeem_steps" multiline />
        <TextInput fullWidth source="denomination" />
        <TextInput fullWidth source="countries" />

        <ReferenceInput source="region_id" reference="regions">
          <SelectInput
            fullWidth
            optionText="name"
            optionValue="id"
            validate={[required()]}
            onChange={handleRegionChange}
          />
        </ReferenceInput>

        {!categoryDisabled && (
          <ReferenceInput
            source="category_id"
            reference="categories"
            filter={{ region_id: selectedRegion }}
          >
            <AutocompleteInput
              fullWidth
              optionText="name"
              optionValue="id"
              filterToQuery={(searchText) => ({
                name: searchText,
                region_id: selectedRegion,
              })}
              validate={[required()]}
            />
          </ReferenceInput>
        )}

        <ImageInput
          sx={{
            "& .RaFileInput-dropZone": {
              backgroundColor: "transparent",
            },
          }}
          source="New_images"
          multiple
          accept="image/*"
          placeholder={<FileInputPlaceholder subText="up to 10 MB." />}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default InDrawer(
  <ProductList />,
  <ProductCreate />,
  "Create Product",
  "Create new Product",
  "/products",
);
