export const style = {
  container: {
    backgroundImage:
      "radial-gradient(circle at 50% 30em, #313264 0%, #00023b 60%, #00023b 100%)",
    color: "white",
    position: "relative",
  },
  boxImage: {
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "110px",
    width: "110px",
    marginRight: "10px",
    marginBottom: "15px",
  },
};
