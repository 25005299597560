import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  required,
  regex,
  maxLength,
  minLength,
  ReferenceArrayInput,
  useRecordContext,
} from "react-admin";
import InDrawer from "../../hocs/InDrawer";
import ShowAccount from "./Show";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";
import { TimezoneInput } from "../../components/Timezone";

const equalToPassword = (value, allValues) =>
  value !== allValues.password && "The two passwords must match";

const MerchantsInput = () => {
  const record = useRecordContext();
  if (record.type === "Accounts::MerchantAdmin") {
    return (
      <ReferenceArrayInput
        fullWidth
        reference="merchants"
        source="merchant_ids"
        validate={[required()]}
      />
    );
  }
};
const AccountEdit = () => {
  return (
    <Edit
      actions={<></>}
      title=" "
      mutationMode="pessimistic"
      undoable={false}
      redirect="show"
    >
      <SimpleForm toolbar={<CustomEditToolbar recordName="accounts" />}>
        <TextInput
          fullWidth
          source="email"
          validate={[
            required(),
            regex(
              /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
              "ra.validation.email",
            ),
            maxLength(50),
          ]}
        />
        <MerchantsInput />
        <TimezoneInput validate={required()} />
        <PasswordInput
          fullWidth
          source="password"
          validate={[required(), minLength(6), maxLength(30)]}
        />
        <PasswordInput
          fullWidth
          source="password_confirmation"
          validate={[required(), minLength(6), maxLength(30), equalToPassword]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default InDrawer(
  <ShowAccount />,
  <AccountEdit />,
  "Edit Account",
  "Edit Account details",
);
