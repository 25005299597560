import * as React from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  required,
  FormDataConsumer,
  ReferenceInput,
  FileInput,
  FileField,
  DateTimeInput,
  useNotify,
  ImageInput,
  ImageField,
} from "react-admin";
import PropTypes from "prop-types";
import Link from "@material-ui/core/Link";
import exampleClick from "./sampleFiles";
import uploadTypes from "./uploadTypes";
import FileInputPlaceholder from "../../components/common/FileInputPlaceholder";
import UploadList from "./List";
import InDrawer from "../../hocs/InDrawer";
import { Box } from "@material-ui/core";
const DownloadExampleButton = ({ type, notify }) => {
  if (type !== "Uploads::BulkImageUpload") {
    return (
      <Box sx={{ cursor: "pointer" }}>
        <Link
          onClick={(e) => {
            e.preventDefault();
            exampleClick(type, notify);
          }}
        >
          Download Example
        </Link>
      </Box>
    );
  }
};
DownloadExampleButton.propTypes = {
  type: PropTypes.string,
  notify: PropTypes.func,
};

const SkuMappingForm = () => (
  <>
    <ReferenceInput source="region_id" reference="regions">
      <SelectInput
        fullWidth
        optionText="name"
        optionValue="id"
        validate={[required()]}
      />
    </ReferenceInput>
    <FileInput
      source="file"
      validate={[required()]}
      accept=".csv, text/csv"
      placeholder={<FileInputPlaceholder subText=".CSV format, up to 50 MB." />}
    >
      <FileField source="src" title="title" />
    </FileInput>
  </>
);

const SupplierProductsForm = () => (
  <>
    <ReferenceInput
      source="integration_id"
      reference="integrations"
      filter={{ active: true }}
    >
      <SelectInput
        fullWidth
        optionText="name"
        optionValue="id"
        label="Provider"
        validate={[required()]}
      />
    </ReferenceInput>
    <ReferenceInput source="currency_id" reference="currencies">
      <SelectInput
        fullWidth
        optionText="name"
        optionValue="id"
        validate={[required()]}
      />
    </ReferenceInput>
    <FileInput
      source="file"
      validate={[required()]}
      accept=".csv, text/csv"
      placeholder={<FileInputPlaceholder subText=".CSV format, up to 50 MB." />}
    >
      <FileField source="src" title="title" />
    </FileInput>
  </>
);

const MerchantProductsForm = () => (
  <>
    <ReferenceInput
      source="merchant_id"
      reference="merchants"
      filter={{ active: true }}
    >
      <SelectInput
        fullWidth
        optionText="name"
        optionValue="id"
        validate={[required()]}
      />
    </ReferenceInput>
    <FileInput
      source="file"
      validate={[required()]}
      accept=".csv, text/csv"
      placeholder={<FileInputPlaceholder subText=".CSV format, up to 50 MB." />}
    >
      <FileField source="src" title="title" />
    </FileInput>
  </>
);
const BulkImageUploadForm = () => (
  <>
    <ReferenceInput source="region_id" reference="regions">
      <SelectInput
        fullWidth
        optionText="name"
        optionValue="id"
        validate={[required()]}
      />
    </ReferenceInput>
    <ImageInput
      sx={{
        "& .RaFileInput-dropZone": {
          backgroundColor: "transparent",
        },
      }}
      source="file"
      validate={[required()]}
      multiple
      accept=".jpeg, .jpg, .png"
      placeholder={<FileInputPlaceholder />}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  </>
);

const customInputs = {
  "Uploads::SkuMapping": SkuMappingForm,
  "Uploads::SupplierProducts": SupplierProductsForm,
  "Uploads::MerchantProducts": MerchantProductsForm,
  "Uploads::BulkImageUpload": BulkImageUploadForm,
};

const UploadCreate = () => {
  const notify = useNotify();
  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <SelectInput
          source="type"
          validate={[required()]}
          choices={uploadTypes}
        />

        <FormDataConsumer>
          {({ formData }) => {
            const CustomForm = customInputs[formData.type];
            return CustomForm ? (
              <>
                <DownloadExampleButton type={formData.type} notify={notify} />
                <CustomForm />
              </>
            ) : null;
          }}
        </FormDataConsumer>
        <DateTimeInput fullWidth source="appliance_time" />
      </SimpleForm>
    </Create>
  );
};

export default InDrawer(
  <UploadList />,
  <UploadCreate />,
  "Create Upload",
  "Upload your configurations here",
  "/uploads",
);
