import React, { useEffect, useState } from "react";
import {
  DateField,
  NumberField,
  ReferenceManyField,
  ReferenceField,
  Show,
  Datagrid,
  TabbedShowLayout,
  TextField,
  Pagination,
  usePermissions,
  useDataProvider,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import VersionList from "../../components/Version/List";
import ImagesList from "../../components/ImageManipulation/ImagesList";
import translatableFields from "../../components/Translations/TranslatableFields";
import EditableField from "../../components/Editable/Field";
const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/^[a-z]/, (letter) => letter.toUpperCase())
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", " ").replace("_", " "),
    );
export const CategoryShow = () => {
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();

  const [translatableKeys, setTranslatableKeys] = useState([]);
  const fields = translatableFields["categories"] || {};
  useEffect(() => {
    dataProvider.getList("locales").then(({ data }) => {
      const result = [];
      data.map((locale) => {
        fields.map((field) => {
          result.push({
            path: `translations.${locale.code}.${field}`,
            label: `${snakeToCamel(field)} (${locale.name})`,
          });
        });
      });
      setTranslatableKeys(result);
    });
  }, []);
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <TextField source="id" />
          <ReferenceField source="category_id" reference="categories">
            <TextField source="name" />
          </ReferenceField>
          <EditableField source="name" type="text" />
          <ReferenceField source="region_id" reference="regions">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="products_count" />
          <NumberField source="categories_count" />
          <DateField source="deleted_at" showTime />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="products">
          <ReferenceManyField
            target="category_id"
            reference="products"
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show" bulkActionButtons={false}>
              <TextField source="id" />
              <TextField source="name" />
              <DateField source="deleted_at" showTime />
              <DateField source="created_at" showTime />
              <DateField source="updated_at" showTime />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="images">
          <ImagesList />
        </TabbedShowLayout.Tab>
        {can("translations", "list", permissions) && (
          <TabbedShowLayout.Tab label="translations">
            {translatableKeys.map((key) => (
              <EditableField
                key={key.path}
                source={key.path}
                label={key.label}
                type={"textarea"}
              />
            ))}
          </TabbedShowLayout.Tab>
        )}
        {can("versions", "list", permissions) && (
          <TabbedShowLayout.Tab label="history">
            <VersionList item_type="Category" />
          </TabbedShowLayout.Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
export default CategoryShow;
