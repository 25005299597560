import React from "react";
import {
  Show,
  DateField,
  NumberField,
  TabbedShowLayout,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";
import VersionList from "../../components/Version/List";
import PolymorphicLink from "../../components/Polymorphic/PolymorphicLink";
import PropTypes from "prop-types";

const ItemLink = () => {
  const { priceable_id, priceable_type } = useRecordContext();
  return <PolymorphicLink type={priceable_type} id={priceable_id} />;
};

ItemLink.propTypes = {
  record: PropTypes.shape({
    priceable_id: PropTypes.number.isRequired,
    priceable_type: PropTypes.string.isRequired,
  }),
};

export const PriceShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <ItemLink />
          <NumberField source="price" />
          <NumberField source="price_without_vat" />
          <NumberField source="vat" />
          <ReferenceField source="currency_id" reference="currencies">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="updated_at" showTime />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="history">
          <VersionList item_type="Price" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default PriceShow;
