import React from "react";
import CustomModal from "../components/common/ModalSide";

const InDrawer = (component, children, title, subTitle, to) => {
  return (
    <>
      {component}
      <CustomModal open={true} title={title} subTitle={subTitle} to={to}>
        {children}
      </CustomModal>
    </>
  );
};

export default InDrawer;
