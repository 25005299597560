import * as React from "react";
import {
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  maxLength,
  FormDataConsumer,
  NumberInput,
  BooleanInput,
  minValue,
} from "react-admin";
import CustomProviderInputs from "./CustomProviderInputs";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";
import IntegrationShow from "./Show";
import InDrawer from "../../hocs/InDrawer";

const IntegrationEdit = () => (
  <Edit
    title=" "
    actions={<></>}
    mutationMode="pessimistic"
    undoable={false}
    redirect="show"
  >
    <SimpleForm toolbar={<CustomEditToolbar recordName="integrations" />}>
      <TextInput
        fullWidth
        source="name"
        validate={[required(), maxLength(100)]}
      />
      <NumberInput source="expected_balance" fullWidth defaultValue={0.0} />
      <ReferenceInput source="supplier_id" reference="suppliers">
        <SelectInput
          fullWidth
          optionText="name"
          optionValue="id"
          validate={[required()]}
        />
      </ReferenceInput>
      <BooleanInput source="notifier_on_balance_change" />

      <SelectInput
        fullWidth
        validate={[required()]}
        source="provider_type"
        choices={[
          { id: "Internal", name: "Internal" },
          { id: "External", name: "External" },
        ]}
      />
      <NumberInput
        source="vat_rate"
        fullWidth
        validate={[minValue(0), required()]}
      />

      <ReferenceInput
        source="region_id"
        reference="regions"
        validate={[required()]}
      >
        <SelectInput fullWidth optionText="name" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput
        source="currency_id"
        reference="currencies"
        validate={[required()]}
      >
        <SelectInput fullWidth optionText="name" optionValue="id" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData: { supplier_id } }) => (
          <CustomProviderInputs supplier_id={supplier_id} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
export default InDrawer(
  <IntegrationShow />,
  <IntegrationEdit />,
  "Edit Provider",
  "Edit provider details",
);
