import React from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";
import PropTypes from "prop-types";

const TimezoneInput = ({ referenceProps, autocompleteProps }) => (
  <ReferenceInput source="timezone" reference="timezones" {...referenceProps}>
    <AutocompleteInput
      optionText={"name"}
      optionValue={"name"}
      {...autocompleteProps}
    />
  </ReferenceInput>
);

TimezoneInput.propTypes = {
  referenceProps: PropTypes.shape({
    validate: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    ),
    label: PropTypes.string,
  }),
  autocompleteProps: PropTypes.shape({
    validate: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    ),
    defaultValue: PropTypes.string,
    label: PropTypes.string,
  }),
};
TimezoneInput.defaultProps = {
  referenceProps: {
    validate: [],
    label: "Timezone",
  },
  autocompleteProps: {
    validate: [],
  },
};

export default TimezoneInput;
