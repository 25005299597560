import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  required,
  SelectInput,
  maxLength,
  minLength,
  regex,
  FormDataConsumer,
  ReferenceInput,
  usePermissions,
  useUnique,
  ReferenceArrayInput,
} from "react-admin";
import AccountList from "./List";
import InDrawer from "../../hocs/InDrawer";
import { TimezoneInput } from "../../components/Timezone";

const accountTypes = (role) => {
  if (role === "Accounts::SuperAdmin") {
    return [
      { id: "Accounts::SuperAdmin", name: "SuperAdmin" },
      { id: "Accounts::MerchantAdmin", name: "Merchant" },
      { id: "Accounts::RegionalAdmin", name: "RegionalAdmin" },
    ];
  } else if (role === "Accounts::RegionalAdmin") {
    return [{ id: "Accounts::MerchantAdmin", name: "Merchant" }];
  } else {
    return [];
  }
};
const MerchantAdminInputs = () => (
  <ReferenceArrayInput source="merchant_ids" reference="merchants" />
);
const RegionalAdminInputs = () => (
  <ReferenceInput
    source="region_ids"
    reference="regions"
    validate={[required()]}
  />
);

const AccountCreate = () => {
  const unique = useUnique();
  const [role, setRole] = React.useState();
  React.useEffect(() => {
    setRole(localStorage.getItem("role"));
  }, []);
  const { permissions } = usePermissions();
  if (!permissions) return null;
  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <TextInput
          fullWidth
          source="email"
          validate={[
            unique(),
            required(),
            maxLength(50),
            regex(
              /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
              "ra.validation.email",
            ),
          ]}
        />
        <SelectInput
          fullWidth
          source="type"
          choices={accountTypes(role)}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.type === "Accounts::MerchantAdmin") {
              return <MerchantAdminInputs />;
            } else if (formData.type === "Accounts::RegionalAdmin") {
              return <RegionalAdminInputs />;
            } else {
              return null;
            }
          }}
        </FormDataConsumer>
        <PasswordInput
          fullWidth
          source="password"
          validate={[required(), minLength(6), maxLength(30)]}
        />
        <PasswordInput
          fullWidth
          source="password_confirmation"
          validate={[required(), minLength(6), maxLength(30)]}
        />
        <TimezoneInput autocompleteProps={{ validate: [required()] }} />
      </SimpleForm>
    </Create>
  );
};

export default InDrawer(
  <AccountList />,
  <AccountCreate />,
  "Create Account",
  "Create new Account",
  "/accounts",
);
