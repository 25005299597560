import React from "react";
import {
  Show,
  DateField,
  TabbedShowLayout,
  ReferenceField,
  TextField,
  useRecordContext,
  // usePermissions,
} from "react-admin";
// import VersionList from "../../components/Version/List";
import PolymorphicLink from "../../components/Polymorphic/PolymorphicLink";
import PropTypes from "prop-types";
import EditableField from "../../components/Editable/Field";
// import { can } from "../../../shared/permissionProvider";

const ItemLink = () => {
  const {
    translatable_id: translatableId,
    translatable_type: translatableType,
  } = useRecordContext();
  if (!translatableId || !translatableType) {
    return null;
  }

  return <PolymorphicLink type={translatableType} id={translatableId} />;
};

ItemLink.propTypes = {
  record: PropTypes.shape({
    translatableId: PropTypes.number,
    translatableType: PropTypes.string,
  }),
};

export const TranslationShow = () => {
  // const { permissions } = usePermissions();
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <ItemLink />
          <ReferenceField source="locale_id" reference="locales">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="key" />
          <EditableField source="value" />
          <DateField source="updated_at" showTime />
        </TabbedShowLayout.Tab>
        {/* {can("versions", "list", permissions) && (
          <TabbedShowLayout.Tab label="history">
            <VersionList item_type="Translation" />
          </TabbedShowLayout.Tab>
        )} */}
      </TabbedShowLayout>
    </Show>
  );
};
export default TranslationShow;
