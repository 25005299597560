import React, { useState } from "react";
import {
  TextField,
  NumberField,
  ReferenceField,
  ShowButton,
  UpdateButton,
  useListContext,
  BulkUpdateButton,
} from "react-admin";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Checkbox,
  Toolbar,
  Button,
} from "@mui/material";
import { EditableField } from "../../components/Editable/Field";
import noImage from "../../../assets/icons/no-image.svg";
import PropTypes from "prop-types";
import { style } from "./style";

const placeholderImage = noImage;

const ProductCard = ({ record, selected, onSelect }) => (
  <Card variant="outlined" sx={style.cardView.container}>
    <Box sx={{ position: "relative" }}>
      <Checkbox
        checked={selected}
        onChange={() => onSelect(record.id)}
        sx={{ position: "absolute", top: 10, left: 10, zIndex: 1 }}
      />
      <CardMedia
        component="img"
        image={record?.image?.url || placeholderImage}
        alt={record?.translations?.en?.name || "Placeholder"}
        sx={style.cardView.cardMedia}
      />
    </Box>
    <CardContent sx={style.cardView.cardContent}>
      <Box sx={style.cardView.innerWrap}>
        <Box sx={{ fontWeight: "bold" }}>
          <EditableField
            source={"translations.en.name"}
            type="text"
            record={record}
            showExtra={true}
            maxChar={128}
            classes={style.cardView.truncate}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: "0.875rem", lineHeight: 1.43 }}
          >
            Id:
          </Typography>
          <NumberField source="id" record={record} />
        </Box>
        <Box sx={style.cardView.titleWrap}>
          <Box sx={{ fontWeight: "bold" }}>Sku:</Box>
          <EditableField
            source={"sku"}
            type="text"
            record={record}
            showExtra={true}
            classes={style.cardView.skuTruncate}
          />
        </Box>
        <Box sx={style.cardView.titleWrap}>
          <Box sx={{ fontWeight: "bold" }}>Category:</Box>
          <ReferenceField
            source="category_id"
            reference="categories"
            record={record}
          >
            <TextField source="name" />
          </ReferenceField>
        </Box>
        <Box sx={style.cardView.titleWrap}>
          <Box sx={{ fontWeight: "bold" }}>Countries:</Box>
          <EditableField
            source={"countries"}
            type="text"
            record={record}
            showExtra={true}
          />
        </Box>
        <Box sx={style.cardView.titleWrap}>
          <Box sx={{ fontWeight: "bold" }}>Denomination:</Box>
          <EditableField
            source={"denomination"}
            type="text"
            record={record}
            showExtra={true}
          />
        </Box>
        <Box sx={style.cardView.titleWrap}>
          <Box sx={{ fontWeight: "bold" }}>Region:</Box>
          <ReferenceField
            source="region_id"
            reference="regions"
            record={record}
          >
            <TextField source="name" />
          </ReferenceField>
        </Box>
        <Box sx={style.cardView.titleWrap}>
          <Box sx={{ fontWeight: "bold" }}>Integration Products count:</Box>
          <NumberField source="integration_products_count" record={record} />
        </Box>
      </Box>
      <Box
        sx={{
          mt: "auto",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "center", gap: "10px", mt: 2 }}
        >
          <ShowButton record={record} />
          <UpdateButton
            record={record}
            data={{ populate: true }}
            label="Populate"
            mutationMode="pessimistic"
          />
        </Box>
      </Box>
    </CardContent>
  </Card>
);

ProductCard.propTypes = {
  record: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

const CardList = () => {
  const { data, isLoading } = useListContext();
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelect = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id],
    );
  };

  const handleSelectAll = () => {
    if (selectedIds.length === data.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.map((record) => record.id));
    }
  };

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const bulkActionsToolbar = (
    <Toolbar>
      <Button onClick={handleSelectAll}>
        {selectedIds.length === data.length ? "Deselect All" : "Select All"}
      </Button>
      <BulkUpdateButton
        selectedIds={selectedIds}
        data={{ populate: true }}
        label="Populate"
        mutationMode="pessimistic"
      />
    </Toolbar>
  );

  return (
    <>
      {selectedIds.length > 0 && bulkActionsToolbar}
      <Grid container spacing={2} justifyContent="center">
        {data.map((record) => (
          <Grid
            item
            key={record.id}
            xs={12}
            sm={6}
            md={4}
            lg={"auto"}
            xl={"auto"}
            sx={{ minWidth: 371, mr: 1 }}
          >
            <ProductCard
              record={record}
              selected={selectedIds.includes(record.id)}
              onSelect={handleSelect}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CardList;
