import React, { useState, useEffect } from "react";
import { Title as AdminTitle, useDataProvider } from "react-admin";
import { Box } from "@mui/system";
import { dashboardTitle } from "./helpers/dashboardTitle";
import ChartArea from "../../components/common/ChartArea";
import {
  FilterCurrency,
  FilterStatus,
  MerchantFilter,
  MerchantInfo,
  FilterCalendar,
} from "../../components/Dashboard";
import { style } from "./style";

const Dashboard = () => {
  const role = localStorage.getItem("role");
  const [currency, setCurrency] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [currencyId, setCurrencyId] = useState(
    sessionStorage.getItem("currency"),
  );
  const [ordersCount, setOrdersCount] = useState(0);
  const [dateFrom, setDateFrom] = useState(sessionStorage.getItem("startDate"));
  const [dateTo, setDateTo] = useState(sessionStorage.getItem("endDate"));
  const [status, setStatus] = useState("completed");
  const [stats, setStats] = useState(null);
  const [ordersData, setOrdersData] = useState({});
  const [salesAmount, setSalesAmount] = useState(0);
  const [profitAmount, setProfitAmount] = useState(0);
  const [currentMenuOpen, setCurrentMenuOpen] = useState(null);
  const dataProvider = useDataProvider();

  const filterEmptyFields = (obj) => {
    return Object.fromEntries(
      //eslint-disable-next-line
      Object.entries(obj).filter(([_, value]) => value != null && value !== ""),
    );
  };
  useEffect(() => {
    dataProvider
      .getDashboard(
        "dashboard",
        filterEmptyFields({
          after: dateFrom,
          before: dateTo,
          currency_id: currencyId,
          status,
          merchant_id: merchant || null,
        }),
      )
      .then((res) => {
        setStats(res.stats);
        setOrdersData(res);
        setSalesAmount(
          res.stats
            .map(({ price }) => parseFloat(price))
            .reduce((partialSum, a) => partialSum + a, 0),
        );
        setProfitAmount(
          res.stats
            .map(({ profit }) => parseFloat(profit))
            .reduce((partialSum, a) => partialSum + a, 0),
        );
        setOrdersCount(
          res.stats
            .map(({ count }) => parseFloat(count))
            .reduce((partialSum, a) => partialSum + a, 0),
        );
        setCurrency(res.currencies.find((c) => c.id === parseInt(currencyId)));
      });
  }, [currencyId, dateFrom, dateTo, status, merchant]);

  return (
    <Box sx={style.dashboardWrap}>
      <AdminTitle
        title={dashboardTitle(
          "Dashboard",
          "Overview of sales, balance, and orders",
        )}
      />
      <Box sx={style.filterWrap}>
        <FilterCalendar
          setStart={setDateFrom}
          setEnd={setDateTo}
          after={true}
          id={"dashAfter"}
        />
        <FilterCalendar
          setStart={setDateFrom}
          setEnd={setDateTo}
          id={"dashBefore"}
        />

        <FilterStatus
          setStatus={setStatus}
          currentMenuOpen={currentMenuOpen}
          setCurrentMenuOpen={setCurrentMenuOpen}
        />
        {ordersData?.merchants?.length > 1 && (
          <MerchantFilter
            setMerchant={setMerchant}
            currencyId={parseInt(currencyId)}
            ordersData={ordersData}
            setCurrencyId={setCurrencyId}
            merchant={merchant}
            currentMenuOpen={currentMenuOpen}
            setCurrentMenuOpen={setCurrentMenuOpen}
          />
        )}
        {ordersData?.currencies?.length > 1 && (
          <FilterCurrency
            setCurrency={setCurrencyId}
            options={ordersData?.currencies}
            setMerchant={setMerchant}
            currencyId={currencyId}
            merchant={merchant}
            currentMenuOpen={currentMenuOpen}
            setCurrentMenuOpen={setCurrentMenuOpen}
          />
        )}
      </Box>
      <Box sx={style.contentWrap}>
        <Box sx={style.userWrap}>
          {role === "Accounts::MerchantAdmin" && (
            <Box sx={style.merchantWrap}>
              <MerchantInfo
                symbol={currency?.extras?.symbol}
                pickedMerchant={parseInt(merchant)}
              />
            </Box>
          )}
          <Box sx={{ fontSize: "16px", fontWeight: 500 }}>Sales</Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={style.sales}>
              {currency?.extras?.symbol}
              {salesAmount.toFixed(2)}
            </Box>
          </Box>
          <ChartArea
            role={role}
            data={stats}
            currency={currency}
            id="sales"
            dataKeyName="price"
            height={role === "Accounts::MerchantAdmin" ? "50%" : null}
          />
        </Box>
        {role !== "Accounts::MerchantAdmin" && (
          <Box sx={style.userWrap}>
            <Box sx={{ fontSize: "16px", fontWeight: 500 }}>Profit</Box>
            <Box sx={style.profitWrap}>
              <Box sx={style.currencyWrap}>
                {currency?.extras?.symbol}
                {profitAmount.toFixed(2)}
              </Box>
            </Box>
            <ChartArea
              data={stats}
              id="profit"
              currency={currency}
              dataKeyName="profit"
            />
          </Box>
        )}
      </Box>
      <Box sx={style.ordersWrap}>
        <Box sx={{ fontSize: "16px", fontWeight: 500 }}>Orders</Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={style.ordersCount}>{ordersCount}</Box>
        </Box>
        <ChartArea
          data={stats}
          stopColor1="#159C74"
          stopColor2="#159C74"
          strokeColor="#159C74"
          id="orders"
          dataKeyName="count"
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
