import React from "react";
import {
  Datagrid,
  NumberField,
  ArrayField,
  CreateButton,
  TextField,
} from "react-admin";

import PropTypes from "prop-types";

const CreateRelatedPriceButton = ({ priceable_type, priceable_id }) => {
  return (
    <CreateButton
      sx={{
        width: "90px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        mt: "100px",
      }}
      resource="prices"
      state={{ record: { priceable_type, priceable_id } }}
    />
  );
};
CreateRelatedPriceButton.propTypes = {
  priceable_type: PropTypes.string.isRequired,
  priceable_id: PropTypes.number.isRequired,
};

const PriceList = () => (
  <ArrayField source="prices_array">
    <Datagrid bulkActionButtons={false}>
      <TextField source="currency" />
      <NumberField type={"number"} source="price" label="Price(VAT)" />
      <NumberField
        type={"number"}
        source="price_without_vat"
        label="Price(no VAT)"
      />
      <NumberField source="vat_amount" label="VAT amount" />
    </Datagrid>
  </ArrayField>
);

export default PriceList;
