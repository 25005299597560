import React, { useState } from "react";
import {
  SimpleForm,
  ReferenceArrayInput,
  SaveButton,
  Toolbar,
  useDataProvider,
  useRecordContext,
  AutocompleteArrayInput,
  useNotify,
} from "react-admin";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Divider,
  Button,
} from "@mui/material";

const optionRender = (choice) => {
  return `${choice.name_by_supplier} #${choice.ean}`;
};
const MergeLogs = ({ logs }) => (
  <DialogContent>
    <Typography variant="h6" gutterBottom>
      Results
    </Typography>
    <ul>
      {logs.map((log, index) => {
        return <li key={index}>{log}</li>;
      })}
    </ul>
  </DialogContent>
);

MergeLogs.propTypes = {
  logs: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const MergeForm = ({ onClose, setLogs }) => {
  const notify = useNotify();
  const [error, setError] = useState(null);
  const record = useRecordContext();
  const [integration, setIntegration] = React.useState(null);
  const dataProvider = useDataProvider();

  const handleSave = async ({ other_ids }) => {
    dataProvider
      .performMerge("integration_products", {
        id: record.id,
        data: { other_ids },
      })
      .then(async ({ data }) => {
        setLogs(data.logs);
        return notify("Merged successfully");
      });
  };

  const trackOnChange = (value) =>
    dataProvider
      .checkMerge("integration_products", {
        id: record.id,
        data: { other_ids: value },
      })
      .then(({ data: { result, message } }) => {
        if (result) {
          setError(null);
        } else {
          setError(message);
          notify(message, { type: "error" });
        }
      });

  React.useEffect(() => {
    if (record) {
      dataProvider
        .getOne("integrations", { id: record.integration_id })
        .then(({ data }) => {
          setIntegration(data);
        });
    }
  }, [record, dataProvider]);

  return (
    <DialogContent>
      <Box mb={2}>
        <Typography variant="h6" gutterBottom>
          Base Product
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {integration?.name} - {record?.name_by_supplier} (EAN: {record?.ean})
        </Typography>
      </Box>
      <Divider />
      <Box mt={2} mb={2}>
        <Typography variant="h6" gutterBottom>
          Products to Merge
        </Typography>
        <SimpleForm
          onSubmit={handleSave}
          toolbar={
            <Toolbar>
              <SaveButton disabled={!!error} label="Merge" />
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
            </Toolbar>
          }
        >
          <ReferenceArrayInput
            reference="integration_products"
            source="other_ids"
            filter={{
              integration_id: record?.integration_id,
              active: true,
              excludeId: record.id,
            }}
          >
            <AutocompleteArrayInput
              optionText={optionRender}
              optionValue="id"
              onChange={trackOnChange}
              fullWidth
              label="Products to Merge"
            />
          </ReferenceArrayInput>
          {error && (
            <Typography color="error" variant="body2" mt={1}>
              {error}
            </Typography>
          )}
        </SimpleForm>
      </Box>
    </DialogContent>
  );
};

MergeForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  setLogs: PropTypes.func.isRequired,
};

const MergeDialog = ({ open, onClose }) => {
  const [logs, setLogs] = useState([]);
  const handleClose = () => {
    setLogs([]);
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Merge Provider Products</DialogTitle>

      {logs.length > 0 ? (
        <MergeLogs logs={logs} />
      ) : (
        <MergeForm open={open} onClose={handleClose} setLogs={setLogs} />
      )}
    </Dialog>
  );
};
MergeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default MergeDialog;
