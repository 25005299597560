import React, { useState, useRef } from "react";
import {
  useDataProvider,
  useRefresh,
  useResourceContext,
  usePermissions,
  useNotify,
  useRecordContext,
} from "react-admin";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import ModeIcon from "@mui/icons-material/Mode";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import { can } from "../../../shared/permissionProvider";
import { get } from "lodash";
import BoolColored from "../common/BoolColored";

const EditableBooleanField = ({ source, justify, adminOnly, recordOut }) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const record = recordOut ? recordOut : useRecordContext();
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(get(record, source));
  const anchorRef = useRef(null);
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const canEdit = can(resource, "edit", permissions);
  const role = localStorage.getItem("role");
  const preventEdit = role !== "Accounts::SuperAdmin" && adminOnly;
  const handleToggleChange = () => setInputValue((prevValue) => !prevValue);

  const handleSave = () => {
    dataProvider
      .update(resource, {
        id: record.id,
        data: { [source.replace("translations.", "").split(".")]: inputValue },
      })
      .then(() => {
        refresh();
        notify("Saved");
        setIsEditing(false);
      })
      .catch((error) => error.message && notify(error.message, "error"));
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setInputValue(get(record, source));
    setIsEditing(false);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditing(true);
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: justify || "unset",
      }}
      ref={anchorRef}
    >
      {canEdit && isEditing && (
        <Popper
          open={isEditing}
          anchorEl={anchorRef.current}
          onClick={(e) => e.stopPropagation()}
        >
          <Paper
            style={{ padding: "8px", display: "flex", alignItems: "center" }}
          >
            <Switch checked={inputValue} onChange={handleToggleChange} />
            <IconButton onClick={handleSave} size="small" color="primary">
              <CheckIcon />
            </IconButton>
            <IconButton onClick={handleCancel} size="small" color="secondary">
              <CloseIcon />
            </IconButton>
          </Paper>
        </Popper>
      )}
      {
        <>
          <BoolColored source={source} recordOut={recordOut} />
          {canEdit && !preventEdit && (
            <IconButton onClick={handleEditClick} size="small">
              <ModeIcon fontSize="small" />
            </IconButton>
          )}
        </>
      }
    </Box>
  );
};

EditableBooleanField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  justify: PropTypes.object,
  adminOnly: PropTypes.bool,
  recordOut: PropTypes.object,
};

export default EditableBooleanField;
