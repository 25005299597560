export const style = {
  truncate: {
    display: "-webkit-box",
    minWidth: "100px",
    maxWidth: "300px",
    wordBreak: "break-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    "&:hover": {
      WebkitLineClamp: 4,
      overflow: "visible",
      display: "block",
    },
  },
  sortButton: {
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
    padding: "0px",
    width: "100px",
    borderRadius: "6px",
    background: "transparent",
    display: "flex",
    backgroundColor: "transparent",
    color: "#201C6C",
    height: "32px",
    boxShadow:
      " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
    "&:hover": {
      boxShadow: "unset",
      border: "1px solid",
      transition: "unset",
      borderColor: "#DFE1E6",
      backgroundColor: "#F6F8FA",
    },
  },
  cardContainer: {
    minWidth: 370,
    minHeight: 500,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    backgroundColor: "#F6F8FA",
  },
  cardMedia: {
    height: 150,
    maxWidth: 371,
    objectFit: "contain",
    background: "white",
  },
  cardContent: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardInnerContentWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    fontSize: "0.875rem",
    lineHeight: 1.43,
  },
  nameField: {
    fontWeight: "bold",
    maxWidth: "300px",
    wordBreak: "break-all",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    "&:hover": {
      WebkitLineClamp: 4,
      overflow: "visible",
      display: "block",
    },
  },
  idTitle: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: 1.43,
  },
  titleWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
  },
  truncateField: {
    maxWidth: "260px",
    wordBreak: "break-all",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    "&:hover": {
      WebkitLineClamp: 4,
      overflow: "visible",
      display: "block",
    },
  },
  showButtonWrap: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    mt: 2,
  },
  show: {
    nameField: {
      display: "flex",
      maxWidth: "500px",
      flexWrap: "wrap",
      wordBreak: "break-all",
    },
  },
};
