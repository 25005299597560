import React, { useState } from "react";
import { useNotify, useDataProvider, useRecordContext } from "react-admin";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { LocalShipping } from "@mui/icons-material";
import { style } from "./style";

const ResendButton = () => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const order = useRecordContext();
  const notify = useNotify();

  const resend = () => {
    setLoading(true);
    return dataProvider.resendOrder(order.id).then(() => {
      notify(`Order #${order.id} has been resent`);
      setLoading(false);
    });
  };

  return (
    <Tooltip title="Resend">
      <Button sx={style.actionButton} disabled={loading} onClick={resend}>
        <LocalShipping />
      </Button>
    </Tooltip>
  );
};

export default ResendButton;
