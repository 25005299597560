import * as React from "react";
import {
  Edit,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
} from "react-admin";
import InDrawer from "../../hocs/InDrawer";
import ConfigShow from "./Show";

const EditCategory = () => (
  <Edit actions={false} redirect="show">
    <SimpleForm>
      <SelectInput
        source="key"
        choices={[
          {
            id: "slack_webhook_url",
            name: "slack_webhook_url",
          },
          {
            id: "slack_channel",
            name: "slack_channel",
          },
        ]}
        validate={[required()]}
      />
      <TextInput source="value" validate={[required()]} fullWidth />
    </SimpleForm>
  </Edit>
);

export default InDrawer(
  <ConfigShow />,
  <EditCategory />,
  "Edit Config",
  "Edit Config details",
  "/configs",
);
