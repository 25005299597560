import React, { useState, useEffect } from "react";
import { useLogin, useNotify } from "react-admin";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import PHwhite from "../../../assets/icons/PH-white.svg";
import playheraTextWhite from "../../../assets/icons/playheraTextWhite.svg";
import { makeStyles } from "@material-ui/core/styles";
import { style } from "./style";

const useStyles = makeStyles(() => ({
  textField: {
    backgroundColor: "white",
    color: "black",
    borderRadius: "8px",
    height: "0px",
  },
}));

const LoginPage = () => {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLocked, setIsLocked] = useState(false);
  const [counter, setCounter] = useState(lockoutTime);
  const lockoutTime = 15;
  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    let timer;
    if (isLocked && counter > 0) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else if (counter === 0 && isLocked) {
      setIsLocked(false);
      setCounter(lockoutTime);
    }
    return () => clearInterval(timer);
  }, [isLocked, counter]);

  const handleClick = () => {
    const validationErrors = {};

    if (!username.trim()) {
      validationErrors.username = "Email is required";
    } else if (!username.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      validationErrors.username = "Please enter a valid email address";
    }

    if (!password.trim()) {
      validationErrors.password = "Password is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      login({ username, password }).catch((error) => {
        if (error.status === 423) {
          setIsLocked(true);
          setCounter(lockoutTime);
          notify(
            "Account is locked due to too many failed attempts. Please try again later.",
          );
        } else {
          notify("Invalid email or password");
        }
      });
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setErrors({ ...errors, username: "" });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors({ ...errors, password: "" });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={style.container}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ position: "absolute", top: "calc(50vh - 200px)" }}
      >
        <Box
          component="img"
          alt="playhera logo"
          src={PHwhite}
          style={style.boxImage}
        />
        <Box
          component="img"
          alt="playhera logo"
          src={playheraTextWhite}
          width="300px"
        />
      </Box>
      <Box width={300}>
        <TextField
          type="email"
          placeholder="Email *"
          variant="outlined"
          value={username}
          onChange={handleUsernameChange}
          fullWidth
          error={!!errors.username}
          helperText={errors.username}
          onKeyDown={handleKeyPress}
          required
          color="primary"
          InputProps={{ classes: { input: classes.textField } }}
        />
        <TextField
          placeholder="Password *"
          color="primary"
          type="password"
          variant="outlined"
          required
          value={password}
          onChange={handlePasswordChange}
          onKeyDown={handleKeyPress}
          fullWidth
          margin="normal"
          error={!!errors.password}
          helperText={errors.password}
          InputProps={{ classes: { input: classes.textField } }}
        />
        <Button
          variant="contained"
          color={isLocked ? "secondary" : "primary"}
          onClick={handleClick}
          fullWidth
          style={{ marginTop: 20, color: isLocked ? "red" : "white" }}
          disabled={isLocked}
        >
          {isLocked ? `Locked (${counter}s)` : "Sign in"}
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPage;
