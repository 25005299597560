import React from "react";
import {
  List,
  Datagrid,
  DateField,
  NumberField,
  TextInput,
  BooleanInput,
  FunctionField,
  ReferenceArrayField,
  ShowButton,
} from "react-admin";
import AccountBadge from "../../components/Badges/AccountBadge";
import ListFilterValues from "../../components/common/ListFilterValues";
import EditableField from "../../components/Editable/Field";

const AccountFilters = [
  <TextInput source="email" key="email" />,
  <BooleanInput source="active" key="active" defaultValue={true} />,
];

const AccountList = () => {
  const [active, setActive] = React.useState(false);
  return (
    <List
      filters={AccountFilters}
      exporter={false}
      filterDefaultValues={{ active: true }}
      aside={<ListFilterValues setActive={setActive} />}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <NumberField source="id" />
        <EditableField source="email" justify={{ justifyContent: "center" }} />
        <FunctionField
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          source="type"
          render={(account) => <AccountBadge text={account.type} />}
        />
        <DateField source="created_at" showTime />
        <ReferenceArrayField
          source="merchant_ids"
          reference="merchants"
          sortable={false}
        />
        {!active && <DateField source="deleted_at" showTime />}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default AccountList;
