import React from "react";
import { Box } from "@mui/system";
import steamWallet from "assets/icons/steamWallet.svg";
const data = [
  {
    name: "Steam Wallet Code",
    amount: 10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: 10,
  },
  {
    name: "Steam Wallet Code",
    amount: 10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: 10,
  },
  {
    name: "Steam Wallet Code",
    amount: 10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: 10,
  },
  {
    name: "Steam Wallet Code",
    amount: 10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: -10,
  },
  {
    name: "Steam Wallet Code",
    amount: 10,
  },
];

const Balance = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        overflowY: "scroll",
        bottom: "0px",
        top: "159px",
        width: "fill-available",
        scrollbarColor: "transparent transparent",
        scrollbarWidth: "thin",
        paddingRight: "12px",
        msOverflowStyle: "none",
        WebkitOverflowScrolling: "touch",
        "&::-webkit-scrollbar": {
          width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent",
        },
      }}
    >
      {data.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "10px 20px",
              borderBottom: "1px solid #ECEFF3",
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={steamWallet} alt="steamWallet" />
              <Box
                sx={{ marginLeft: "5px", fontSize: "14px", color: "#818898" }}
              >
                {item.name}
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: "14px",
                color: item.amount < 0 ? "red" : "green",
              }}
            >
              {item.amount > 0
                ? `$${item.amount.toFixed(2)}`
                : `-$${Math.abs(item.amount).toFixed(2)}`}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Balance;
