export const copyToClipboard = async (e, textToCopy, notify, translate) => {
  e.preventDefault();
  e.stopPropagation();

  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
    notify(translate("copied", { _: "Copied to clipboard" }));
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();
    notify(translate("copied", { _: "Copied to clipboard" }));
    try {
      document.execCommand("copy");
    } finally {
      textArea.remove();
    }
  }
};
