import * as React from "react";
import { DateField, Show, TabbedShowLayout, TextField } from "react-admin";
import VersionList from "../../components/Version/List";
import EditableField from "../../components/Editable/Field";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
const CurrencyShow = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="summary">
        <TextField source="id" />
        <EditableField source="iso_code" />
        <EditableField source="name" />
        <EditableBooleanField source="default" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="history">
        <VersionList item_type="Currency" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
export default CurrencyShow;
