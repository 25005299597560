import React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  FunctionField,
  SelectInput,
  TextInput,
  ReferenceInput,
  DateTimeInput,
  FilterButton,
  TopToolbar,
  CreateButton,
  ExportButton,
  ShowButton,
} from "react-admin";
import StatusBadge from "../../components/Badges/StatusBadge";

import Box from "@mui/material/Box";
import { snakeCase } from "lodash";
import pluralize from "pluralize";
const ContractsFilter = [
  <SelectInput
    source="type"
    choices={[
      { id: "Contracts::Topup", name: "Topup" },
      { id: "Contracts::Refund", name: "Refund" },
      { id: "Contracts::Payment", name: "Payment" },
    ]}
    key="type_filter"
  />,
  <TextInput source="id" key="id_filter" />,
  <SelectInput
    source="status"
    choices={[
      { id: "pending", name: "Pending" },
      { id: "completed", name: "Completed" },
      { id: "refunded", name: "Refunded" },
    ]}
    key="status_filter"
  />,
  <ReferenceInput
    source="wallet_id"
    reference="wallets"
    key="wallet_id_filter"
    label="Merchant"
  >
    <SelectInput optionText="name" optionValue="id" label="Owner" />
  </ReferenceInput>,
  <DateTimeInput source="before" key="before" />,
  <DateTimeInput source="after" key="after" />,
];

const ListActions = () => {
  const role = localStorage.getItem("role");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <TopToolbar sx={{ position: "relative" }}>
        <FilterButton />
        {role !== "Accounts::MerchantAdmin" && <CreateButton />}
        <ExportButton />
      </TopToolbar>
    </Box>
  );
};
//
export const ContractList = () => (
  <List
    filters={ContractsFilter}
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="amount" />
      <FunctionField
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        source="status"
        render={(order) => <StatusBadge text={order.status} />}
      />
      <FunctionField
        render={(record) => record.type.replace(/^Contracts::/, "")}
        source="type"
      />
      <ReferenceField source="order_id" reference="orders" />
      <ReferenceField source="currency_id" reference="currencies" />
      <ReferenceField source="wallet_id" reference="wallets" label="Merchant">
        <FunctionField
          render={(record) => {
            return (
              <ReferenceField
                source="owner_id"
                reference={pluralize(snakeCase(record.owner_type))}
                link={"show"}
              />
            );
          }}
        />
      </ReferenceField>
      <ReferenceField source="contract_id" reference="contracts" />
      <DateField source="created_at" showTime />
      <ReferenceField source="region_id" reference="regions" />
      <TextField source="wallet_before_balance" />
      <TextField source="wallet_after_balance" />
      <ShowButton />
    </Datagrid>
  </List>
);
export default ContractList;
