import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Divider, Button, Checkbox } from "@mui/material";
import notifyIcon from "../../../assets/icons/notifyIcon.svg";
import {
  useDataProvider,
  ListContextProvider,
  useRedirect,
  useRefresh,
  useNotify,
} from "react-admin";
import useInterval from "../../../hooks/useInterval";
import CustomModal from "./ModalSide";
import NotificationTypes from "../Badges/NotificationTypes";
import { Select } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { style } from "./style";
const Notification = () => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [notificationsQty, setNotificationsQty] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [filter, setFilter] = useState({ seen: false });
  const scrollContainerRef = useRef(null);

  const fetchNotifications = async (page, filter) => {
    setLoading(true);
    try {
      const { data } = await dataProvider.getList("notifications", {
        pagination: { perPage: 10, page },
        sort: { field: "id", order: "DESC" },
        filter: filter.seen !== "All" && filter,
      });

      setNotifications((prevNotifications) => [...prevNotifications, ...data]);
      setHasMore(data.length > 0);
    } catch (error) {
      notify("Error fetching notifications", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setNotifications([]);
    setPage(1);
    fetchNotifications(1, filter);
  }, [filter]);

  useEffect(() => {
    fetchNotifications(page, filter);
  }, [page]);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 5 &&
      hasMore &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, loading]);

  const handleOpen = () => {
    refresh();
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleClick = (id) => {
    redirect(`/notifications/${id}/show`);
    handleClose();
  };

  const notify = useNotify();

  const getNotificationsQty = () => {
    dataProvider
      .getList("notifications", {
        pagination: { perPage: 100, page: 1 },
        sort: { field: "id", order: "ASC" },
        filter: { seen: false },
      })
      .then(({ total }) => {
        setNotificationsQty(total);
      });
  };

  const handleCheckboxChange = (id, e) => {
    if (selectedNotifications.includes(id)) {
      setSelectedNotifications(selectedNotifications.filter((n) => n !== id));
    } else {
      setSelectedNotifications([...selectedNotifications, id]);
    }
    e.stopPropagation();
  };

  const markSelectedAsRead = async () => {
    try {
      await dataProvider.getManyOneByOne("notifications", {
        ids: selectedNotifications,
      });
      setSelectedNotifications([]);
      refresh();
    } catch (error) {
      notify("Failed to mark notification as read", "error");
    }
  };

  const markAllAsRead = async () => {
    try {
      dataProvider.bulkUpdate("notifications");
      setNotifications([]);
    } catch (error) {
      notify("Failed to mark all notifications as read", "error");
    }
  };

  const selectAllNotifications = () => {
    if (selectedNotifications.length === 0) {
      setSelectedNotifications(
        notifications.map((notification) => notification.id),
      );
    } else setSelectedNotifications([]);
  };

  useInterval(getNotificationsQty, 3000);

  const NotificationActions = () => {
    return (
      notificationsQty > 0 &&
      notifications.filter((n) => n.seen === false).length > 0 && (
        <>
          <Button onClick={markAllAsRead}>Mark All as Read</Button>
          {selectedNotifications.length > 0 && (
            <Button onClick={markSelectedAsRead}>Mark Selected as Read</Button>
          )}
          <Button onClick={selectAllNotifications}>
            {selectedNotifications.length === 0 ? "Select All" : "Unselect All"}
          </Button>
        </>
      )
    );
  };

  return (
    <>
      <Box sx={style.notificationWrap}>
        <Box sx={style.notificationButton} onClick={handleOpen}>
          <img alt="Notification icon" src={notifyIcon}></img>
        </Box>
        {notificationsQty > 0 && (
          <Box sx={style.notificationQty}>
            <Typography sx={style.notificationQtyText}>
              {notificationsQty}
            </Typography>
          </Box>
        )}
        <CustomModal
          open={modalOpen}
          onClose={handleClose}
          title={"Notifications"}
          subTitle={"Updates on providers and merchants activities"}
        >
          <Box sx={{ marginLeft: "20px", marginRight: "20px" }}>
            <Divider sx={style.notificationDivider} />
          </Box>
          <Box sx={style.notificationInnerWrap}>
            <ListContextProvider value={{ data: notifications }}>
              <Box sx={style.notificationList}>
                <NotificationActions />
                <Box
                  sx={{
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Select
                    sx={style.notificationSelect}
                    key="seenSelect"
                    value={filter.seen}
                    label="Seen"
                    defaultValue={false}
                    input={<OutlinedInput sx={{ height: "36px" }} />}
                    onChange={(e) => setFilter({ seen: e.target.value })}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={false}>Unread</MenuItem>
                    <MenuItem value={true}>Seen</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box
                sx={style.notificationBoxWrap}
                ref={scrollContainerRef}
                onScroll={handleScroll}
              >
                {notifications.length > 0 ? (
                  notifications.map((item, index) => {
                    return (
                      <Box
                        key={"item" + item.id + index}
                        sx={{
                          ...style.notificationItem,
                          backgroundColor: selectedNotifications.includes(
                            item.id,
                          )
                            ? "#CFE5FB"
                            : "transparent",
                          "&:hover": { backgroundColor: "#F6F8FA" },
                        }}
                        onClick={() => {
                          handleClick(item.id);
                        }}
                      >
                        <Box sx={style.notificationTitle}>{item.title}</Box>
                        <Box sx={style.notificationText}>
                          <NotificationTypes body={item.body} />
                        </Box>
                        <Box sx={style.notificationDate}>
                          {new Date(item.created_at).toLocaleDateString() +
                            ", " +
                            new Date(item.created_at).toLocaleTimeString()}
                        </Box>
                        {item.seen === false && (
                          <Checkbox
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: "-3px",
                            }}
                            onChange={(e) => handleCheckboxChange(item.id, e)}
                            checked={selectedNotifications.includes(item.id)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        )}
                      </Box>
                    );
                  })
                ) : (
                  <Box sx={style.noNotificationsBox}>
                    Currently there are no Notifications
                  </Box>
                )}
              </Box>
            </ListContextProvider>
          </Box>
        </CustomModal>
      </Box>
    </>
  );
};

export default Notification;
