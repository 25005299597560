import React from "react";
import {
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import SafeJsonField from "../../components/SafeJsonField";
const SupplierShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="config.optional_fields" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <TextField source="service" />
      <SafeJsonField source="config" />
      <TextField source="deleted_at" />
      <NumberField source="integrations_count" />
    </SimpleShowLayout>
  </Show>
);
export default SupplierShow;
