import { fetchUtils } from "react-admin";
import { convertPermissionTable } from "./permissionProvider";
const httpClient = fetchUtils.fetchJson;

const saveToken = (token, role) => {
  localStorage.setItem("token", token);
  localStorage.setItem("role", role);
  const authEvent = new CustomEvent("auth:login", {
    detail: { token, role },
    bubbles: true,
    cancelable: true,
  });
  document.dispatchEvent(authEvent);
};

const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  const authEvent = new CustomEvent("auth:logout", {
    detail: {},
    bubbles: true,
    cancelable: true,
  });
  document.dispatchEvent(authEvent);
};

const authProvider = ({ csrfToken }) => ({
  login: (params) => {
    const { username, password } = params;
    return httpClient("/api/login", {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      }),
    }).then(async ({ json: { role, token } }) => {
      saveToken(token, role);
    });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      removeToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    new Promise((resolve, reject) => {
      if (!localStorage.getItem("token")) {
        return reject();
      }

      return httpClient("/api/check_token", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
          Authorization: localStorage.getItem("token"),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return resolve();
          } else {
            removeToken();
            return reject();
          }
        })
        .catch(() => {
          removeToken();
          return reject();
        });
    }),
  logout: () => {
    removeToken();
    return Promise.resolve();
  },

  getIdentity: () =>
    httpClient("/api/identity", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
        Authorization: localStorage.getItem("token"),
      }),
    })
      .then(
        ({
          json: {
            user: { type, email, id, timezone },
          },
        }) => {
          return Promise.resolve({ id, email, type, timezone });
        },
      )
      .catch((error) => {
        return Promise.reject(error);
      }),

  getPermissions: () =>
    httpClient("/api/permissions", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
        Authorization: localStorage.getItem("token"),
      }),
    })
      .then(({ json: { permissions } }) => {
        const perms = convertPermissionTable(permissions);
        return Promise.resolve(perms);
      })
      .catch((error) => {
        return Promise.reject(error);
      }),
});
export default authProvider;
