import * as React from "react";
import {
  BooleanInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  AutocompleteInput,
  NumberInput,
  minValue,
  ArrayInput,
  SimpleFormIterator,
  maxLength,
} from "react-admin";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";

import IntegrationProductShow from "./Show";
import InDrawer from "../../hocs/InDrawer";
import { VatTypeInput } from "../../components";
import { validateUniqueCurrency } from "./shared";

const IntegrationProductEdit = () => {
  return (
    <Edit
      title=" "
      actions={<></>}
      mutationMode="pessimistic"
      undoable={false}
      redirect="show"
    >
      <SimpleForm
        toolbar={<CustomEditToolbar recordName={"integration_products"} />}
      >
        <TextInput
          fullWidth
          source="ean"
          validate={[required(), maxLength(128)]}
        />
        <BooleanInput source="vat" />
        <TextInput fullWidth source="countries" />
        <ReferenceInput source="integration_id" reference="integrations">
          <SelectInput fullWidth optionText="name" validate={[required()]} />
        </ReferenceInput>
        <BooleanInput source="available" />
        <VatTypeInput validate={[required()]} />
        <ReferenceInput source="product_id" reference="products">
          <AutocompleteInput
            fullWidth
            optionText="sku"
            optionValue="id"
            filterToQuery={(sku) => ({ sku })}
          />
        </ReferenceInput>
        <ArrayInput
          source="prices_array"
          fullWidth
          label="Prices"
          validate={validateUniqueCurrency}
        >
          <SimpleFormIterator inline>
            <ReferenceInput
              source="currency"
              reference="currencies"
              target="iso_code"
            >
              <AutocompleteInput
                optionText="iso_code"
                optionValue="iso_code"
                validate={[required()]}
              />
            </ReferenceInput>
            <NumberInput source="price" validate={[minValue(0)]} />
            <NumberInput source="price_without_vat" validate={[minValue(0)]} />
            <NumberInput source="vat_amount" validate={[minValue(0)]} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default InDrawer(
  <IntegrationProductShow />,
  <IntegrationProductEdit />,
  "Edit Provider Product",
  "Edit Provider Product details",
);
