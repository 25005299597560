import * as React from "react";
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  minLength,
  maxLength,
  required,
  regex,
  useUnique,
} from "react-admin";
import CurrencyList from "./List";
import InDrawer from "../../hocs/InDrawer";

const CurrencyCreate = () => {
  const unique = useUnique();
  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <TextInput
          fullWidth
          source="iso_code"
          validate={[
            required(),
            regex(/^[A-Z]{3}$/, "3 letters uppercase"),
            unique(),
          ]}
        />
        <TextInput
          fullWidth
          source="name"
          validate={[required(), maxLength(60), minLength(3)]}
        />
        <BooleanInput source="default" />
      </SimpleForm>
    </Create>
  );
};

export default InDrawer(
  <CurrencyList />,
  <CurrencyCreate />,
  "Create Currency",
  "Create new Currency",
  "/currencies",
);
