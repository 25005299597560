import PropTypes from "prop-types";
import React from "react";
import {
  useDataProvider,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";

const CurrencyInput = ({ region_id }) => {
  const dataProvider = useDataProvider();
  const [currencyIds, setCurrencyIds] = React.useState([]);
  React.useEffect(() => {
    if (region_id) {
      dataProvider.getOne("regions", { id: region_id }).then(({ data }) => {
        setCurrencyIds(data.currency_ids);
      });
    }
  }, [region_id]);

  if (currencyIds.length === 0) return;

  return (
    <ReferenceInput
      source="currency_id"
      reference="currencies"
      filter={{ "id[]": currencyIds }}
    >
      <SelectInput
        fullWidth
        optionText="name"
        optionValue="id"
        validate={[required()]}
      />
    </ReferenceInput>
  );
};
CurrencyInput.propTypes = {
  region_id: PropTypes.number,
};
export default CurrencyInput;
