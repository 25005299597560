import React from "react";
import {
  List,
  Datagrid,
  DateField,
  NumberField,
  TextInput,
  BooleanInput,
  ShowButton,
} from "react-admin";
import ListFilterValues from "../../components/common/ListFilterValues";
import EditableField from "../../components/Editable/Field";

const regionFilters = [
  <TextInput source="name" key="name" />,
  <BooleanInput source="active" key="active" />,
];

export const RegionList = () => {
  const [active, setActive] = React.useState(false);
  return (
    <List
      filters={regionFilters}
      exporter={false}
      aside={<ListFilterValues setActive={setActive} />}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <NumberField source="id" />

        <EditableField justify={{ justifyContent: "center" }} source="name" />

        <DateField source="created_at" showTime />
        {!active && <DateField source="deleted_at" showTime />}
        <ShowButton />
      </Datagrid>
    </List>
  );
};
export default RegionList;
