import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const AccountBadge = ({ text }) => {
  const pickBackground = () => {
    switch (text) {
      case "Accounts::SuperAdmin":
        return "#C2EFFF";
      case "Accounts::MerchantAdmin":
        return "#CBF5E5";
      case "Accounts::RegionalAdmin":
        return "#F5E5E5";
      default:
        return "#FFFFFF";
    }
  };

  const pickText = () => {
    switch (text) {
      case "Accounts::SuperAdmin":
        return "Super Admin";
      case "Accounts::MerchantAdmin":
        return "Merchant Admin";
      case "Accounts::RegionalAdmin":
        return "Regional Admin";
      default:
        return text;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 4.5,
        backgroundColor: pickBackground(),
        padding: "2px 10px 2px 8px",
        width: "fit-content",
        height: "fit-content",
      }}
    >
      <Box sx={{ fontSize: "12px" }}>{pickText()}</Box>
    </Box>
  );
};

AccountBadge.propTypes = {
  text: PropTypes.string,
};

export default AccountBadge;
