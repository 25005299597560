import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { statusColorMap, statusBackgroundMap } from "./statusDefs";

const StatusBadge = ({ text }) => {
  const pickColor = () => {
    return text ? statusColorMap[text.toLowerCase()] : "black";
  };

  const pickBackground = () => {
    return text ? statusBackgroundMap[text.toLowerCase()] : "#FFFFFF";
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 4.5,
        backgroundColor: pickBackground(),
        padding: "2px 10px 2px 8px",
        width: "fit-content",
        height: "fit-content",
      }}
    >
      <Box sx={{ fontSize: "12px", color: pickColor() }}>{text && text}</Box>
    </Box>
  );
};

StatusBadge.propTypes = {
  text: PropTypes.string,
};

export default StatusBadge;
