import React from "react";
import {
  DateField,
  Show,
  TabbedShowLayout,
  NumberField,
  TextField,
} from "react-admin";
import VersionList from "../../components/Version/List";
const TagShow = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="summary">
        <NumberField source="id" />
        <TextField source="name" />
        <NumberField source="tag_items_count" />
        <DateField source="deleted_at" showTime />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="history">
        <VersionList item_type="Tag" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
export default TagShow;
