import * as React from "react";
import { TextField, useRecordContext } from "react-admin";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { useNotify, useTranslate } from "react-admin";
import { Box, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

export const TextFieldWithCopy = ({ source, sx, sort }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const record = useRecordContext();
  const copyToClipboard = async (e, textToCopy) => {
    e.preventDefault();
    e.stopPropagation();

    if (navigator.clipboard && window.isSecureContext && textToCopy) {
      await navigator.clipboard.writeText(textToCopy);
      notify(translate("copied", { _: "Copied to clipboard" }));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;

      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();
      notify(translate("copied", { _: "Copied to clipboard" }));
      try {
        document.execCommand("copy");
      } finally {
        textArea.remove();
      }
    }
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <TextField
        sx={{ ...sx }}
        record={record}
        source={source}
        sortable={sort}
      />
      <Tooltip title="Copy to clipboard" placement="top">
        <Box sx={{ cursor: "pointer" }}>
          <CopyAllIcon onClick={(e) => copyToClipboard(e, record[source])} />
        </Box>
      </Tooltip>
    </Box>
  );
};

TextFieldWithCopy.propTypes = {
  source: PropTypes.string,
  sx: PropTypes.object,
  sort: PropTypes.bool,
};
