export const style = {
  notificationWrap: {
    cursor: "pointer",
    position: "absolute",
    right: 30,
    top: 30,
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
  },
  notificationButton: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationQty: {
    position: "absolute",
    right: "-5px",
    top: "-5px",
    background: "#FF7979",
    borderRadius: "50%",
    minWidth: "16px",
    minHeight: "16px",
    padding: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  notificationQtyText: {
    fontSize: "10px !important",
    fontWeight: "bold",
    fontFamily: "inter !important",
    color: "#fff",
    textAlign: "center",
    lineHeight: "15px",
  },
  notificationDivider: {
    borderStyle: "dashed",
    width: "100%",
    marginTop: "20px",
  },
  notificationInnerWrap: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    fontFamily: "inter",
    overflowY: "visible",
  },
  notificationList: {
    paddingLeft: "10px",
    position: "sticky",
    background: "white",
    top: 0,
    zIndex: 100,
  },
  notificationSelect: {
    maxHeight: "36px",
    ".MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
  },
  notificationBoxWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    overflow: "scroll",
    maxHeight: "100vh",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
    },
    scrollbarColor: "gray transparent",
    scrollbarWidth: "thin",
    msOverflowStyle: "none",
    WebkitOverflowScrolling: "touch",
    padding: "0px",
    paddingBottom: "164px",
    overflowY: "visible",
  },
  notificationItem: {
    position: "relative",
    border: "1px solid #DFE1E6",
    borderRadius: "10px",
    padding: "20px",
    width: "435px",
    cursor: "pointer",
  },
  notificationTitle: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "20px",
    paddingTop: "10px",
    marginBottom: "6px",
  },
  notificationText: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  notificationDate: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  noNotificationsBox: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  deteteButton: {
    buttonWrapper: {
      width: "86px",
      height: "35px",
      borderRadius: "6px",
      color: "rgb(211, 47, 47)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "white",
      margin: "0px",
      textTransform: "none",
      lineHeight: "1.5",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
      boxShadow:
        " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
    },
    buttonActivate: {
      width: "86px",
      height: "35px",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "white",
      margin: "0px",
      textTransform: "none",
      lineHeight: "1.5",
      boxShadow:
        " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
    },
  },
};
