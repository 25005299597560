import React from "react";
import {
  Datagrid,
  DateField,
  List,
  FunctionField,
  NumberField,
  SelectField,
  SimpleShowLayout,
  CreateButton,
  TopToolbar,
  ShowButton,
} from "react-admin";

import SafeJsonField from "../../components/SafeJsonField";
import uploadTypes from "./uploadTypes";
import UploadsStatus from "../../components/Badges/UploadsStatus";

const UploadShow = () => (
  <SimpleShowLayout>
    <SafeJsonField source="check_results" />
  </SimpleShowLayout>
);
const ListActions = () => {
  return (
    <TopToolbar>
      <CreateButton
        label="Skus"
        state={{ record: { type: "Uploads::SkuMapping" } }}
      />
      <CreateButton
        label="Merchants"
        state={{ record: { type: "Uploads::MerchantProducts" } }}
      />
      <CreateButton
        label="Providers"
        state={{ record: { type: "Uploads::SupplierProducts" } }}
      />
      <CreateButton
        label="Images"
        state={{ record: { type: "Uploads::BulkImageUpload" } }}
      />
    </TopToolbar>
  );
};
const UploadList = () => (
  <List
    exporter={false}
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid rowClick="show" expand={<UploadShow />} bulkActionButtons={false}>
      <NumberField source="id" />
      <FunctionField
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        source="status"
        render={(upload) => <UploadsStatus text={upload.status} />}
      />
      <SelectField source="type" choices={uploadTypes} />
      <DateField source="appliance_time" showTime />
      <DateField source="created_at" />
      <ShowButton />
    </Datagrid>
  </List>
);
export default UploadList;
