import polyglotI18nProvider from "ra-i18n-polyglot";
import en from "ra-language-english";

const messages = (dataProvider, locale) => {
  if (locale === "en" || locale === null) {
    return en;
  }

  return dataProvider.get("translations/all", { locale });
};

export default (dataProvider, locales) =>
  polyglotI18nProvider(
    (locale) => messages(dataProvider, locale),
    "en", // default locale
    { allowMissing: true },
    locales,
  );
