export const style = {
  listItem: {
    padding: "8px",
    cursor: "pointer",
    borderRadius: "4px",
    "&:hover": {
      background: "#F9FAFC",
    },
  },
  statusDropdown: {
    position: "absolute",
    top: "calc(100% + 8px)",
    left: "50%",
    transform: "translateX(-50%)",
    width: "250px",
    background: "#FFF",
    borderRadius: "8px",
    boxShadow:
      "0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
    zIndex: 9999,
    padding: "12px",
    boxSizing: "border-box",
  },
  statusButton: {
    display: "flex",
    cursor: "pointer",
    position: "relative",
    width: "200px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    height: "32px",
    color: "#666D80",
    gap: "4px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
  },
  merchantDropdown: {
    position: "absolute",
    top: "calc(100% + 8px)",
    left: "50%",
    transform: "translateX(-50%)",
    width: "250px",
    background: "#FFF",
    borderRadius: "8px",
    boxShadow:
      "0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
    zIndex: 9999,
    padding: "12px",
    boxSizing: "border-box",
    maxHeight: "200px",
    overflowY: "auto",
  },
  merchantPicker: {
    display: "flex",
    cursor: "pointer",
    position: "relative",
    width: "200px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    height: "32px",
    color: "#666D80",
    gap: "4px",
    borderRadius: "8px",
    background: "var(--Background-White, #FFF)",
    boxShadow:
      "0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
  },
  merchantTitle: {
    width: "160px",
    height: "22px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  currencyButton: {
    display: "flex",
    cursor: "pointer",
    position: "relative",
    width: "200px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    height: "32px",
    color: "#666D80",
    gap: "4px",
    borderRadius: "8px",
    background: "var(--Background-White, #FFF)",
    boxShadow:
      "0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
  },
  currencyPicker: {
    position: "absolute",
    top: "calc(100% + 8px)",
    left: "50%",
    transform: "translateX(-50%)",
    width: "250px",
    background: "#FFF",
    borderRadius: "8px",
    boxShadow:
      "0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
    zIndex: 9999,
    padding: "12px",
    boxSizing: "border-box",
  },
  filterCalendar: {
    display: "flex",
    cursor: "pointer",
    position: "relative",
    width: "200px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
    height: "32px",
    color: "#666D80",
    gap: "4px",
    borderRadius: "8px",
    background: "var(--Background-White, #FFF)",
    boxShadow:
      "0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
    border: "none",
  },
  calendarDropdown: {
    position: "absolute",
    top: "calc(100% + 8px)",
    left: "50%",
    transform: "translateX(-50%)",
    width: "250px",
    background: "#FFF",
    borderRadius: "8px",
    boxShadow:
      "0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
    zIndex: 9999,
    padding: "12px",
    boxSizing: "border-box",
  },
  datePicker: {
    marginBottom: "12px",
    width: "100%",
    padding: "8px",
    borderRadius: "8px",
    boxSizing: "border-box",
    fontSize: "16px",
    fontFamily: "inherit",
    position: "absolute",
    top: 0,
    left: 0,
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "6px",
    color: "transparent",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    caretColor: "transparent",
    appearance: "none",
    cursor: "pointer",
    visibility: "hidden",
  },
  resetButton: {
    width: "100%",
    marginTop: "12px",
    padding: "8px",
    borderRadius: "8px",
    background: "#3f51b5",
    color: "#FFF",
    border: "none",
    cursor: "pointer",
    outline: "none",
  },
  merchantInfo: {
    "&:hover": {
      border: "1px solid rgb(197, 211, 225)",
    },
    borderRadius: "6px",
    height: "32px",
    width: "200px",
    padding: "6px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    background:
      "var(--Form-Button-Secondary-Normal, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #FFF)",
    boxShadow:
      "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
  },
  merchantInfoWrap: {
    background: "rgb(244, 239, 239)",
    marginTop: "-20px",
    marginLeft: "-20px",
    marginRight: "-20px",
    padding: "10px",
    borderRadius: "6px",
  },
  merchantBalance: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    mt: "20px",
  },
};
