import * as React from "react";
import { useDataProvider, TextInput, required } from "react-admin";
import PropTypes from "prop-types";

const CustomProviderInputs = ({ supplier_id }) => {
  if (!supplier_id) return null;
  const [config, setConfig] = React.useState(null);
  const dataProvider = useDataProvider();
  React.useEffect(() => {
    dataProvider
      .getOne("suppliers", { id: supplier_id })
      .then(({ data: { config } }) => {
        setConfig(config);
      });
  }, [supplier_id]);
  if (!config) return null;

  return (
    <>
      {config.required_fields.map((field) => (
        <TextInput
          fullWidth
          source={`config.${field}`}
          key={field}
          label={field}
          validate={[required()]}
        />
      ))}
      {config.optional_fields.map((field) => (
        <TextInput
          fullWidth
          source={`config.${field}`}
          key={field}
          label={field}
        />
      ))}
    </>
  );
};

CustomProviderInputs.propTypes = {
  supplier_id: PropTypes.number,
};
export default CustomProviderInputs;
