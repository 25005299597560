import React from "react";
import {
  TextField,
  DateField,
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  usePermissions,
  FunctionField,
} from "react-admin";

import PropTypes from "prop-types";
import { can } from "../../../shared/permissionProvider";
import ObjectChanges from "./ObjectChanges";

const VersionList = ({ item_type, ...props }) => {
  const { permissions } = usePermissions();
  if (!can("versions", "list", permissions)) {
    return <>You don&apos;t have access to this page</>;
  }
  return (
    <ReferenceManyField
      reference="versions"
      target="item_id"
      filter={{ item_type }}
      sort={{ field: "created_at", order: "DESC" }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={false}
        rowSx={() => {
          return { verticalAlign: "baseline" };
        }}
      >
        <TextField source="id" />
        <TextField source="event" />
        <FunctionField
          source="whodunnit"
          label="Whodunnit"
          render={(record) => {
            return record?.whodunnit ? (
              <ReferenceField source="whodunnit" reference="accounts">
                <TextField source="email" />
              </ReferenceField>
            ) : (
              <span>System</span>
            );
          }}
        />

        <ObjectChanges source="object_changes" sortable={false} />
        <DateField source="created_at" showTime />
      </Datagrid>
    </ReferenceManyField>
  );
};

VersionList.propTypes = {
  item_type: PropTypes.string.isRequired,
};
export default VersionList;
