import React from "react";
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonSmall from "./ButtonSmall";
import closeIcon from "../../../assets/icons/closeIcon.svg";
const drawerWidth = 440;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    minWidth: drawerWidth,
    height: "100vh",
    overflowY: "visible",
    scrollbarColor: "transparent transparent",
    scrollbarWidth: "thin",
    msOverflowStyle: "none",
    WebkitOverflowScrolling: "touch",
  },
  notifPaper: {
    backgroundColor: theme.palette.background.paper,
    minWidth: drawerWidth,
    height: "100vh",
    overflowY: "hidden",
    scrollbarColor: "transparent transparent",
    scrollbarWidth: "thin",
    msOverflowStyle: "none",
    WebkitOverflowScrolling: "touch",
  },
}));

const CustomModal = ({ open, onClose, children, title, subTitle, to }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={
        onClose
          ? onClose
          : to
            ? () => navigate(to)
            : () => navigate(location.pathname + "/show")
      }
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        fontFamily: "Inter",
        "& .MuiDrawer-paperAnchorRight": {
          right: "20px",
          height: "calc(100vh - 70px)",
          width: "490px",
          top: "20px",
          // overflowY: "hidden",
          borderRadius: "10px",
          padding: "20px",
        },
      }}
    >
      <Box sx={{ position: "absolute", right: "20px", top: "20px" }}>
        <ButtonSmall
          icon={closeIcon}
          action={
            onClose
              ? onClose
              : to
                ? () => navigate(to)
                : () => navigate(location.pathname + "/show")
          }
        ></ButtonSmall>
      </Box>
      <Typography
        sx={{ fontSize: "20px !important", fontWeight: "500 !important" }}
        gutterBottom
      >
        {title && title}
      </Typography>
      <Typography
        sx={{
          color: "#818898",
          fontWeight: "500",
          fontSize: "16px !important",
        }}
        variant="h6"
        gutterBottom
      >
        {subTitle && subTitle}
      </Typography>
      <Box
        className={
          title === "Notifications" ? classes.notifPaper : classes.paper
        }
      >
        {children}
      </Box>
    </Drawer>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  to: PropTypes.string,
};
export default CustomModal;
