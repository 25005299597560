import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { snakeCase } from "lodash";
import pluralize from "pluralize";

const PolymorphicLink = ({ type, id }) => {
  const resource_type = pluralize(snakeCase(type));

  return (
    <Link to={`/${resource_type}/${id}/show`}>
      {type}#{id}
    </Link>
  );
};

PolymorphicLink.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
export default PolymorphicLink;
