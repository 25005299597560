import React from "react";
import { Datagrid, List, ShowButton, TextField } from "react-admin";
import EditableField from "../../components/Editable/Field";

export const ConfigList = () => (
  <List>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <EditableField source="key" justify={{ justifyContent: "center" }} />
      <ShowButton />
    </Datagrid>
  </List>
);
export default ConfigList;
