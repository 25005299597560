import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import {
  Menu,
  Sidebar,
  useResourceDefinitions,
  MenuItemLink,
} from "react-admin";
import playheraIcon from "../../../assets/icons/playheraIcon.svg";
import playheraText from "../../../assets/icons/playheraText.svg";
import UserSettings from "./UserSettings";
import ListIcon from "@mui/icons-material/List";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslate } from "react-admin";
import { SettingsIcon } from "../../../assets/icons/SidebarIcons";
import Tooltip from "@mui/material/Tooltip";

const MerchantAdminSidebar = ({ dashboard, collapsed }) => {
  const translate = useTranslate();

  const resourcesDefinitions = useResourceDefinitions();

  const resources = Object.keys(resourcesDefinitions)
    .map((name) => resourcesDefinitions[name])
    .filter((r) => r.hasList);

  const getSpecificResourceIcon = (resourceName) => {
    const resource = resources.find(
      (resource) => resource.name === resourceName,
    );
    if (!resource) return;

    return resource.icon ? <resource.icon /> : <ListIcon />;
  };

  const SideBarItem = ({ label, resource }) => {
    return (
      <>
        <Tooltip title={label} placement="top-start">
          <MenuItemLink
            to={"/" + resource}
            primaryText={label}
            leftIcon={getSpecificResourceIcon(resource)}
            sx={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              marginLeft: collapsed ? "3px" : "10px",
            }}
          />
        </Tooltip>
      </>
    );
  };

  const menuItems = {
    menu: [
      { resource: "orders", label: translate("Orders", { _: "Orders" }) },
      {
        resource: "merchant_products",
        label: translate("Products", { _: "Products" }),
      },
      {
        resource: "contracts",
        label: translate("Contracts", { _: "Contracts" }),
      },
      {
        resource: "categories",
        label: translate("Categories", { _: "Categories" }),
      },
      {
        resource: "currencies",
        label: translate("Currencies", { _: "Currencies" }),
      },
      {
        resource: "merchants",
        label: translate("Merchants", { _: "Merchants" }),
      },
      {
        resource: "api_tokens",
        label: translate("api_tokens", { _: "Api Tokens" }),
      },
    ],
  };

  return (
    <Sidebar>
      <Box
        sx={{
          display: "flex",
          paddingTop: "10px",
          alignItems: "center",
          top: "10px",
          marginLeft: collapsed ? "15px" : "26px",
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            background: "linear-gradient(180deg, #E412B8 -9%, #151C50 110.26%)",
            boxShadow:
              "0px -2px 4px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 0px 1px rgba(0, 0, 0, 0.10) inset",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "30px",
            width: "30px",
            marginRight: "10px",
          }}
        >
          <Box component="img" alt="playhera logo" src={playheraIcon}></Box>
        </Box>
        {!collapsed && (
          <Box
            component="img"
            alt="playhera text"
            sx={{ height: "13px" }}
            src={playheraText}
          ></Box>
        )}
      </Box>

      <Menu
        hasDashboard={!!dashboard}
        sx={{
          display: "flex",
          backgroundColor: "transparent",
          gap: "5px",
          paddingTop: "20px",
          flexDirection: "column",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        {" "}
        <MenuItemLink
          to="/"
          primaryText={translate("dashboard", { _: "Dashboard" })}
          leftIcon={<HomeIcon />}
          sx={{
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: collapsed ? "3px" : "10px",
          }}
        />
        {menuItems.menu.map((item) => (
          <SideBarItem
            key={item.resource}
            label={item.label}
            resource={item.resource}
          />
        ))}
        <MenuItemLink
          to="/settings"
          primaryText={translate("settings", { _: "Settings" })}
          leftIcon={<SettingsIcon />}
          sx={{
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: collapsed ? "5px" : "10px",
          }}
        />
      </Menu>
      <UserSettings collapsed={collapsed} />
    </Sidebar>
  );
};

MerchantAdminSidebar.propTypes = {
  dashboard: PropTypes.element,
  label: PropTypes.string,
  resource: PropTypes.string,
  collapsed: PropTypes.bool,
};

export default MerchantAdminSidebar;
