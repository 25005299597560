import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  usePermissions,
  TopToolbar,
  FilterButton,
  CreateButton,
  AutocompleteInput,
  ShowButton,
  SortButton,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import ExportButton from "../../components/Export/ExportButton";
import PivotButton from "../../components/Export/PivotButton";
import ListFilterValues from "../../components/common/ListFilterValues";
import BoolColored from "../../components/common/BoolColored";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import SmallPriceList from "../../components/Prices/SmallList";
import { useState } from "react";
import { IconButton } from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import CardList from "./CardView";
import PropTypes from "prop-types";
import EditableField from "../../components/Editable/Field";
import { Box } from "@material-ui/core";
import ExtendedField from "../../components/Editable/ExtendedField";
import { style } from "./style";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
import { VatTypeField, VatTypeFilterInput } from "../../components";

const IntegrationProductFilter = [
  <TextInput source="ean" key="ean" />,
  <BooleanInput source="vat" key="vat" />,
  <BooleanInput source="available" key="available" />,
  <ReferenceInput
    source="merchant_id"
    reference="merchants"
    label="Merchant"
    key={"merchant_id"}
  >
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput
    source="integration_id"
    reference="integrations"
    key="integration_id"
    filter={{ active: true }}
  >
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <SelectInput
    source="unmapped"
    label="Mapping"
    choices={[
      { id: true, name: "Unmapped" },
      { id: false, name: "Mapped" },
    ]}
    key="unmapped"
  />,
  <VatTypeFilterInput key="vat_type" source="vat_type" />,
  <ReferenceInput source="product_id" reference="products" key="product_id">
    <AutocompleteInput
      optionText="sku"
      optionValue="id"
      fullWidth
      filterToQuery={(sku) => ({ sku })}
    />
  </ReferenceInput>,
  <BooleanInput source="active" key="active" defaultValue={true} />,
];
const ListActions = ({ toggleView, isCardView }) => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      <IconButton onClick={toggleView}>
        {isCardView ? <ViewListIcon /> : <ViewModuleIcon />}
      </IconButton>
      {isCardView && (
        <SortButton
          sx={style.sortButton}
          fields={[
            "available",
            "product_id",
            "countries",
            "ean",
            "vat",
            "integration_id",
            "active",
            "created_at",
            "deleted_at",
            "updated_at",
          ]}
          label="sort"
        />
      )}

      <FilterButton />
      {can("integration_products", "create", permissions) && <CreateButton />}
      <ExportButton />
      <PivotButton />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  toggleView: PropTypes.func,
  isCardView: PropTypes.bool,
};

const IntegrationProductList = () => {
  const [active, setActive] = React.useState(true);
  const [isCardView, setIsCardView] = useState(false);
  const toggleView = () => {
    setIsCardView(!isCardView);
  };
  return (
    <List
      filters={IntegrationProductFilter}
      exporter={false}
      actions={<ListActions toggleView={toggleView} isCardView={isCardView} />}
      aside={<ListFilterValues setActive={setActive} />}
    >
      {isCardView ? (
        <CardList />
      ) : (
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="id" />
          <EditableField
            truncate={{ ...style.truncate, minWidth: "130px" }}
            source="ean"
            justify={{ justifyContent: "center" }}
            withCopy={true}
          />
          <TextField source="name_by_supplier" sortable={false} emptyText="-" />
          <BoolColored source="active" key="active" label="Active" />
          <EditableBooleanField source="available" />
          <EditableBooleanField source="vat" key="vat" label="Vat" />
          <TextField source="countries" emptyText="-" />
          <ExtendedField
            source="integration_id"
            reference="integrations"
            resource={"integration_products"}
            optionText="name"
            optionValue="id"
          />
          <VatTypeField label="Vat Type" />
          <ExtendedField
            source="product_id"
            reference="products"
            resource={"integration_products"}
            optionText="name"
            optionValue="id"
            sx={style.truncate}
          />
          <SmallPriceList filter={{ priceable_type: "IntegrationProduct" }} />
          <DateField source="created_at" />
          <DateField source="updated_at" />
          {!active && <TextField source="deleted_at" emptyText="-" />}
          <Box sx={{ display: "flex", gap: "10px" }}>
            <ShowButton sx={{ height: "35px" }} />
            <CustomDeleteButton recordName={"integration_products"} />
          </Box>
        </Datagrid>
      )}
    </List>
  );
};
export default IntegrationProductList;
