import React from "react";
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  UrlField,
  FunctionField,
  DateTimeInput,
  NumberInput,
} from "react-admin";
import { PolymorphicLink } from "../../components";
const Filters = [
  <ReferenceInput
    key="merchant_id"
    source="merchant_id"
    reference="merchants"
    label="Merchant"
  />,
  <DateTimeInput source="before" label="Before" key="before" />,
  <DateTimeInput source="after" label="After" key="after" />,
  <NumberInput source="status" label="Status" key="status" />,
];

export const WHLList = () => (
  <List filters={Filters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="merchant_id" reference="merchants" />
      <FunctionField
        label="Hookable"
        render={(record) => (
          <PolymorphicLink
            id={record.hookable_id}
            type={record.hookable_type}
          />
        )}
      />

      <UrlField source="url" />
      <NumberField source="status" />
      <TextField source="error_message" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);

export default WHLList;
