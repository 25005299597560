import React, { useState, useEffect, useRef } from "react";
import { Box, List } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import { style } from "./style";
const FilterMerchant = ({
  setMerchant,
  setCurrencyId,
  ordersData,
  merchant,
  currentMenuOpen,
  setCurrentMenuOpen,
}) => {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const dropdownRef = useRef();
  const buttonRef = useRef();
  const [title, setTitle] = useState(
    <Box>{selectedMerchant ? selectedMerchant : "Select Merchant"}</Box>,
  );

  const merchantsList = ordersData?.merchants || [];

  useEffect(() => {
    const newMerchant = merchantsList.find(
      (item) => parseInt(item.id) === parseInt(merchant),
    );

    handleMerchantSelect(newMerchant?.id || "");
  }, [merchant]);

  const handleMerchantSelect = (merchantId) => {
    const selectedOption = merchantsList.find(
      (merchant) => parseInt(merchant.id) === parseInt(merchantId),
    );

    if (selectedOption) {
      setMerchant(selectedOption.id);
      setCurrencyId(selectedOption.currency_id);
      setSelectedMerchant(selectedOption.id);

      setTitle(
        <Box sx={style.merchantTitle}>
          {selectedOption?.name || "Select Merchant"}
        </Box>,
      );
    } else {
      setMerchant(null);
      setSelectedMerchant("");
      setTitle(<Box>Select Merchant</Box>);
    }
    setCurrentMenuOpen(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCurrentMenuOpen(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToggle = (event) => {
    event.stopPropagation();
    setCurrentMenuOpen("merchant");
  };

  return (
    <Box ref={buttonRef} sx={style.merchantPicker} onClick={handleToggle}>
      <Box>{title}</Box>
      <ExpandMoreIcon />

      {currentMenuOpen === "merchant" && (
        <Box ref={dropdownRef} sx={style.merchantDropdown}>
          <List>
            <Box onClick={() => handleMerchantSelect("")}>-</Box>
            {merchantsList.map((option) => (
              <Box
                sx={{
                  ...style.listItem,
                  backgroundColor:
                    option === selectedMerchant ? "#F5F6F8" : "#FFF",
                }}
                key={option.id}
                onClick={() => {
                  handleMerchantSelect(option.id);
                }}
              >
                {capitalize(option.name)}
              </Box>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

FilterMerchant.propTypes = {
  setMerchant: PropTypes.func.isRequired,
  setCurrencyId: PropTypes.func.isRequired,
  ordersData: PropTypes.object.isRequired,
  merchant: PropTypes.number,
  currentMenuOpen: PropTypes.string,
  setCurrentMenuOpen: PropTypes.func,
};

export default FilterMerchant;
