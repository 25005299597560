import React from "react";
import {
  Show,
  TabbedShowLayout,
  DateField,
  ReferenceArrayField,
} from "react-admin";
import VersionList from "../../components/Version/List";
import { EditableField } from "../../components/Editable/Field";

const ShowRegion = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="summary">
        <EditableField source="name" />
        <DateField source="created_at" showTime />
        <DateField source="deleted_at" showTime />
        <ReferenceArrayField
          sx={{ display: "flex", justifyContent: "start" }}
          source="currency_ids"
          reference="currencies"
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="history">
        <VersionList item_type="Region" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default ShowRegion;
