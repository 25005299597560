import React from "react";
import {
  TextField,
  DateField,
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  CreateButton,
  EditButton,
  useRecordContext,
  usePermissions,
} from "react-admin";

import { can } from "../../../shared/permissionProvider";

import PropTypes from "prop-types";

const CreateRelatedTranslationButton = ({
  translatableType,
  translatableId,
}) => (
  <CreateButton
    resource="translations"
    state={{ record: { translatableType, translatableId } }}
  />
);

CreateRelatedTranslationButton.propTypes = {
  translatableType: PropTypes.string.isRequired,
  translatableId: PropTypes.number.isRequired,
};

const TranslationList = ({ translatableType, ...props }) => {
  const { id: translatableId } = useRecordContext();
  const { permissions } = usePermissions();
  if (!can("translations", "list", permissions)) {
    return <>You don&apos;t have access to this page</>;
  }
  return (
    <ReferenceManyField
      reference="translations"
      target="translatable_id"
      filter={{ translatable_type: translatableType }}
      sort={{ field: "locale_id", order: "DESC" }}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ReferenceField source="locale_id" reference="locales">
          <TextField source="name" />
        </ReferenceField>

        <TextField source="key" />
        <TextField source="value" />
        <DateField source="updated_at" showTime />
        {can("translations", "edit", permissions) && <EditButton />}
      </Datagrid>
      {can("translations", "create", permissions) && (
        <CreateRelatedTranslationButton
          translatableType={translatableType}
          translatableId={translatableId}
        />
      )}
    </ReferenceManyField>
  );
};

TranslationList.propTypes = {
  translatableType: PropTypes.string.isRequired,
};
export default TranslationList;
