import React from "react";
import { DateField, Show, TabbedShowLayout } from "react-admin";
import VersionList from "../../components/Version/List";
import EditableField from "../../components/Editable/Field";

const ShowLocale = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="summary">
        <EditableField source="code" />
        <EditableField source="name" />
        <DateField source="created_at" showTime />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="history">
        <VersionList item_type="Region" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default ShowLocale;
