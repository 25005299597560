import React from "react";
import { ArrayField, SingleFieldList } from "react-admin";
import ImageFieldWithRemoval from "./ImageFieldWithRemoval";
import ImageList from "@mui/material/ImageList";
import { Box } from "@material-ui/core";

const ImagesList = () => (
  <ImageList>
    <ArrayField source="images">
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <SingleFieldList linkType={false}>
          <ImageFieldWithRemoval source="url" title="title" />
        </SingleFieldList>
      </Box>
    </ArrayField>
  </ImageList>
);
export default ImagesList;
