import React from "react";

export const CatalogueIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5H8.33333C8.79357 2.5 9.16667 2.8731 9.16667 3.33333V8.33333C9.16667 8.79357 8.79357 9.16667 8.33333 9.16667H3.33333C2.8731 9.16667 2.5 8.79357 2.5 8.33333V3.33333Z"
        fill="currentColor"
      />
      <path
        d="M10.8333 3.33333C10.8333 2.8731 11.2064 2.5 11.6667 2.5H16.6667C17.1269 2.5 17.5 2.8731 17.5 3.33333V8.33333C17.5 8.79357 17.1269 9.16667 16.6667 9.16667H11.6667C11.2064 9.16667 10.8333 8.79357 10.8333 8.33333V3.33333Z"
        fill="currentColor"
      />
      <path
        d="M2.5 11.6667C2.5 11.2064 2.8731 10.8333 3.33333 10.8333H8.33333C8.79357 10.8333 9.16667 11.2064 9.16667 11.6667V16.6667C9.16667 17.1269 8.79357 17.5 8.33333 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V11.6667Z"
        fill="currentColor"
      />
      <path
        d="M16.5237 11.8101C15.2219 10.5084 13.1114 10.5084 11.8096 11.8101C10.5079 13.1119 10.5079 15.2224 11.8096 16.5242C12.9079 17.6225 14.5819 17.7941 15.8602 17.0392L16.9107 18.0898C17.2362 18.4152 17.7638 18.4152 18.0893 18.0898C18.4147 17.7643 18.4147 17.2367 18.0893 16.9113L17.0387 15.8607C17.7936 14.5824 17.622 12.9084 16.5237 11.8101ZM12.9882 12.9887C13.639 12.3378 14.6943 12.3378 15.3452 12.9887C15.9961 13.6395 15.9961 14.6948 15.3452 15.3457C14.6943 15.9966 13.639 15.9966 12.9882 15.3457C12.3373 14.6948 12.3373 13.6395 12.9882 12.9887Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IntegrationsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 1.66669C8.27444 1.66669 6.87533 3.0658 6.87533 4.79169C6.87533 6.51758 8.27444 7.91669 10.0003 7.91669C11.7262 7.91669 13.1253 6.51758 13.1253 4.79169C13.1253 3.0658 11.7262 1.66669 10.0003 1.66669Z"
        fill="currentColor"
      />
      <path
        d="M4.79199 6.87502C3.0661 6.87502 1.66699 8.27413 1.66699 10C1.66699 11.7259 3.0661 13.125 4.79199 13.125C6.51788 13.125 7.91699 11.7259 7.91699 10C7.91699 8.27413 6.51788 6.87502 4.79199 6.87502Z"
        fill="currentColor"
      />
      <path
        d="M15.2087 6.87502C13.4828 6.87502 12.0837 8.27413 12.0837 10C12.0837 11.7259 13.4828 13.125 15.2087 13.125C16.9345 13.125 18.3337 11.7259 18.3337 10C18.3337 8.27413 16.9345 6.87502 15.2087 6.87502Z"
        fill="currentColor"
      />
      <path
        d="M10.0003 12.0834C8.27444 12.0834 6.87533 13.4825 6.87533 15.2084C6.87533 16.9342 8.27444 18.3334 10.0003 18.3334C11.7262 18.3334 13.1253 16.9342 13.1253 15.2084C13.1253 13.4825 11.7262 12.0834 10.0003 12.0834Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const MerchantsIcon = () => {
  return (
    <svg
      width="28"
      height="23"
      viewBox="0 0 20 17"
      fill="cuurentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33333 0.5C0.873096 0.5 0.5 0.873096 0.5 1.33333V14.6667C0.5 15.1269 0.873096 15.5 1.33333 15.5H14.6667C15.1269 15.5 15.5 15.1269 15.5 14.6667V1.33333C15.5 0.873096 15.1269 0.5 14.6667 0.5H1.33333ZM6.33333 4.66667C5.8731 4.66667 5.5 4.29357 5.5 3.83333V2.16667H7.16667V3.83333C7.16667 4.29357 6.79357 4.66667 6.33333 4.66667ZM3.83333 3.83333V2.16667H2.16667V3.83333C2.16667 4.29357 2.53976 4.66667 3 4.66667C3.46024 4.66667 3.83333 4.29357 3.83333 3.83333ZM8.83333 3.83333V2.16667H10.5V3.83333C10.5 4.29357 10.1269 4.66667 9.66667 4.66667C9.20643 4.66667 8.83333 4.29357 8.83333 3.83333ZM12.1667 3.83333V2.16667H13.8333V3.83333C13.8333 4.29357 13.4602 4.66667 13 4.66667C12.5398 4.66667 12.1667 4.29357 12.1667 3.83333ZM5.5 8.83333C5.03976 8.83333 4.66667 9.20643 4.66667 9.66667V13.8333H6.33333V10.5H9.66667V13.8333H11.3333V9.66667C11.3333 9.20643 10.9602 8.83333 10.5 8.83333H5.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const SettingsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 6.24994C2.5 5.94887 2.66239 5.67122 2.92479 5.52362L9.59145 1.77369C9.84513 1.631 10.1549 1.631 10.4085 1.77369L17.0752 5.52365C17.3376 5.67124 17.5 5.9489 17.5 6.24996V13.75C17.5 14.051 17.3376 14.3287 17.0752 14.4763L10.4085 18.2262C10.1549 18.3689 9.84514 18.3689 9.59147 18.2262L2.92481 14.4765C2.6624 14.3289 2.5 14.0513 2.5 13.7502V6.24994ZM7.08336 10C7.08336 8.38917 8.38919 7.08334 10 7.08334C11.6109 7.08334 12.9167 8.38917 12.9167 10C12.9167 11.6108 11.6109 12.9167 10 12.9167C8.38919 12.9167 7.08336 11.6108 7.08336 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const OrdersIcon = () => {
  return (
    <svg
      width="28"
      height="23"
      viewBox="0 0 20 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.916687 0.916707C0.916687 0.45647 1.28978 0.083374 1.75002 0.083374H14.25C14.7103 0.083374 15.0834 0.45647 15.0834 0.916707V15.0834C15.0834 15.5436 14.7103 15.9167 14.25 15.9167H1.75002C1.28978 15.9167 0.916687 15.5436 0.916687 15.0834V0.916707ZM4.66669 4.66671C4.66669 4.20647 5.03978 3.83337 5.50002 3.83337H10.5C10.9603 3.83337 11.3334 4.20647 11.3334 4.66671C11.3334 5.12694 10.9603 5.50004 10.5 5.50004H5.50002C5.03978 5.50004 4.66669 5.12694 4.66669 4.66671ZM4.66669 8.00004C4.66669 7.5398 5.03978 7.16671 5.50002 7.16671H8.00002C8.46026 7.16671 8.83335 7.5398 8.83335 8.00004C8.83335 8.46028 8.46026 8.83337 8.00002 8.83337H5.50002C5.03978 8.83337 4.66669 8.46028 4.66669 8.00004Z"
        fill="currentColor"
      />
    </svg>
  );
};
