import React from "react";
import {
  TextField,
  DateField,
  Datagrid,
  ReferenceField,
  List,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  FunctionField,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import StatusBadge from "../../components/Badges/StatusBadge";

const VersionFilters = [
  <TextInput source="item_type" key="item_type" />,
  <TextInput source="item_id" key="item_id" />,
  <ReferenceInput source="whodunnit" reference="accounts" key="account">
    <SelectInput optionText="email" optionValue="id" />
  </ReferenceInput>,
  <DateTimeInput source="before" key="before" />,
  <DateTimeInput source="after" key="after" />,
];
const VersionList = (props) => (
  <List
    filters={VersionFilters}
    sort={{ field: "created_at", order: "DESC" }}
    {...props}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <FunctionField
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        source="event"
        render={(event) => <StatusBadge text={event.event} />}
      />
      <TextField source="item_type" />
      <TextField source="item_id" />
      <ReferenceField source="whodunnit" reference="accounts">
        <TextField source="email" />
      </ReferenceField>
      <JsonField
        source="object_changes"
        sortable={false}
        reactJsonOptions={{
          collapsed: 1,
          collapseStringsAfterLength: 50,
        }}
      />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);

export default VersionList;
