import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
} from "react-admin";

export const LocaleList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <NumberField source="id" />
      <TextField source="code" />
      <TextField source="name" />
      <BooleanField source="default" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
export default LocaleList;
