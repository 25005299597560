import React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  FunctionField,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TopToolbar,
  FilterButton,
  TextInput,
  AutocompleteInput,
  ShowButton,
} from "react-admin";
import { Link } from "react-router-dom";
import ExportButton from "../../components/Export/ExportButton";
import { JsonField } from "react-admin-json-view";
import Box from "@mui/material/Box";
import StatusBadge from "../../components/Badges/StatusBadge";
import { TextFieldWithCopy } from "../../components/Editable/CopyTextField";

const ListActions = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <TopToolbar sx={{ position: "relative" }}>
        <FilterButton />
        <ExportButton />
      </TopToolbar>
    </Box>
  );
};

const filters = [
  <TextInput source="q" label="Search" key="q" />,
  <ReferenceInput
    source="integration_id"
    reference="integrations"
    key="integration_id"
  />,
  <ReferenceInput
    source="order_merchant_product_id"
    reference="order_merchant_products"
    key="order_merchant_product_id"
    label="Merchant Order"
  >
    <AutocompleteInput optionText="id" optionValue="id" />
  </ReferenceInput>,
  <SelectInput
    source="status"
    key="status"
    choices={[
      { id: "ordered", name: "Ordered" },
      { id: "failed", name: "Failed" },
    ]}
  />,
  <ReferenceArrayInput source="product_id" reference="products" key={"sku"}>
    <AutocompleteArrayInput
      fullWidth
      optionValue="id"
      optionText="sku"
      filterToQuery={(sku) => ({ sku })}
    />
  </ReferenceArrayInput>,
  <DateTimeInput source="before" key="before" />,
  <DateTimeInput source="after" key="after" />,
];

export const OIPList = () => (
  <List
    filters={filters}
    sort={{ field: "created_at", order: "DESC" }}
    actions={<ListActions />}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField
        source="integration_product_id"
        reference="integration_products"
        label="Provider"
        sortable={false}
        link={false}
      >
        <ReferenceField source="integration_id" reference="integrations">
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        source="order_merchant_product_id"
        reference="order_merchant_products"
        label="Merchant Order"
        sortable={false}
        link={false}
      >
        <ReferenceField source="order_id" reference="orders" link={false}>
          <FunctionField
            render={(record) => (
              <Link to={`/orders/${record.id}/show`}>{record.id}</Link>
            )}
          />
        </ReferenceField>
      </ReferenceField>

      <ReferenceField
        source="integration_product_id"
        reference="integration_products"
        label="Product"
        sortable={false}
        link={false}
      >
        <ReferenceField source="product_id" reference="products">
          <TextFieldWithCopy source="sku" />
        </ReferenceField>
      </ReferenceField>
      <FunctionField
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        source="status"
        render={(order) => <StatusBadge text={order.status} />}
      />
      <TextField source="cost" />
      <DateField source="created_at" showTime />
      <ReferenceField
        source="order_merchant_product_id"
        reference="order_merchant_products"
      />
      <JsonField source="result" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default OIPList;
