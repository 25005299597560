import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useDataProvider } from "react-admin";
import propTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { style } from "./style";

const MerchantInfo = ({ symbol, pickedMerchant }) => {
  const provider = useDataProvider();
  const [merchant, setMerchant] = useState();
  const [specificMerchant, setSpecificMerchant] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchMerchantData = async () => {
    try {
      setIsLoaded(false);
      const res = await provider.get("merchants");
      setMerchant(res);
      if (pickedMerchant) {
        setSpecificMerchant(res.find((m) => m.id === parseInt(pickedMerchant)));
      }
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchMerchantData();
  }, [pickedMerchant]);

  return (
    <Box sx={style.merchantInfoWrap}>
      <Box sx={{ fontWeight: "500" }}>
        Merchant:{" "}
        {specificMerchant
          ? specificMerchant.name
          : merchant && merchant[0]?.name}{" "}
      </Box>

      <Box sx={style.merchantBalance}>
        <Box>
          Balance:{" "}
          {specificMerchant
            ? specificMerchant.current_balance
            : merchant && merchant[0]?.current_balance}{" "}
          {symbol && symbol}
        </Box>
        <Box sx={style.merchantInfo} onClick={fetchMerchantData}>
          {!isLoaded ? <CircularProgress size={24} /> : "Check Balance"}
        </Box>
      </Box>
    </Box>
  );
};

MerchantInfo.propTypes = {
  symbol: propTypes.string,
  pickedMerchant: propTypes.number,
};

export default MerchantInfo;
