import React, { useEffect, useState } from "react";
import {
  Show,
  TextField,
  DateField,
  NumberField,
  TabbedShowLayout,
  ReferenceField,
  Datagrid,
  ReferenceManyField,
  BooleanField,
  usePermissions,
  BooleanInput,
  useDataProvider,
  ShowButton,
} from "react-admin";
import { VatTypeField } from "../../components/VatTypeInput";
import EditableField from "../../components/Editable/Field";
import VersionList from "../../components/Version/List";
import ImagesList from "../../components/ImageManipulation/ImagesList";
import { can } from "../../../shared/permissionProvider";
import SmallPriceList from "../../components/Prices/SmallList";
import translatableFields from "../../components/Translations/TranslatableFields";
const FilterIntegrationProducts = [
  <BooleanInput source="active" key="active" />,
  <BooleanInput source="available" key="available" />,
];
const IntegrationProductList = () => (
  <ReferenceManyField
    target="product_id"
    reference="integration_products"
    label="integration_products"
    perPage={1000}
    filters={FilterIntegrationProducts}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="ean" />
      <BooleanField source="vat" />
      <TextField source="countries" />
      <ReferenceField source="integration_id" reference="integrations">
        <TextField source="name" />
      </ReferenceField>
      <SmallPriceList
        filter={{ priceable_type: "IntegrationProduct" }}
        label="Prices"
      />
      <BooleanField source="active" />
      <BooleanField source="available" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <TextField source="deleted_at" />
      <ShowButton />
    </Datagrid>
  </ReferenceManyField>
);
const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/^[a-z]/, (letter) => letter.toUpperCase())
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", " ").replace("_", " "),
    );
export const ProductShow = () => {
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();

  const [translatableKeys, setTranslatableKeys] = useState([]);
  const fields = translatableFields["products"] || {};
  useEffect(() => {
    dataProvider.getList("locales").then(({ data }) => {
      const result = [];
      data.map((locale) => {
        fields.map((field) => {
          result.push({
            path: `translations.${locale.code}.${field}`,
            label: `${snakeToCamel(field)} (${locale.name})`,
          });
        });
      });
      setTranslatableKeys(result);
    });
  }, []);
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <NumberField source="id" />
          <ReferenceField source="region_id" reference="regions">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="category_id" reference="categories">
            <TextField source="name" />
          </ReferenceField>
          <EditableField source="sku" />
          <EditableField source="countries" />
          <EditableField source="denomination" />
          <NumberField source="integration_products_count" />
          <DateField source="created_at" showTime />
          <DateField source="deleted_at" showTime />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="merchant products">
          <ReferenceManyField
            target="product_id"
            reference="merchant_products"
            perPage={100}
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField source="merchant_id" reference="merchants">
                <TextField source="name" />
              </ReferenceField>
              <EditableField source="price" type={"number"} />
              <EditableField source="price_without_vat" type={"number"} />
              <NumberField source="vat_amount" />
              <NumberField source="nominal" />
              <VatTypeField source="vat_type" />
              <DateField source="created_at" showTime />
              <DateField source="updated_at" showTime />
              <DateField source="deleted_at" showTime />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        {can("integration_products", "list", permissions) && (
          <TabbedShowLayout.Tab label="Provider products">
            <IntegrationProductList />
          </TabbedShowLayout.Tab>
        )}
        {can("attachments", "list", permissions) && (
          <TabbedShowLayout.Tab label="Images">
            <ImagesList />
          </TabbedShowLayout.Tab>
        )}
        {can("translations", "list", permissions) && (
          <TabbedShowLayout.Tab label="translations">
            {translatableKeys.map((key) => (
              <EditableField
                key={key.path}
                source={key.path}
                label={key.label}
                type={"textarea"}
              />
            ))}
          </TabbedShowLayout.Tab>
        )}
        {can("versions", "list", permissions) && (
          <TabbedShowLayout.Tab label="History">
            <VersionList item_type="Product" />
          </TabbedShowLayout.Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
export default ProductShow;
