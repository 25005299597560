const skuData = `sku,name,ean Mintroute,ean redeemly,tax_class,category,denomination,countries
PHDC-12-Months-DeezerPremium,12 Months Deezer Premium,,4535,Taxable Goods,Shopping,12 Months,"MENA,US"
PHDC-360Vuz-VIP-12months,360Vuz VIP- 12 months,MINT2,3438,Taxable Goods,Shopping,12 Months,Global
PHDC-360Vuz-VIP-1months,360Vuz VIP- 1 months,MINT1,,,Shopping,1 Month,Global`;
const merchantPriceData = `sku,price,recommended_retail_price,price_without_vat,vat,vat_type,nominal,price_type,profit
PHDC-12-Months-DeezerPremium,57,58,56,true,SG,100,fixed_price,100
PHDC-360Vuz-VIP-12months,57,59,56,true,SM,100,fixed_profit,100
PHDC-360Vuz-VIP-1months,57,60,57,true,VI,100,percent_profit,100
PHDC-360Vuz-VIP-3months,57,,false,OS,100,fixed_price,100
`;
const providerPriceData = `sku,price,price_without_vat,vat_type,nominal
PHDC-12-Months-DeezerPremium,50,45,SM,100
PHDC-360Vuz-VIP-12months,50,50,SM,100
PHDC-360Vuz-VIP-1months,50,20,SM,100
`;

const mapping = {
  "Uploads::SkuMapping": skuData,
  "Uploads::MerchantProducts": merchantPriceData,
  "Uploads::SupplierProducts": providerPriceData,
};

const exampleClick = (type) => {
  if (mapping[type] === "Uploads::BulkImageUpload") {
    return;
  }
  if (mapping[type] === undefined) {
    return;
  }

  const blob = new Blob([mapping[type]], {
    type: "application/csv",
  });
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", `${type}_example.csv`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export default exampleClick;
