import * as React from "react";
import {
  Create,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  required,
  TextInput,
} from "react-admin";
import ApiTokenList from "./List";
import InDrawer from "../../hocs/InDrawer";

const ApiTokenCreate = () => {
  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <ReferenceInput source="tokenizable_id" reference="merchants">
          <SelectInput
            fullWidth
            optionText="name"
            optionValue="id"
            label="Merchant"
            validate={[required()]}
          />
        </ReferenceInput>
        <TextInput
          fullWidth
          source="tokenizable_type"
          validate={[required()]}
          defaultValue="Merchant"
          disabled
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Create>
  );
};
export default InDrawer(
  <ApiTokenList />,
  <ApiTokenCreate />,
  "Create Api Token",
  "Create new Api Token",
  "/api_tokens",
);
