import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import RegionList from "./List";
import InDrawer from "../../hocs/InDrawer";

const RegionCreate = () => {
  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <TextInput
          fullWidth
          source="name"
          validate={[required(), maxLength(100)]}
          sx={{ marginBottom: "16px" }}
        />
        <ReferenceArrayInput source="currency_ids" reference="currencies">
          <SelectArrayInput
            fullWidth
            optionText="name"
            validate={[required()]}
            sx={{
              "& .MuiInputLabel-root": {
                top: "-6px",
              },
            }}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
export default InDrawer(
  <RegionList />,
  <RegionCreate />,
  "Create Region",
  "Create new Region",
  "/regions",
);
