import React, { useState, useEffect, useRef } from "react";
import { Box, List } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import { style } from "./style";
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const FilterStatus = ({ setStatus, currentMenuOpen, setCurrentMenuOpen }) => {
  const [selectedStatus, setSelectedStatus] = useState("completed");
  const dropdownRef = useRef();
  const buttonRef = useRef();
  const [title, setTitle] = useState(
    <Box>Status: {capitalize(selectedStatus)}</Box>,
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCurrentMenuOpen(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToggle = (event) => {
    event.stopPropagation();
    if (currentMenuOpen === "status") {
      setCurrentMenuOpen(null);
    }
    setCurrentMenuOpen("status");
  };

  const handleStatusClick = (e, status) => {
    e.stopPropagation();

    const selected = status === "all" ? "" : status;
    setSelectedStatus(selected);
    setStatus(selected);
    setTitle(<Box>Status: {capitalize(status)}</Box>);
    setCurrentMenuOpen(null);
  };

  return (
    <Box ref={buttonRef} sx={style.statusButton} onClick={handleToggle}>
      <Box>{title}</Box>
      <ExpandMoreIcon />
      {currentMenuOpen === "status" && (
        <Box ref={dropdownRef} sx={style.statusDropdown}>
          <List>
            {[
              "all",
              "initialized",
              "completed",
              "failed",
              "refunded",
              "processing",
              "delivering",
              "accepted",
              "canceled",
            ].map((status) => (
              <Box
                sx={{
                  ...style.listItem,
                  backgroundColor:
                    status === selectedStatus ? "#F5F6F8" : "#FFF",
                }}
                key={status}
                onClick={(e) => handleStatusClick(e, status)}
              >
                {capitalize(status)}
              </Box>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

FilterStatus.propTypes = {
  setStatus: PropTypes.func,
  currentMenuOpen: PropTypes.string,
  setCurrentMenuOpen: PropTypes.func,
};

export default FilterStatus;
