import React, { useState, useCallback } from "react";
import {
  Edit,
  ReferenceInput,
  SimpleForm,
  AutocompleteInput,
  ImageField,
  ImageInput,
  TextInput,
  required,
  maxLength,
  useEditController,
} from "react-admin";
import { useNotify } from "react-admin";
import FileInputPlaceholder from "../../components/common/FileInputPlaceholder";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";
import ImagesList from "../../components/ImageManipulation/ImagesList";
import CategoryShow from "./Show";
import InDrawer from "../../hocs/InDrawer";

const ImageInputWithPreview = (props) => {
  const { record } = useEditController(props);
  const [inputHidden, setInputHidden] = useState(false);
  const handleDropRejected = useCallback(
    (fileRejections) => {
      const errorMessage = fileRejections
        .flatMap((fr) => fr.errors)
        .map((err) => err.message)
        .join("; ");
      notify(`Invalid file(s): ${errorMessage}`, { type: "error" });
    },
    [notify],
  );
  const notify = useNotify();
  const handleDropAccept = () => setInputHidden(true);

  if (record !== undefined && record?.images !== undefined && record.images[0])
    return <ImagesList />;

  return (
    <ImageInput
      sx={{
        "& .RaFileInput-dropZone": {
          backgroundColor: "transparent",
        },
      }}
      source="new_images"
      options={{
        onDropRejected: handleDropRejected,
        onDropAccepted: handleDropAccept,
      }}
      maxSize={10000000}
      multiple
      accept="image/*"
      placeholder={
        !inputHidden ? <FileInputPlaceholder subText="up to 10 MB." /> : <></>
      }
      validateFileRemoval={() => setInputHidden(false)}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  );
};

const CategoryEdit = (props) => {
  const record = useEditController(props).record;

  return (
    <Edit
      actions={<></>}
      title=" "
      mutationMode="pessimistic"
      undoable={false}
      redirect="show"
      {...props}
    >
      <SimpleForm toolbar={<CustomEditToolbar recordName={"categories"} />}>
        <TextInput
          fullWidth
          source="name"
          validate={[required(), maxLength(60)]}
        />
        <ReferenceInput
          source="category_id"
          reference="categories"
          filter={{ excludeId: record?.id }}
        >
          <AutocompleteInput
            fullWidth
            optionText="name"
            optionValue="id"
            filterToQuery={(searchText) => ({ name: searchText })}
            label="Parental Category"
          />
        </ReferenceInput>
        <ImageInputWithPreview />
      </SimpleForm>
    </Edit>
  );
};
export default InDrawer(
  <CategoryShow />,
  <CategoryEdit />,
  "Edit category",
  "Edit category details",
);
