export const statusColorMap = {
  received: "#162664",
  accepted: "#164564",
  paid: "#176448",
  canceled: "#710E21",
  placed: "#6E330C",
  processing: "#2B1664",
  failed: "#525866",
  completed: "#176448",
  pending: "#525866",
  ordered: "#176448",
  succeed: "#176448",
  refunded: "#710E21",
  rejected: "#525866",
  create: "#176448",
  update: "#2B1664",
};

export const statusBackgroundMap = {
  received: "#C2D6FF",
  accepted: "#C2EFFF",
  paid: "#CBF5E5",
  canceled: "#F8C9D2",
  placed: "#FFDAC2",
  processing: "#CAC2FF",
  failed: "#F6F8FA",
  completed: "#C2FFC2",
  pending: "#F6F8FA",
  ordered: "#FFF897",
  succeed: "#C2FFC2",
  refunded: "#F8C9D2",
  rejected: "#F6F8FA",
  create: "#CBF5E5",
  update: "#CAC2FF",
};
