import React, { useState } from "react";
import {
  useNotify,
  useDataProvider,
  useRecordContext,
  useRefresh,
  usePermissions,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ThumbDownIcon from "@mui/icons-material/ThumbDownOffAlt";
import { style } from "./style";
const RefundButton = () => {
  const { permissions } = usePermissions();

  if (!can("orders", "refund", permissions)) return null;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const order = useRecordContext();
  const notify = useNotify();

  const refund = () => {
    setLoading(true);
    return dataProvider.refundOrder(order.id).then(() => {
      notify(`Order #${order.id} has been refunded`);
      setLoading(false);
      refresh();
    });
  };
  if (!order) return null;
  if (!order.refundable) return null;

  return (
    <Tooltip title="Refund">
      <Button sx={style.actionButton} disabled={loading} onClick={refund}>
        <ThumbDownIcon alt="Refund" />
      </Button>
    </Tooltip>
  );
};

export default RefundButton;
