import React from "react";
import {
  TextField,
  DateField,
  ReferenceField,
  ShowButton,
  useListContext,
  useGetOne,
} from "react-admin";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import noImage from "../../../assets/icons/no-image.svg";
import PropTypes from "prop-types";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import SmallPriceList from "../../components/Prices/SmallList";
import { EditableField } from "../../components/Editable/Field";
import BoolColored from "../../components/common/BoolColored";
import { style } from "./style";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
const placeholderImage = noImage;

const ProductCard = ({ record }) => {
  const role = localStorage.getItem("role");
  const { data: product } = record.product_id
    ? useGetOne("products", { id: record.product_id })
    : { data: null };
  const imageUrl =
    product && product?.images && product?.images[0]
      ? product?.images[0]?.url
      : placeholderImage;

  return (
    <Card variant="outlined" sx={style.cardView.cardContainer}>
      <Box sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          image={imageUrl}
          alt={record?.translations?.en?.name || "Placeholder"}
          sx={style.cardView.cardMedia}
        />
      </Box>
      <CardContent sx={style.cardView.cardContent}>
        <Box sx={style.cardView.innerWrap}>
          <Box sx={{ fontWeight: "bold" }}>
            <TextField
              sx={{ fontWeight: "bold" }}
              source="name_by_supplier"
              record={record}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "0.875rem",
                lineHeight: 1.43,
              }}
            >
              Id:
            </Typography>
            <TextField source="id" record={record} />
          </Box>

          <Box sx={style.cardView.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>EAN:</Box>
            <EditableField
              source={"ean"}
              type="text"
              record={record}
              showExtra={true}
              classes={style.cardView.truncate}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Box sx={{ fontWeight: "bold" }}>Product:</Box>
            <ReferenceField
              source="product_id"
              reference="products"
              record={record}
            >
              <TextField sx={style.cardView.nameTruncate} source="name" />
            </ReferenceField>
          </Box>

          <Box sx={style.cardView.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Integration:</Box>
            <ReferenceField
              source="integration_id"
              reference="integrations"
              record={record}
            >
              <TextField source="name" />
            </ReferenceField>
          </Box>

          <Box sx={style.cardView.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Active:</Box>
            <BoolColored
              source="active"
              key="active"
              label="Active"
              recordOut={record}
            />
          </Box>

          <Box sx={style.cardView.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Available:</Box>
            <EditableBooleanField
              source="available"
              key="available"
              label="Available"
              recordOut={record}
            />
          </Box>

          <Box sx={style.cardView.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>VAT:</Box>
            <EditableBooleanField
              source="vat"
              key="vat"
              label="Vat"
              recordOut={record}
            />
          </Box>

          <Box sx={style.cardView.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Countries:</Box>
            <TextField source="countries" record={record} />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Box sx={{ fontWeight: "bold" }}>Created At:</Box>
            <DateField source="created_at" record={record} />
          </Box>

          <Box sx={style.cardView.titleWrap}>
            <Box sx={{ fontWeight: "bold" }}>Updated At:</Box>
            <DateField source="updated_at" record={record} />
          </Box>

          {!record.active && (
            <Box sx={style.cardView.titleWrap}>
              <Box sx={{ fontWeight: "bold" }}>Deleted At:</Box>
              <DateField source="deleted_at" record={record} />
            </Box>
          )}

          <Box>
            <SmallPriceList
              record={record}
              filter={{ priceable_type: "IntegrationProduct" }}
            />
          </Box>
        </Box>
        <Box sx={{ mt: "auto" }}>
          <Box sx={style.cardView.showButtonWrap}>
            <ShowButton record={record} />
            {role === "Accounts::SuperAdmin" && (
              <CustomDeleteButton
                extRecord={record}
                recordName="integration_products"
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

ProductCard.propTypes = {
  record: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

const CardList = () => {
  const { data, isLoading } = useListContext();
  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      {data.map((record) => (
        <Grid
          mb={2}
          item
          key={record.id}
          xs={12}
          sm={6}
          md={4}
          lg={"auto"}
          xl={"auto"}
          sx={{ minWidth: 371, mr: 1 }}
        >
          <ProductCard record={record} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardList;
