import * as React from "react";
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useRecordContext,
} from "react-admin";
import PropTypes from "prop-types";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";
import TranslationShow from "./Show";
import InDrawer from "../../hocs/InDrawer";
const KeyInput = ({ disabled }) => (
  <TextInput
    fullWidth
    source="key"
    validate={[required()]}
    disabled={disabled}
  />
);

KeyInput.propTypes = { disabled: PropTypes.bool.isRequired };

const LocaleInput = ({ disabled }) => (
  <ReferenceInput source="locale_id" reference="locales">
    <SelectInput
      label="Language"
      fullWidth
      optionText="name"
      disabled={disabled}
      validate={[required()]}
    />
  </ReferenceInput>
);

LocaleInput.propTypes = { disabled: PropTypes.bool.isRequired };

const EditableInputs = () => {
  const { translatable_type: translatableType } = useRecordContext() || {};
  const disabled = Boolean(translatableType);

  return (
    <>
      <KeyInput disabled={disabled} />
      <LocaleInput disabled={disabled} />
    </>
  );
};

const TranslationEdit = () => {
  return (
    <Edit title=" " actions={<></>} redirect="show">
      <SimpleForm toolbar={<CustomEditToolbar recordName={"translations"} />}>
        <EditableInputs />
        <TextInput fullWidth source="value" multiline validate={[required()]} />
        <TextInput
          fullWidth
          source="translatable_type"
          disabled
          style={{ display: "none" }}
        />
        <NumberInput
          fullWidth
          source="translatable_id"
          disabled
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default InDrawer(
  <TranslationShow />,
  <TranslationEdit />,
  "Edit Translation",
  "Edit Translation details",
);
