import React from "react";
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  NumberField,
} from "react-admin";

export const ContractShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="amount" />
      <TextField source="status" />
      <FunctionField
        render={(record) => record.type.replace(/^Contracts::/, "")}
        source="type"
      />
      <ReferenceField source="currency_id" reference="currencies" />
      <ReferenceField source="wallet_id" reference="wallets" label="Merchant">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="contract_id" reference="contracts" />
      <ReferenceField source="order_id" reference="orders" />
      <DateField source="created_at" showTime />
      <ReferenceField source="region_id" reference="regions" />
      <NumberField source="wallet_before_balance" />
      <NumberField source="wallet_after_balance" />
    </SimpleShowLayout>
  </Show>
);
export default ContractShow;
