import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  NumberField,
  BooleanInput,
  TextInput,
  ReferenceArrayField,
  ShowButton,
  usePermissions,
} from "react-admin";
import MerchantTopupButton from "./MerchantTopupButton";
import MerchantDeductButton from "./MerchantDeductButton";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import ListFilterValues from "../../components/common/ListFilterValues";
import { Box } from "@material-ui/core";
import { can } from "../../../shared/permissionProvider";
import EditableField from "../../components/Editable/Field";
import BoolColored from "../../components/common/BoolColored";

const MerchantFilters = [
  <TextInput source="name" key="name" />,
  <BooleanInput source="active" key="active" defaultValue={true} />,
];

const TopupButton = () => {
  const { permissions } = usePermissions();

  return can("contracts", "create", permissions) ? (
    <MerchantTopupButton />
  ) : null;
};
const DeductButton = () => {
  const { permissions } = usePermissions();

  return can("contracts", "create", permissions) ? (
    <MerchantDeductButton />
  ) : null;
};
const MerchantList = () => {
  const role = localStorage.getItem("role");
  const [active, setActive] = React.useState(true);
  return (
    <List
      filters={MerchantFilters}
      exporter={false}
      aside={<ListFilterValues setActive={setActive} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <ReferenceField source="region_id" reference="regions">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="currency_id" reference="currencies">
          <TextField source="iso_code" />
        </ReferenceField>
        <BoolColored source="vatable" />
        <ReferenceArrayField
          source="account_ids"
          reference="accounts"
          sortable={false}
        />
        <NumberField source="current_balance" sortable={false} />
        <EditableField
          source="credit"
          type={"number"}
          justify={{ justifyContent: "center" }}
        />
        <DateField source="created_at" showTime />
        {!active && <DateField source="deleted_at" showTime />}
        <Box sx={{ display: "flex", gap: "8px" }}>
          <TopupButton />
          <DeductButton />
          <ShowButton />
          {role === "Accounts::SuperAdmin" && (
            <CustomDeleteButton recordName={"merchants"} />
          )}
        </Box>
      </Datagrid>
    </List>
  );
};

export default () => MerchantList;
