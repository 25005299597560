import React from "react";
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  ReferenceManyField,
  Datagrid,
  usePermissions,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import { Extra } from "./List";
export const OrderShow = () => {
  const { permissions } = usePermissions();
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="merchant_id" reference="merchants" />
        <ReferenceField source="currency_id" reference="currencies" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="status" />
        {can("contracts", "list", permissions) && (
          <TextField source="total_cost" />
        )}
        <TextField source="total_price" />
        <TextField source="request_id" />
        <TextField source="customer_id" />
        <TextField source="pos_order_id" />
        {can("contracts", "create", permissions) && (
          <ReferenceManyField
            label="Contracts"
            reference="contracts"
            target="order_id"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="type" />
              <TextField source="status" />
              <NumberField source="amount" />
            </Datagrid>
          </ReferenceManyField>
        )}
        <Extra />
      </SimpleShowLayout>
    </Show>
  );
};

export default OrderShow;
