import * as React from "react";
import {
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  maxLength,
  FormDataConsumer,
  NumberInput,
  BooleanInput,
} from "react-admin";
import CustomProviderInputs from "./CustomProviderInputs";
import IntegrationList from "./List";
import InDrawer from "../../hocs/InDrawer";

const IntegrationCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput
          fullWidth
          source="name"
          validate={[required(), maxLength(100)]}
        />
        <ReferenceInput source="supplier_id" reference="suppliers">
          <SelectInput
            fullWidth
            label="Integration"
            optionText="name"
            optionValue="id"
            validate={[required()]}
          />
        </ReferenceInput>
        <BooleanInput source="notifier_on_balance_change" />
        <NumberInput source="expected_balance" fullWidth defaultValue={0.0} />
        <ReferenceInput source="region_id" reference="regions">
          <SelectInput
            fullWidth
            optionText="name"
            optionValue="id"
            validate={[required()]}
          />
        </ReferenceInput>
        <ReferenceInput source="currency_id" reference="currencies">
          <SelectInput
            fullWidth
            optionText="name"
            optionValue="id"
            validate={[required()]}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData: { supplier_id } }) => (
            <CustomProviderInputs supplier_id={supplier_id} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
export default InDrawer(
  <IntegrationList />,
  <IntegrationCreate />,
  "Create Provider",
  "Create new Provider",
  "/integrations",
);
