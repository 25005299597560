import React from "react";
import PropTypes from "prop-types";

const NotificationTypes = ({ body }) => {
  const modifyString = (body) => {
    const cases = {
      "Upload FileUploads::BulkImageUpload": "Bulk image Upload",
      "Upload FileUploads::SkuMapping": "Sku Mapping Upload",
      "Upload FileUploads::SupplierProducts": "Supplier Products Upload",
      "Upload FileUploads::MerchantProducts": "Merchant Products Upload",
    };

    for (const target in cases) {
      if (body.includes(target)) {
        body = body.replace(target, cases[target]);
        break;
      }
    }

    return body;
  };

  return <div>{modifyString(body)}</div>;
};

NotificationTypes.propTypes = {
  body: PropTypes.string,
};

export default NotificationTypes;
