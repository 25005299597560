import React from "react";
import PropTypes from "prop-types";
import { JsonField } from "react-admin-json-view";
import { FunctionField, TextField } from "react-admin";
import { isArray } from "lodash";

const defaultJSONOptions = {
  name: null,
  collapsed: true,
  enableClipboard: false,
  displayDataTypes: false,
  root: false,
};
const ObjectJsonField = ({
  source,
  displayDataTypes,
  reactJsonOptions,
  jsonString,
}) => (
  <JsonField
    displayDataTypes={displayDataTypes}
    jsonString={jsonString}
    source={source}
    reactJsonOptions={{ ...defaultJSONOptions, ...reactJsonOptions }}
  />
);

const isObject = (a) => !!a && a.constructor === Object;
const isNull = (a) => !a;
const isString = (a) => typeof a === "string";

const GetSafeField = (source, displayDataTypes, reactJsonOptions, record) => {
  const data = record[source];

  if (isNull(data)) {
    return <TextField source={source} />;
  }

  if (isString(data)) {
    try {
      const result = JSON.parse(data);
      if (isObject(result)) {
        return (
          <ObjectJsonField
            source={source}
            jsonString={true}
            displayDataTypes={displayDataTypes}
            reactJsonOptions={reactJsonOptions}
          />
        );
      }
      return <TextField source={source} />;
    } catch (e) {
      return <TextField source={source} />;
    }
  }
  if (isObject(data) || isArray(data)) {
    return (
      <ObjectJsonField
        source={source}
        displayDataTypes={displayDataTypes}
        reactJsonOptions={reactJsonOptions}
      />
    );
  }
  return null;
};
const JSONField = ({ source, displayDataTypes, reactJsonOptions = {} }) => (
  <FunctionField
    source={source}
    render={(record) =>
      GetSafeField(source, displayDataTypes, reactJsonOptions, record)
    }
  />
);

JSONField.propTypes = {
  source: PropTypes.string,
  displayDataTypes: PropTypes.bool,
  reactJsonOptions: PropTypes.object,
};
ObjectJsonField.propTypes = {
  source: PropTypes.string,
  displayDataTypes: PropTypes.bool,
  reactJsonOptions: PropTypes.object,
  jsonString: PropTypes.bool,
};

export default JSONField;
