import React, { useState, useEffect, useRef } from "react";
import { Box, List } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import { style } from "./style";

const FilterCurrency = ({
  setCurrency,
  options,
  currencyId,
  merchant,
  currentMenuOpen,
  setCurrentMenuOpen,
}) => {
  const defaultCurrency = options.find((curr) => curr.default);

  useEffect(() => {
    handleCurrencySelect(currencyId);
  }, [merchant]);

  useEffect(() => {
    if (!currencyId) {
      handleCurrencySelect(defaultCurrency.id);
    }
  }, [currencyId]);

  const dropdownRef = useRef();
  const buttonRef = useRef();

  const [title, setTitle] = useState(
    <Box>
      {defaultCurrency ? defaultCurrency.iso_code : options[0].iso_code}
    </Box>,
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCurrentMenuOpen(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToggle = (event) => {
    event.stopPropagation();

    setCurrentMenuOpen("currency");
  };

  const handleCurrencySelect = (currencyId) => {
    const selectedOption = options.find(
      (curr) => parseInt(curr.id) === parseInt(currencyId),
    );

    if (selectedOption) {
      setCurrency(selectedOption.id);

      setTitle(
        <Box
          sx={{
            width: "160px",
            height: "22px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {selectedOption.iso_code}
        </Box>,
      );
    } else {
      setCurrency(null);

      setTitle(<Box>{defaultCurrency.iso_code}</Box>);
    }
    setCurrentMenuOpen(null);
  };

  return (
    <Box ref={buttonRef} sx={style.currencyButton} onClick={handleToggle}>
      <Box>{title}</Box>
      <ExpandMoreIcon />

      {currentMenuOpen === "currency" && (
        <Box ref={dropdownRef} sx={style.currencyPicker}>
          <List>
            <Box
              onClick={() => handleCurrencySelect("")}
              sx={{
                cursor: "pointer",
                padding: "8px",
                borderRadius: "8px",
                ":hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              -
            </Box>

            {options.map((option) => (
              <Box
                key={option.id}
                onClick={(e) => {
                  e.stopPropagation(), handleCurrencySelect(option.id);
                }}
                sx={{
                  cursor: "pointer",
                  padding: "8px",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                {option.iso_code}
              </Box>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

FilterCurrency.propTypes = {
  setCurrency: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  currency: PropTypes.object,
  merchant: PropTypes.number,
  currencyId: PropTypes.number,
  currentMenuOpen: PropTypes.string,
  setCurrentMenuOpen: PropTypes.func,
};

export default FilterCurrency;
