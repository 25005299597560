import React from "react";
import { Datagrid, DateField, List, ShowButton, TextField } from "react-admin";

const SupplierList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <DateField source="created_at" />
      <ShowButton />
    </Datagrid>
  </List>
);
export default SupplierList;
