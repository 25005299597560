import React from "react";
import PropTypes from "prop-types";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { get as dig } from "lodash";
const formatDate = (isoDate) => new Date(isoDate).toLocaleDateString();

const CustomTooltip = ({ active, payload, label, dataKeyName }) => {
  if (payload && payload.length && active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Date: ${formatDate(label)}`}</p>
        <p className="value">{`Value: ${dig(payload, [
          0,
          "payload",
          dataKeyName,
        ])}`}</p>
      </div>
    );
  }

  return null;
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
  dataKeyName: PropTypes.string,
};
const ChartArea = ({
  data,
  stopColor1,
  stopColor2,
  strokeColor,
  id,
  unit,
  dataKeyName,
  height,
}) => {
  return (
    <ResponsiveContainer height={height ? height : "75%"}>
      <AreaChart
        data={data ? data : []}
        margin={{ top: 10, right: 30, left: 40, bottom: 0 }}
      >
        <defs>
          <linearGradient
            id={id}
            x1="221.491"
            y1="0"
            x2="221.491"
            y2="163"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={stopColor1} stopOpacity="0.86" />
            <stop offset="0.908976" stopColor={stopColor2} stopOpacity="0.04" />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="date"
          tickFormatter={(tick) => formatDate(tick)}
          axisLine={false}
          dy={10}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          allowDataOverflow={true}
          unit={unit}
          type="number"
          domain={["auto", "auto"]}
        />
        <CartesianGrid vertical={true} strokeDasharray="3 " />
        <Tooltip content={<CustomTooltip dataKeyName={dataKeyName} />} />
        <Area
          type="monotone"
          dataKey={dataKeyName}
          stroke={strokeColor}
          strokeWidth={2}
          fillOpacity={1}
          fill={`url(#${id})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
ChartArea.propTypes = {
  data: PropTypes.array,
  stopColor1: PropTypes.string,
  stopColor2: PropTypes.string,
  strokeColor: PropTypes.string,
  id: PropTypes.string,
  unit: PropTypes.string,
  dataKeyName: PropTypes.string,
  height: PropTypes.string,
};

export default ChartArea;
