import * as React from "react";
import {
  Create,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  TextInput,
  required,
  NumberInput,
  FormDataConsumer,
  minValue,
  ReferenceArrayInput,
} from "react-admin";
import MerchantList from "./List";
import CurrencyInput from "./CurrencyInput";
import InDrawer from "../../hocs/InDrawer";
import { Box } from "@material-ui/core";
import { TimezoneInput } from "../../components/Timezone";

const MerchantCreate = () => (
  <Create title={" "} redirect="show">
    <SimpleForm>
      <TextInput fullWidth source="name" validate={[required()]} />
      <ReferenceInput source="region_id" reference="regions">
        <SelectInput
          fullWidth
          optionText="name"
          optionValue="id"
          validate={[required()]}
        />
      </ReferenceInput>
      <NumberInput
        fullWidth
        source="credit"
        validate={[required(), minValue(0)]}
        defaultValue={0.0}
      />
      <Box sx={{ maxWidth: "408px" }}>
        <ReferenceArrayInput
          fullWidth
          source="account_ids"
          reference="accounts"
          filter={{ type: "Accounts::MerchantAdmin", active: true }}
        />
      </Box>
      <Box sx={{ maxWidth: "408px" }}>
        <NumberInput
          fullWidth
          source="vat_rate"
          validate={[required(), minValue(0)]}
          defaultValue={0.0}
        />
        <SelectInput
          fullWidth
          source="merchant_type"
          choices={[
            { id: "Internal", name: "Internal" },
            { id: "External", name: "External" },
          ]}
        />
      </Box>
      <TimezoneInput />
      <FormDataConsumer>
        {({ formData }) => <CurrencyInput {...formData} />}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default InDrawer(
  <MerchantList />,
  <MerchantCreate />,
  "Create Merchant",
  "Create new Merchant",
  "/merchants",
);
