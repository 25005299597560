import React from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  TextField,
  ReferenceManyField,
  ReferenceField,
  ReferenceOneField,
  ShowButton,
  ReferenceInput,
  FilterButton,
  SelectInput,
  TopToolbar,
  usePermissions,
  DateTimeInput,
  BooleanInput,
  TextInput,
  Loading,
  useListContext,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from "react-admin";
import RefundButton from "../../components/Order/RefundButton";
import ComplaintButton from "../../components/Order/ComplaintButton";
import ResendButton from "../../components/Order/ResendButton";
import StatusBadge from "../../components/Badges/StatusBadge";
import ExportButton from "../../components/Export/ExportButton";
import { can } from "../../../shared/permissionProvider";
import Box from "@mui/material/Box";
import SafeJsonField from "../../components/SafeJsonField";

const Extra = () => {
  const role = localStorage.getItem("role");
  const { permissions } = usePermissions();

  return (
    <>
      <SafeJsonField
        source="request_payload"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          root: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <ReferenceManyField
        label="Products"
        reference="order_merchant_products"
        target="order_id"
        perPage={50}
      >
        <Datagrid bulkActionButtons={false}>
          <ReferenceField
            source="merchant_product_id"
            reference="merchant_products"
            link={false}
          >
            <ReferenceField
              source="product_id"
              reference="products"
              label="Product"
            >
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
          {can("order_integration_products", "create", permissions) && (
            <ReferenceManyField
              perPage={50}
              label="ProviderProduct"
              reference="order_integration_products"
              target="order_merchant_product_id"
            >
              <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="status" />
                <ShowButton />
                <ReferenceManyField
                  source="integration_product_id"
                  reference="integration_products"
                  target="id"
                >
                  <Datagrid bulkActionButtons={false}>
                    <TextField source="id" />
                    <TextField source="ean" />
                    <ReferenceField
                      source="product_id"
                      reference="products"
                      label="Product"
                    />
                    <ReferenceField
                      source="integration_id"
                      reference="integrations"
                      label="Provider"
                    />
                  </Datagrid>
                </ReferenceManyField>
              </Datagrid>
            </ReferenceManyField>
          )}
          {role !== "Accounts::MerchantAdmin" && <TextField source="code" />}
          <NumberField source="price" />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

const OrderFilters = [
  <TextInput source="q" label="Search" key="q" />,
  <ReferenceInput
    source="merchant_id"
    reference="merchants"
    label="Merchant"
    key={"merchant_id"}
    filter={{ active: true }}
  >
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceArrayInput source="product_id" reference="products" key={"sku"}>
    <AutocompleteArrayInput
      fullWidth
      optionValue="id"
      optionText="sku"
      filterToQuery={(sku) => ({ sku })}
    />
  </ReferenceArrayInput>,
  <TextInput source="pos_order_id" key="pos_order_id" label="POS Order ID" />,
  <SelectInput
    source="status"
    choices={[
      { id: "initialized", name: "Initialized" },
      { id: "completed", name: "Completed" },
      { id: "failed", name: "Failed" },
      { id: "refunded", name: "Refunded" },
      { id: "processing", name: "Processing" },
      { id: "delivering", name: "Delivering" },
      { id: "accepted", name: "Accepted" },
      { id: "canceled", name: "Canceled" },
    ]}
    key="status"
  />,
  <BooleanInput
    source="active_merchants"
    key="active_merchants"
    defaultValue={true}
  />,
  <DateTimeInput source="before" key="before" />,
  <DateTimeInput source="after" key="after" />,
];
const SuperAdminFilters = [
  ...OrderFilters,
  <ReferenceInput
    source="integration_id"
    reference="integrations"
    label="Provider"
    key={"integration_id"}
  >
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
];

const ListActions = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <TopToolbar sx={{ position: "relative" }}>
        <FilterButton />
        <ExportButton />
      </TopToolbar>
    </Box>
  );
};

const OrderList = () => {
  const role = localStorage.getItem("role");
  const { isLoading, isFetching } = useListContext();

  if (isLoading || isFetching) {
    return <Loading />;
  }
  const filters =
    role === "Accounts::SuperAdmin" ? SuperAdminFilters : OrderFilters;
  return (
    <List
      filters={filters}
      exporter={false}
      actions={<ListActions />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false} expand={Extra} expandSingle>
        <NumberField source="id" />
        <ReferenceField
          source="merchant_id"
          reference="merchants"
          label="Merchant"
        />
        {role !== "Accounts::MerchantAdmin" && (
          <NumberField source="total_cost" />
        )}

        <NumberField source="total_price" />
        <DateField source="created_at" showTime />
        {role !== "Accounts::MerchantAdmin" && (
          <FunctionField
            render={(order) => {
              try {
                return (
                  Math.round((order.total_price - order.total_cost) * 100) / 100
                );
              } catch (error) {
                return 0;
              }
            }}
            label="Profit"
          />
        )}
        <ReferenceOneField
          source="currency_id"
          target="id"
          reference="currencies"
          label="Currency"
        />
        <FunctionField
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          source="status"
          render={(order) => <StatusBadge text={order.status} />}
        />
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ShowButton />
          <ResendButton />
          <RefundButton />
          <ComplaintButton />
        </Box>
      </Datagrid>
    </List>
  );
};

export default () => OrderList;
export { Extra };
