import React from "react";
import { BooleanField, useRecordContext } from "react-admin";
import PropTypes from "prop-types";
const BoolColored = ({ label, source, recordOut }) => {
  const record = recordOut ? recordOut : useRecordContext();

  return record[source] ? (
    <BooleanField
      record={record}
      sx={{ color: "#32e132" }}
      source={source}
      label={label}
    />
  ) : (
    <BooleanField
      record={record}
      sx={{ color: "red" }}
      source={source}
      label={label}
    />
  );
};

BoolColored.propTypes = {
  recordOut: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string,
};

export default BoolColored;
