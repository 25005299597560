import { Box } from "@material-ui/core";
import React from "react";
import exportIcon from "../../../assets/icons/exportIcon.svg";
import ButtonSmall from "./ButtonSmall";
import PropTypes from "prop-types";

const FileInputPlaceholder = ({ subText }) => {
  return (
    <Box
      sx={{
        width: "400px",
        height: "130px",
        borderRadius: "12px",
        border: "1px dashed var(--Neutral-Solid-100, #DFE1E7)",
        background: "var(--bg-white-0, #FFF)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <Box>
        <ButtonSmall icon={exportIcon} />
      </Box>
      <Box>
        <Box
          sx={{
            fontFamily: "inter",
            fontSize: "14px",
            fontWeight: "500",
            marginLeft: "10px",
          }}
        >
          <Box sx={{ color: "#9747FF", display: "inline" }}>
            Click to upload
          </Box>{" "}
          or drag & drop it here.
        </Box>
        <Box
          sx={{
            fontFamily: "inter",
            fontSize: "12px",
            color: "#818898",
          }}
        >
          {subText}
        </Box>
      </Box>
    </Box>
  );
};

FileInputPlaceholder.propTypes = {
  subText: PropTypes.string,
};

export default FileInputPlaceholder;
