import * as React from "react";
import {
  DateField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  TextField,
  usePermissions,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import VersionList from "../../components/Version/List";

export const CategoryShow = () => {
  const { permissions } = usePermissions();

  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <TextField source="id" />
          <ReferenceField
            source="tokenizable_id"
            reference="merchants"
            label="Merchant"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="value" />
          <DateField source="deleted_at" showTime />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
        </TabbedShowLayout.Tab>
        {can("versions", "list", permissions) && (
          <TabbedShowLayout.Tab label="history">
            <VersionList item_type="ApiToken" />
          </TabbedShowLayout.Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
export default CategoryShow;
