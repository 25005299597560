import * as React from "react";
import {
  Create,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  required,
  NumberInput,
  minValue,
} from "react-admin";
import CustomModal from "../../components/common/ModalSide";
import ContractList from "./List";
import { useNavigate } from "react-router";

const CategoryCreate = () => {
  const navigate = useNavigate();
  return (
    <>
      <ContractList />
      <CustomModal open={true} onClose={() => navigate("/contracts")}>
        <Create redirect="show">
          <SimpleForm>
            <SelectInput
              source="type"
              validate={[required()]}
              choices={[
                { id: "Contracts::Topup", name: "Topup Merchant" },
                { id: "Contracts::Deduct", name: "Deduct Merchant Balance" },
              ]}
            />
            <NumberInput source="amount" validate={[required(), minValue(0)]} />
            <ReferenceInput
              source="wallet_id"
              reference="wallets"
              label="Merchant"
            >
              <SelectInput
                optionText="name"
                optionValue="id"
                label="Merchant"
              />
            </ReferenceInput>
          </SimpleForm>
        </Create>
      </CustomModal>
    </>
  );
};
export default CategoryCreate;
