import { makeStyles, createStyles } from "@material-ui/core/styles";
export const style = {
  input: {
    "& .MuiFormHelperText-root": {
      display: "none",
    },
    "& .MuiSelect-select": {
      width: "200px",
    },
    "& .MuiList-root": {
      width: "200px",
    },
  },
  form: {
    "& .MuiFormControl-root": { width: "200px" },
  },
  paper: {
    padding: "8px",
    display: "flex",
    alignItems: "center",
  },
  onTop: {
    zIndex: 100,
  },
  wrap: {
    display: "flex",
    alignItems: "center",
  },
};

export const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
);
