import * as React from "react";
import {
  BooleanInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  SelectInput,
  required,
  maxLength,
  SimpleFormIterator,
  ArrayInput,
  NumberInput,
  minValue,
} from "react-admin";
import IntegrationProductList from "./List";
import InDrawer from "../../hocs/InDrawer";
import { VatTypeInput } from "../../components";
import { validateUniqueCurrency } from "./shared";

const IntegrationProductCreate = () => {
  return (
    <Create title={" "} redirect="show">
      <SimpleForm>
        <TextInput
          fullWidth
          source="ean"
          validate={[required(), maxLength(128)]}
        />
        <BooleanInput source="vat" />
        <TextInput fullWidth source="countries" />
        <ReferenceInput source="integration_id" reference="integrations">
          <SelectInput fullWidth optionText="name" validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput source="product_id" reference="products">
          <AutocompleteInput
            fullWidth
            optionText="sku"
            filterToQuery={(sku) => ({ sku })}
            validate={[required()]}
          />
        </ReferenceInput>
        <VatTypeInput validate={[required()]} />
        <ArrayInput
          source="prices_array"
          label="Prices"
          fullWidth
          validate={validateUniqueCurrency}
        >
          <SimpleFormIterator inline>
            <ReferenceInput
              source="currency"
              reference="currencies"
              target="iso_code"
            >
              <AutocompleteInput
                optionText="iso_code"
                optionValue="iso_code"
                validate={[required()]}
              />
            </ReferenceInput>
            <NumberInput source="price" validate={[minValue(0)]} />
            <NumberInput source="price_without_vat" validate={[minValue(0)]} />
            <NumberInput source="vat_amount" validate={[minValue(0)]} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
export default InDrawer(
  <IntegrationProductList />,
  <IntegrationProductCreate />,
  "Create Provider Product",
  "Create new Provider Product",
  "/integration_products",
);
