import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import CustomEditToolbar from "../../components/common/CustomEditToolbar";

import RegionShow from "./Show";
import InDrawer from "../../hocs/InDrawer";

const RegionEdit = () => (
  <Edit
    title={" "}
    actions={<></>}
    mutationMode="pessimistic"
    undoable={false}
    redirect="show"
  >
    <SimpleForm toolbar={<CustomEditToolbar recordName={"regions"} />}>
      <TextInput
        fullWidth
        source="name"
        validate={[required(), maxLength(100)]}
      />
      <ReferenceArrayInput source="currency_ids" reference="currencies">
        <SelectArrayInput
          sx={{
            "& .MuiInputLabel-root": {
              top: "-6px",
            },
          }}
          fullWidth
          optionText="name"
          validate={[required()]}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
export default InDrawer(
  <RegionShow />,
  <RegionEdit />,
  "Edit Region",
  "Edit Region details",
);
