import * as React from "react";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
} from "react-admin";
import InDrawer from "../../hocs/InDrawer";
import ConfigList from "./List";

const CreateCategory = () => (
  <Create redirect="show">
    <SimpleForm>
      <SelectInput
        source="key"
        choices={[
          {
            id: "slack_webhook_url",
            name: "slack_webhook_url",
          },
          {
            id: "slack_channel",
            name: "slack_channel",
          },
        ]}
        validate={[required()]}
      />
      <TextInput source="value" validate={[required()]} fullWidth />
    </SimpleForm>
  </Create>
);

export default InDrawer(
  <ConfigList />,
  <CreateCategory />,
  "Create Config",
  "Create new Config",
  "/configs",
);
