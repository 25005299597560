import React from "react";
import {
  List,
  DatagridConfigurable,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  TextInput,
  TopToolbar,
  SelectColumnsButton,
  FilterButton,
  CreateButton,
  ReferenceInput,
  SelectInput,
  usePermissions,
  ShowButton,
} from "react-admin";
import { can } from "../../../shared/permissionProvider";
import EditableField from "../../components/Editable/Field";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import { Box } from "@material-ui/core";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
import { ExtendedField } from "../../components/Editable/ExtendedField";
const ListActions = () => {
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      {can("translations", "create", permissions) && <CreateButton />}
    </TopToolbar>
  );
};

const TranslationFilters = [
  <ReferenceInput source="locale_id" reference="locales" key="locale_id">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <TextInput source="key" key="key" />,
  <TextInput source="value" key="value" />,
];

export const TranslationList = () => (
  <>
    <h2>Languages</h2>
    <List
      disableSyncWithLocation={true}
      title={" "}
      exporter={false}
      resource="locales"
      pagination={false}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <NumberField source="id" />
        <EditableField source="code" justify={{ justifyContent: "center" }} />
        <EditableField source="name" justify={{ justifyContent: "center" }} />
        <EditableBooleanField source="default" />
        <DateField source="created_at" showTime />
        <ShowButton />
      </Datagrid>
    </List>

    <h2>Translations</h2>
    <List
      title={" "}
      filters={TranslationFilters}
      filter={{ translatable: null }}
      actions={<ListActions />}
      exporter={false}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <NumberField source="id" />
        <ExtendedField
          source="locale_id"
          reference="locales"
          resource={"locales"}
          optionText="name"
          optionValue="id"
        />
        <TextField source="key" />
        <EditableField source="value" />
        <DateField source="updated_at" showTime />
        {/* <DateField source="deleted_at" showTime /> */}
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ShowButton sx={{ height: "35px" }} />
          <CustomDeleteButton recordName={"translations"} />
        </Box>
      </DatagridConfigurable>
    </List>
  </>
);
export default TranslationList;
