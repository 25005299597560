import * as React from "react";
import {
  BooleanInput,
  CreateButton,
  Datagrid,
  DateField,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  TopToolbar,
  usePermissions,
  AutocompleteInput,
  ShowButton,
  SortButton,
  BooleanField,
  ImageField,
} from "react-admin";
import ExportButton from "../../components/Export/ExportButton";
import PropTypes from "prop-types";
import { can } from "../../../shared/permissionProvider";
import ListFilterValues from "../../components/common/ListFilterValues";
import EditableField from "../../components/Editable/Field";
import { useState } from "react";
import { IconButton } from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import CardList from "./CardView";
import { Box } from "@material-ui/core";
import EditableBooleanField from "../../components/Editable/EditableBooleanField";
import { VatTypeField, VatTypeFilterInput } from "../../components";
import { style } from "../MerchantProduct/style";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import { TextFieldWithCopy } from "../../components/Editable/CopyTextField";

const MerchantProductFilters = [
  <BooleanInput source="active" key="active" defaultValue={true} />,
  <BooleanInput source="vat" key="vat" defaultValue={true} />,
  <ReferenceInput
    source="merchant_id"
    target="id"
    reference="merchants"
    key="merchant"
  />,
  <ReferenceInput
    source="product_id"
    reference="products"
    sort={{ field: "id", order: "ASC" }}
    key={"product"}
  >
    <AutocompleteInput
      optionText="sku"
      optionValue="id"
      filterToQuery={(searchText) => ({ sku: searchText })}
      size="small"
    />
  </ReferenceInput>,
  <VatTypeFilterInput key="vat_type" source="vat_type" />,
];
const ListActions = ({ toggleView, isCardView }) => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      <IconButton onClick={toggleView}>
        {isCardView ? <ViewListIcon /> : <ViewModuleIcon />}
      </IconButton>
      {isCardView && (
        <SortButton
          sx={style.sortButton}
          fields={[
            "merchant_id",
            "product_id",
            "recommended_retail_price",
            "created_at",
            "deleted_at",
          ]}
          label="sort"
        />
      )}
      <FilterButton />
      {can("merchant_products", "create", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  toggleView: PropTypes.func,
  isCardView: PropTypes.bool,
};

const MerchantProductList = () => {
  const { permissions } = usePermissions();
  const [active, setActive] = React.useState(true);
  const [isCardView, setIsCardView] = useState(false);

  const toggleView = () => {
    setIsCardView(!isCardView);
  };

  return (
    <List
      filters={MerchantProductFilters}
      exporter={false}
      actions={<ListActions toggleView={toggleView} isCardView={isCardView} />}
      aside={<ListFilterValues setActive={setActive} />}
    >
      {isCardView ? (
        <CardList />
      ) : (
        <Datagrid bulkActionButtons={false}>
          <NumberField source="id" />
          <ReferenceField source="product_id" reference="products" link="show">
            <ImageField source="image.url" sortable={false} />
          </ReferenceField>

          <ReferenceField
            source="merchant_id"
            reference="merchants"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="product_id" reference="products" link="show">
            <TextFieldWithCopy source="name" sx={style.truncate} />
          </ReferenceField>
          <TextFieldWithCopy
            source="sku"
            sx={style.truncate}
            sortable={false}
          />

          <EditableField source="price" label="Price" type={"number"} />
          <EditableField
            source="price_without_vat"
            label="Price without vat"
            type={"number"}
          />
          <NumberField
            source="vat_amount"
            label="Vat Amount"
            sortable={false}
          />
          <BooleanField source="active" />
          <TextField
            label="Currency"
            source="currency.iso_code"
            sortable={false}
          />
          <EditableField source="nominal" label="Nominal" type={"number"} />
          <EditableBooleanField source="vat" />
          <VatTypeField label="Vat Type" />
          <EditableField
            na={true}
            source="recommended_retail_price"
            type={"number"}
            justify={{ justifyContent: "center" }}
          />
          {can("merchant_products", "delete", permissions) && (
            <DateField source="created_at" />
          )}
          {can("merchant_products", "delete", permissions) && (
            <DateField source="updated_at" />
          )}
          {can("merchant_products", "delete", permissions) && !active && (
            <DateField source="deleted_at" />
          )}

          <Box sx={{ display: "flex", gap: "10px" }}>
            {can("merchant_products", "show", permissions) && (
              <ShowButton sx={{ height: "35px" }} />
            )}
            {can("merchant_products", "delete", permissions) && (
              <CustomDeleteButton recordName={"merchant_products"} />
            )}
          </Box>
        </Datagrid>
      )}
    </List>
  );
};
export default MerchantProductList;
