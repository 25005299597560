import React, { useState, useEffect } from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import AdminLayout from "./components/Layout/AdminLayout";
import * as Pages from "./pages";
import jsonServerProvider from "../shared/dataProvider";
import i18nServerProvider from "../shared/i18nProvider";
import authenticationProvider from "../shared/authProvider";
import { can } from "../shared/permissionProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./components/Layout/Theme";
import {
  MerchantsIcon,
  IntegrationsIcon,
  OrdersIcon,
} from "../assets/icons/SidebarIcons";
import CategoryIcon from "@mui/icons-material/Category";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  ApiIcon,
  TranslateIcon,
  LanguageIcon,
  PeopleIcon,
  InventoryIcon,
  SouthAmericaIcon,
  ProductionQuantityLimitsIcon,
  IntegrationInstructionsIcon,
  FileUploadIcon,
  CloudIcon,
  DifferenceIcon,
  WarehouseIcon,
  GavelIcon,
  ListAltIcon,
} from "../assets/icons/icons.js";

const App = (props) => {
  const [locales, setLocales] = useState([]);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const dataProvider = jsonServerProvider("/api")({ ...props });
  const authProvider = authenticationProvider({ ...props });
  const i18nProvider = i18nServerProvider(dataProvider, locales);

  useEffect(() => {
    dataProvider
      .getList("locales")
      .then(({ data }) =>
        setLocales(data.map(({ code, name }) => ({ locale: code, name }))),
      );

    const onLogIn = ({ detail: { role } }) => setRole(role);
    const onLogOut = () => {
      setRole(null);
      sessionStorage.clear();
    };

    window.addEventListener("auth:login", onLogIn);
    window.addEventListener("auth:logout", onLogOut);

    return () => {
      window.removeEventListener("auth:login", onLogIn);
      window.removeEventListener("auth:logout", onLogOut);
    };
  }, []);

  const { Intercom } = window;

  if (Intercom !== undefined) {
    // useEffect prevents repeated attempts to initialize the widget.
    useEffect(() => {
      if (role === "Accounts::MerchantAdmin") {
        dataProvider.getList("intercom_settings").then((settings) => {
          Intercom("boot", settings);
        });
      } else {
        Intercom("shutdown");
      }
    }, [role]);
  }

  return (
    <>
      <Admin
        theme={theme}
        layout={AdminLayout}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        loginPage={Pages.LoginPage}
        requireAuth
      >
        {(permissions) => (
          <>
            <CssBaseline />
            <CustomRoutes>
              <Route path="/" element={<Pages.Dashboard.Dashboard />} />
            </CustomRoutes>
            <Resource
              recordRepresentation="email"
              name="accounts"
              options={{
                label: i18nProvider.translate("accounts", {
                  _: "Accounts",
                }),
              }}
              icon={PeopleIcon}
              list={can("accounts", "list", permissions) && Pages.Account.List}
              edit={can("accounts", "edit", permissions) && Pages.Account.Edit}
              show={can("accounts", "show", permissions) && Pages.Account.Show}
              create={
                can("accounts", "create", permissions) && Pages.Account.Create
              }
            />
            <Resource
              recordRepresentation="name"
              options={{
                label: i18nProvider.translate("locales", {
                  _: "Locales",
                }),
              }}
              icon={LanguageIcon}
              name="locales"
              show={can("locales", "show", permissions) && Pages.Locale.Show}
              edit={can("locales", "edit", permissions) && Pages.Locale.Edit}
              create={
                can("locales", "create", permissions) && Pages.Locale.Create
              }
            />
            <Resource
              name="translations"
              options={{
                label: i18nProvider.translate("locales", {
                  _: "Languages",
                }),
              }}
              icon={TranslateIcon}
              list={
                can("translations", "list", permissions) &&
                role !== "Accounts::MerchantAdmin" &&
                Pages.Translation.List
              }
              edit={
                can("translations", "edit", permissions) &&
                Pages.Translation.Edit
              }
              show={
                can("translations", "show", permissions) &&
                Pages.Translation.Show
              }
              create={
                can("translations", "create", permissions) &&
                Pages.Translation.Create
              }
            />
            <Resource
              name="contracts"
              icon={GavelIcon}
              list={
                can("contracts", "list", permissions) && Pages.Contract.List
              }
              show={
                can("contracts", "show", permissions) && Pages.Contract.Show
              }
              create={
                can("contracts", "create", permissions) && Pages.Contract.Create
              }
            ></Resource>
            <Resource
              name="categories"
              options={{
                label: i18nProvider.translate("categories", {
                  _: "Categories",
                }),
              }}
              recordRepresentation="name"
              icon={CategoryIcon}
              create={
                can("categories", "create", permissions) &&
                Pages.Category.Create
              }
              list={
                can("categories", "list", permissions) && Pages.Category.List
              }
              edit={
                can("categories", "edit", permissions) && Pages.Category.Edit
              }
              show={
                can("categories", "show", permissions) && Pages.Category.Show
              }
            />
            <Resource
              name="currencies"
              options={{
                label: i18nProvider.translate("currencies", {
                  _: "Currencies",
                }),
              }}
              recordRepresentation="name"
              icon={MonetizationOnIcon}
              list={
                can("currencies", "list", permissions) && Pages.Currency.List
              }
              edit={
                can("currencies", "edit", permissions) && Pages.Currency.Edit
              }
              show={
                can("currencies", "show", permissions) && Pages.Currency.Show
              }
              create={
                can("currencies", "create", permissions) &&
                Pages.Currency.Create
              }
            />
            <Resource
              name="integrations"
              options={{
                label: i18nProvider.translate("providers", {
                  _: "Providers",
                }),
              }}
              recordRepresentation="name"
              icon={IntegrationsIcon}
              list={
                can("integrations", "list", permissions) &&
                Pages.Integration.List
              }
              edit={
                can("integrations", "edit", permissions) &&
                Pages.Integration.Edit
              }
              show={
                can("integrations", "show", permissions) &&
                Pages.Integration.Show
              }
              create={
                can("integrations", "create", permissions) &&
                Pages.Integration.Create
              }
            />
            <Resource
              name="merchant_products"
              options={{
                label: i18nProvider.translate("MerchantProducts", {
                  _: "Merchant products",
                }),
              }}
              icon={WarehouseIcon}
              list={
                can("merchant_products", "list", permissions) &&
                Pages.MerchantProduct.List
              }
              create={
                can("merchant_products", "create", permissions) &&
                Pages.MerchantProduct.Create
              }
              edit={
                can("merchant_products", "edit", permissions) &&
                Pages.MerchantProduct.Edit
              }
              show={
                can("merchant_products", "show", permissions) &&
                Pages.MerchantProduct.Show
              }
            />
            <Resource
              name="orders"
              icon={OrdersIcon}
              options={{
                label: i18nProvider.translate("orders", {
                  _: "Orders",
                }),
              }}
              list={
                can("orders", "list", permissions) &&
                Pages.Order.List({ filter: can("orders", "list", permissions) })
              }
              show={can("orders", "show", permissions) && Pages.Order.Show}
            />
            <Resource
              name="merchants"
              options={{
                label: i18nProvider.translate("merchants", {
                  _: "Merchants",
                }),
              }}
              icon={MerchantsIcon}
              recordRepresentation="name"
              list={
                can("merchants", "list", permissions) &&
                Pages.Merchant.List({
                  filter: can("merchants", "list", permissions),
                })
              }
              create={
                can("merchants", "create", permissions) && Pages.Merchant.Create
              }
              edit={
                can("merchants", "edit", permissions) && Pages.Merchant.Edit
              }
              show={
                can("merchants", "show", permissions) && Pages.Merchant.Show
              }
            />
            <Resource
              name="api_tokens"
              options={{
                label: i18nProvider.translate("apiTokens", {
                  _: "Api tokens",
                }),
              }}
              icon={ApiIcon}
              list={
                can("api_tokens", "list", permissions) && Pages.ApiToken.List
              }
              create={
                can("api_tokens", "create", permissions) &&
                Pages.ApiToken.Create
              }
              show={
                can("api_tokens", "show", permissions) && Pages.ApiToken.Show
              }
            />
            {role && role !== "Accounts::MerchantAdmin" && (
              <Resource
                name="products"
                options={{
                  label: i18nProvider.translate("products", {
                    _: "Products",
                  }),
                }}
                recordRepresentation="sku"
                icon={InventoryIcon}
                list={
                  can("products", "list", permissions) && Pages.Product.List
                }
                create={
                  can("products", "create", permissions) && Pages.Product.Create
                }
                edit={
                  can("products", "edit", permissions) && Pages.Product.Edit
                }
                show={
                  can("products", "show", permissions) && Pages.Product.Show
                }
              />
            )}
            <Resource
              name="regions"
              options={{
                label: i18nProvider.translate("regions", {
                  _: "Regions",
                }),
              }}
              recordRepresentation="name"
              icon={SouthAmericaIcon}
              list={
                role &&
                role !== "Accounts::MerchantAdmin" &&
                can("regions", "list", permissions) &&
                Pages.Region.List
              }
              create={
                can("regions", "create", permissions) && Pages.Region.Create
              }
              edit={can("regions", "edit", permissions) && Pages.Region.Edit}
              show={can("regions", "show", permissions) && Pages.Region.Show}
            />
            <Resource
              name="integration_products"
              recordRepresentation="ean"
              options={{
                label: i18nProvider.translate("providerProducts", {
                  _: "Provider Products",
                }),
              }}
              icon={ProductionQuantityLimitsIcon}
              list={
                can("integration_products", "list", permissions) &&
                Pages.IntegrationProduct.List
              }
              create={
                can("integration_products", "create", permissions) &&
                Pages.IntegrationProduct.Create
              }
              edit={
                can("integration_products", "edit", permissions) &&
                Pages.IntegrationProduct.Edit
              }
              show={
                can("integration_products", "show", permissions) &&
                Pages.IntegrationProduct.Show
              }
            />
            <Resource
              name="suppliers"
              options={{
                label: i18nProvider.translate("Integrations", {
                  _: "Integrations",
                }),
              }}
              recordRepresentation="name"
              icon={IntegrationInstructionsIcon}
              list={
                can("suppliers", "list", permissions) && Pages.Supplier.List
              }
              show={
                can("suppliers", "show", permissions) && Pages.Supplier.Show
              }
            />
            <Resource
              name="notifications"
              options={{
                label: i18nProvider.translate("notifications", {
                  _: "Notifications",
                }),
              }}
              recordRepresentation="title"
              show={
                can("notifications", "show", permissions) &&
                Pages.Notification.Show
              }
            />
            <Resource
              name="uploads"
              options={{
                label: i18nProvider.translate("uploads", {
                  _: "Uploads",
                }),
              }}
              recordRepresentation={(record) => `${record.type} / ${record.id}`}
              icon={FileUploadIcon}
              create={
                can("uploads", "create", permissions) && Pages.Upload.Create
              }
              list={can("uploads", "list", permissions) && Pages.Upload.List}
              show={can("uploads", "show", permissions) && Pages.Upload.Show}
            />
            <Resource
              name="api_requests"
              options={{
                label: i18nProvider.translate("apiRequests", {
                  _: "Api requests",
                }),
              }}
              icon={CloudIcon}
              list={
                can("api_requests", "list", permissions) &&
                Pages.ApiRequest.List
              }
              show={
                can("api_requests", "show", permissions) &&
                Pages.ApiRequest.Show
              }
            />
            <Resource
              name="web_hook_logs"
              options={{
                label: i18nProvider.translate("webHookLogs", {
                  _: "Web Hook Logs",
                }),
              }}
              icon={CloudIcon}
              list={
                can("web_hook_logs", "list", permissions) &&
                Pages.WebHookLog.List
              }
              show={
                can("web_hook_logs", "show", permissions) &&
                Pages.WebHookLog.Show
              }
            />
            <Resource
              name="versions"
              options={{
                label: i18nProvider.translate("versions", {
                  _: "Versions",
                }),
              }}
              icon={DifferenceIcon}
              list={can("versions", "list", permissions) && Pages.Version.List}
            />
            <Resource
              name="configs"
              options={{
                label: i18nProvider.translate("configs", {
                  _: "Configs",
                }),
              }}
              icon={DifferenceIcon}
              list={can("configs", "list", permissions) && Pages.Config.List}
              edit={can("configs", "edit", permissions) && Pages.Config.Edit}
              show={can("configs", "show", permissions) && Pages.Config.Show}
              create={
                can("configs", "create", permissions) && Pages.Config.Create
              }
            />
            <Resource name="admin_regions" />
            <Resource name="attachments" />
            <Resource name="currency_regions" />
            <Resource name="timezones" />
            <Resource name="wallets" />
            <Resource
              name="order_integration_products"
              icon={ListAltIcon}
              options={{
                label: i18nProvider.translate("order_integration_products", {
                  _: "Provider Orders",
                }),
              }}
              list={
                can("order_integration_products", "list", permissions) &&
                Pages.OrderIntegrationProduct.List
              }
              show={
                can("order_integration_products", "show", permissions) &&
                Pages.OrderIntegrationProduct.Show
              }
            />
            <Resource
              name="pending_prices"
              icon={ListAltIcon}
              options={{
                label: i18nProvider.translate("pending_prices", {
                  _: "Pending Prices",
                }),
              }}
              list={
                can("pending_prices", "list", permissions) &&
                Pages.PendingPrice.List
              }
            />
            <Resource name="order_merchant_products" />
            <CustomRoutes>
              <Route path="/Settings" element={<Pages.Settings.Edit />} />
            </CustomRoutes>
          </>
        )}
      </Admin>
    </>
  );
};

export default App;
