import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const HTTPbadge = ({ text }) => {
  const pickBackground = () => {
    switch (text) {
      case "GET":
        return "#61affe";
      case "POST":
        return "#45df99";
      case "PUT":
        return "#fca130";
      case "PATCH":
        return "#fca130";
      case "DELETE":
        return "#f93e3e";
      default:
        return "#FFFFFF";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 4.5,
        backgroundColor: pickBackground(),
        padding: "2px 10px 2px 8px",
        width: "fit-content",
        height: "fit-content",
      }}
    >
      <Box sx={{ fontSize: "12px" }}>{text && text}</Box>
    </Box>
  );
};

HTTPbadge.propTypes = {
  text: PropTypes.string,
};

export default HTTPbadge;
