export const style = {
  dashboardWrap: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Inter",
  },
  filterWrap: { display: "flex", gap: "10px", marginBottom: "20px" },
  contentWrap: { display: "flex", width: "100%", gap: "30px", height: "100%" },
  userWrap: {
    borderRadius: "12px",
    border: "1px solid #ECEFF3",
    padding: "20px",
    width: "100%",
    height: "100%",
  },
  ordersWrap: {
    borderRadius: "12px",
    border: "1px solid #ECEFF3",
    padding: "20px",
    marginTop: "30px",
    height: "100%",
  },
  currencyWrap: {
    fontSize: "20px",
    marginBottom: "10px",
    marginRight: "10px",
  },
  ordersCount: { fontSize: "20px", marginBottom: "10px", marginRight: "10px" },
  profitWrap: { display: "flex", alignItems: "center" },
  sales: {
    fontSize: "20px",
    marginBottom: "10px",
    marginRight: "10px",
  },
  merchantWrap: { width: "100%", mb: "20px" },
};
