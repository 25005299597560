import React, { useState } from "react";
import {
  DatagridConfigurable,
  TextField,
  NumberField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  usePermissions,
  TopToolbar,
  FilterButton,
  CreateButton,
  UpdateButton,
  BulkUpdateButton,
  ImageField,
  ShowButton,
  SortButton,
  List,
} from "react-admin";
import { IconButton } from "@mui/material";
import CardList from "./CardView";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import PropTypes from "prop-types";
import { can } from "../../../shared/permissionProvider";
import ExportButton from "../../components/Export/ExportButton";
import EditableField from "../../components/Editable/Field";
import { Box } from "@material-ui/core";
import { ExtendedField } from "../../components/Editable/ExtendedField";
import { style } from "./style";

const ProductFilters = [
  <TextInput source="name" key="name" />,
  <TextInput source="ean" key="ean" />,
  <TextInput source="sku" key="sku" />,
  <BooleanInput source="vat" key="vat" />,
  <ReferenceInput
    source="region_id"
    reference="regions"
    key="region"
    filter={{ active: true }}
  >
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <BooleanInput source="active" key="active" defaultValue={true} />,
];

const ListActions = ({ toggleView, isCardView }) => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      <IconButton onClick={toggleView}>
        {isCardView ? <ViewListIcon /> : <ViewModuleIcon />}
      </IconButton>
      {isCardView && (
        <SortButton
          sx={style.sortButton}
          fields={[
            "sku",
            "id",
            "category_id",
            "region_id",
            "integration_products_count",
          ]}
          label="sort"
        />
      )}
      <FilterButton />
      {can("integration_products", "create", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  toggleView: PropTypes.func,
  isCardView: PropTypes.bool,
};

const BulkActionButtons = () => (
  <>
    <BulkUpdateButton
      data={{ populate: true }}
      label="Populate"
      mutationMode="pessimistic"
    />
  </>
);

export const ProductList = () => {
  const [isCardView, setIsCardView] = useState(false);

  const toggleView = () => {
    setIsCardView(!isCardView);
  };

  return (
    <List
      filters={ProductFilters}
      exporter={false}
      actions={<ListActions toggleView={toggleView} isCardView={isCardView} />}
    >
      {isCardView ? (
        <CardList />
      ) : (
        <DatagridConfigurable
          filter={{ active: true }}
          bulkActionButtons={<BulkActionButtons />}
        >
          <ImageField source="image.url" sortable={false} />
          <NumberField source="id" />
          <EditableField
            source="sku"
            type={"text"}
            justify={{ justifyContent: "center" }}
            truncate={style.productTruncate}
            withCopy={true}
          />
          <EditableField
            source="translations.en.name"
            justify={{ justifyContent: "center" }}
            sortable={false}
            label="Name"
            maxChar={128}
            truncate={style.productTruncate}
            withCopy={true}
          />
          <ExtendedField
            source="category_id"
            reference="categories"
            resource={"products"}
            optionText="name"
            optionValue="id"
          />
          <EditableField
            na={true}
            source="countries"
            justify={{ justifyContent: "center" }}
          />
          <EditableField
            source="denomination"
            na={true}
            sortable={false}
            justify={{ justifyContent: "center" }}
          />
          <ReferenceField source="region_id" reference="regions">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="integration_products_count" />
          <Box sx={{ display: "flex", gap: "10px" }}>
            <ShowButton />
            <UpdateButton
              data={{ populate: true }}
              label="Populate"
              mutationMode="pessimistic"
            />
          </Box>
        </DatagridConfigurable>
      )}
    </List>
  );
};

export default ProductList;
