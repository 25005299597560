import React, { useState, useEffect } from "react";
import {
  PasswordInput,
  required,
  maxLength,
  minLength,
  useAuthProvider,
  useDataProvider,
  TabbedForm,
} from "react-admin";
import { Box } from "@material-ui/core";
import { SaveButton } from "react-admin";
import { TimezoneInput } from "../../components/Timezone";
const equalToPassword = (value, allValues) =>
  value !== allValues.password && "The two passwords must match";

const SettingsEdit = () => {
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [timezone, setUserTimezone] = useState("UTC");
  useEffect(() => {
    authProvider
      .getIdentity()
      .then(({ id, timezone }) => {
        setUserId(id);
        setUserTimezone(timezone);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <>Loading</>;
  if (error) return <>Error</>;
  if (!userId) return null;

  const updateAccount = (data) =>
    dataProvider.update("accounts", { id: userId, data });

  return (
    <TabbedForm
      syncWithLocation={false}
      onSubmit={updateAccount}
      toolbar={<Box></Box>}
    >
      <TabbedForm.Tab label="Profile">
        <TimezoneInput
          referenceProps={{}}
          autocompleteProps={{ validate: [required()], defaultValue: timezone }}
        />
        <SaveButton />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Security">
        <PasswordInput
          source="password"
          validate={[required(), minLength(6), maxLength(30)]}
        />
        <PasswordInput
          source="password_confirmation"
          validate={[required(), minLength(6), maxLength(30), equalToPassword]}
        />
        <SaveButton />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export default SettingsEdit;
