import React from "react";
import Button from "@mui/material/Button";
import { useNotify, useDataProvider, useListContext } from "react-admin";
import PropTypes from "prop-types";

const ExportButton = () => {
  const { resource, filterValues } = useListContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [hideButton, setHideButton] = React.useState(false);

  const handleDownload = () => {
    setHideButton(true);
    return dataProvider
      .export(resource, { filter: filterValues })
      .then((response) => {
        const blob = new Blob([response.body], {
          type: "text/csv;charset=utf-8;",
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          resource + Date(Date.now()).toString(),
          ".csv",
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notify("Export successful", { type: "success" });
        setHideButton(false);
      })
      .catch(() =>
        notify("Export failed. Please try again.", { type: "warning" }),
      );
  };

  return (
    <Button
      sx={{
        width: "100px",
        height: "32px",
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        margin: "0px",
        textTransform: "none",
        lineHeight: "1.5",
        boxShadow:
          " 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08)",
      }}
      onClick={handleDownload}
      disabled={hideButton}
    >
      Export
    </Button>
  );
};

ExportButton.propTypes = {
  resource: PropTypes.string,
};

export default ExportButton;
